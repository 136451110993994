import React from 'react';
import Ima1 from './Topup5.png';
import Ima2 from './Topup6.png';
import Ima3 from './Topup1.png';
import Ima4 from './Topup2.png';
import Ima5 from './Topup3.png';
import Ima6 from './Topup4.png';
import Ima7 from './Topup7.png';
import Ima8 from './Topup8.png';

const products = [
  { id: 1, name: 'Credit Topup', imageSrc: Ima7, imageAlt: "200 Credits Topup.", price: '$3.99' },
  { id: 2, name: 'Credit Topup', imageSrc: Ima1, imageAlt: "500 Credits Topup.", price: '$9.99' },
  { id: 3, name: 'Basic Tee', imageSrc: Ima2, imageAlt: "1000 Credits Topup.", price: '$19.99' },
  { id: 4, name: 'Basic Tee', imageSrc: Ima3, imageAlt: "2000 Credits Topup.", price: '$39.99' },
  { id: 5, name: 'Basic Tee', imageSrc: Ima4, imageAlt: "5000 Credits Topup.", price: '$94.99', bonus: '200 Bonus Credits' },
  { id: 6, name: 'Basic Tee', imageSrc: Ima5, imageAlt: "10000 Credits Topup.", price: '$189.99', bonus: '500 Bonus Credits' },
  { id: 7, name: 'Basic Tee', imageSrc: Ima8, imageAlt: "15000 Credits Topup.", price: '$285.99', bonus: '1000 Bonus Credits' },
  { id: 8, name: 'Basic Tee', imageSrc: Ima6, imageAlt: "20000 Credits Topup.", price: '$379.99', bonus: '1500 Bonus Credits' }
];

export default function Example() {
  const redirectUser = (productId) => {
    const urls = {
      1: 'https://swiftaiwriter.gumroad.com/l/200',
      2: 'https://swiftaiwriter.gumroad.com/l/500',
      3: 'https://swiftaiwriter.gumroad.com/l/1000',
      4: 'https://swiftaiwriter.gumroad.com/l/2000',
      5: 'https://swiftaiwriter.gumroad.com/l/5000',
      6: 'https://swiftaiwriter.gumroad.com/l/10000',
      7: 'https://swiftaiwriter.gumroad.com/l/15000',
      8: 'https://swiftaiwriter.gumroad.com/l/20000',
    };
    window.location.href = urls[productId];
  };

  return (
    <div className="bg-white">
      <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
        <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
          {products.map((product) => (
            <div key={product.id} className="group relative" onClick={() => redirectUser(product.id)}>
              <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-md bg-gray-200 lg:aspect-none group-hover:opacity-75 lg:h-80 relative">
                <img
                  src={product.imageSrc}
                  alt={product.imageAlt}
                  className="h-full w-full object-cover object-center lg:h-full lg:w-full"
                />
                {product.bonus && (
                  <div className="absolute top-3 left-3 p-3 bg-white rounded-lg backdrop-filter backdrop-blur-md bg-opacity-40">
                    <p className="text-lg text-white font-semibold">{product.bonus}</p>
                  </div>
                )}
                <div className="absolute bottom-3 right-3 p-3 bg-white rounded-lg backdrop-filter backdrop-blur-md bg-opacity-40">
                  <p className="text-lg text-white font-semibold">{product.price}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>  
  );
}
