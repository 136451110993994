import React from 'react';

const StoreBoardTailwindComponent = ({ selectedSection }) => {
  if (!selectedSection || !selectedSection.paragraph) {
    return (
      <div className="max-w-2xl mx-auto p-8 bg-gray-100 rounded-md shadow-md">
        <p className="text-gray-600">Error: Please select a section.</p>
      </div>
    );
  }

  const paragraph = selectedSection.paragraph;
  const wordCount = paragraph.split(/\s+/).filter((word) => word.length > 0).length;
  const charCount = paragraph.length;

  return (
    <div className="max-w-2xl mx-auto  bg-gray-100 rounded-md shadow-md flex items-center justify-center">
      <div className="text-gray-800 text-center backdrop-blur-lg pl-2 pr-2 pt-4 rounded-md">
        <p className="text-md font-medium">
          {wordCount} Words | {charCount} Characters
        </p>
      </div>
    </div>
  );
};

export default StoreBoardTailwindComponent;
