import React, { useState, useEffect, useCallback, useRef } from 'react';
import BookDescriptionInput from '../BookDescriptionInput';
import DottedButton from '../DottedButton';
import { useNavigate } from 'react-router-dom';
import AsyncQueue from 'async-queue';
import { useUser } from "@clerk/clerk-react";
import axios from 'axios';
import Pop from './Pop'
import Loading from'./Loading';
import FactDisplay from './FactDisplay';
import Generate from './Generate';


// Function to format chapter title to proper case
const formatChapterTitle = (title) => {
  return title
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};


const BookGen = () => {
  const [bookDescription, setBookDescription] = useState('');
  const [isErrorModalOpen, setIsErrorModalOpen] = useState();
  const [isModalOpen, setIsModalOpen] = useState()
  const [userRequest, setUserRequest] = useState('');
  const [completionResult, setCompletionResult] = useState(null);
  const [shown, setShown] = useState(true)
  const [bookTitle, setBookTitle] = useState('');
  const [timestamp, setTimestamp] = useState('');
  const isMounted = useRef(true);
  const isFetchedTimestamp = useRef(true);



  const [tryFetch,setTryFetch] = useState(false);

  const { user } = useUser();

  const [processingQueue, setProcessingQueue] = useState(new AsyncQueue());
  const [processing, setProcessing] = useState(false);

  const [isLoading, setIsLoading] = useState(false); // New loading state

  const [bookRequest, setBookRequest] = useState(true);

  const [isFunctionsCalled, setIsFunctionsCalled] = useState(false);


  const navigate = useNavigate();

  function convertTimestamp(timestamp) {
    return timestamp.replace(/[\/,: ]/g, '');
  }


  const cleanData = (Data) => {
    // console.log("Data,",Data);

    // Initialize a flag to track what type of brace to replace with next.
    const FirstDataClean = Data
    let replaceWithOpenBrace = true;
  
    // Replace "**" with "**{" or "}**" alternately throughout the string.
    let replacedData = FirstDataClean.replace(/\*\*/g, () => {
      if (replaceWithOpenBrace) {
        replaceWithOpenBrace = false;
        return '**{';
      } else {
        replaceWithOpenBrace = true;
        return '}**';
      }
    });

    // console.log("Data1,",replacedData);
    // Replace occurrences of "**{1." to "**{Chapter 1:" and so on up to 10.
    for (let i = 1; i <= 10; i++) {
      replacedData = replacedData.replace(new RegExp(`\\*\\*\\{${i}\\.`,'g'), `**{Chapter ${i}:`);
    }
    // console.log("Data2,",replacedData);
  
    // Trim the string to start from "**{Chapter 1:" onwards.
    let relevantData = replacedData.substring(replacedData.indexOf("**{Chapter 1:"));

    // console.log("Data3,",relevantData);

  
    // Replace specific sections with their numbered counterparts.
    // finalData = finalData.replace(/(1\.2|1\.3|1\.4|2\.2|2\.3|2\.4|3\.2|3\.3|3\.4|4\.2|4\.3|4\.4|5\.2|5\.3|5\.4|6\.2|6\.3|6\.4|7\.2|7\.3|7\.4|8\.2|8\.3|8\.4|9\.2|9\.3|9\.4|10\.2|10\.3|10\.4)/g, '/$1');
  
    // Replace /** and **/ with ** to clean up any formatting issues.
    let finalData = relevantData.replace(/\/\*\*/g, '**');
    finalData = finalData.replace(/\*\*\//g, '**');

    // console.log("Data4,",finalData);
  
    // Replace "**{Chapter 2" to "/**{Chapter 2" and so on up to 10 for additional chapters.
    for (let i = 2; i <= 10; i++) {
      const regex = new RegExp(`\\*\\*{Chapter ${i}`, 'g');
      finalData = finalData.replace(regex, `/**{Chapter ${i}`);
    }

    // console.log("Data5,",finalData);

  
    // Remove slashes between curly braces to clean up any misplaced slashes.
    // finalData = finalData.replace(/({[^}]*?)\/([^}]*})/g, '$1$2');

      // Step 1: Replace all double asterisks temporarily with a placeholder.
  let tempData = finalData.replace(/\*\*/g, "DOUBLEAST");

  // console.log("Data6,",tempData);

  // Step 2: Remove all single asterisks.
  tempData = tempData.replace(/\*/g, "");

  // console.log("Data7,",tempData);

  // Step 3: Replace the placeholder back with double asterisks.
  tempData = tempData.replace(/DOUBLEAST/g, "**");

  // console.log("Data8,",tempData);

    // Add a "/" at the end of the line starting with "10.4."
    finalData = tempData.replace(/(10\.4[^/]*)$/, '$1/');

    // console.log("Data810,",finalData);


              // Cut off everything after the last '/' to clean up the end.
     finalData = finalData.substring(0, finalData.lastIndexOf('/') + 1);

    // console.log("Data9,",finalData);

    finalData = finalData.replace(/\n/g, '/\n');

    // console.log("Data910",finalData)

    finalData = finalData.replace(/\n\n/g, '/ \n\n');

    // console.log("Data920",finalData)


              // Replace multiple consecutive slashes with a single slash
              tempData = finalData.replace(/\/{2,}/g, '/');

              // console.log("Data11,",tempData);

    
    // Return the cleaned and formatted data.
    return finalData + '/';
  };



  

  const parseInput = (input) => {
    const chapters = [];
    let currentChapter = null;
  
    input.split(/\n+/).forEach((line) => {
      // Replace "CHAPTER" with "Chapter"
      line = line.replace(/\*\*\{CHAPTER (\d+): (.+?)\}\*\*/, (_, chapterNumber, chapterTitle) => {
        // Update chapter title to proper case
        const formattedChapterTitle = formatChapterTitle(chapterTitle);
        return `**{Chapter ${chapterNumber}: ${formattedChapterTitle}}**`;
      });
  
      const matchChapter = line.match(/\*\*\{(.+?)\}\*\*/);
      const matchSection = line.match(/(\d+\.\d+)\s(.+?)\//);
  
      if (matchChapter) {
        currentChapter = {
          title: formatChapterTitle(matchChapter[1].replace('/', '')), // Remove "/" from the title and format to proper case
          sections: [],
        };
        chapters.push(currentChapter);
      } else if (matchSection && currentChapter) {
        currentChapter.sections.push({
          title: matchSection[2].replace('/', ''), // Remove "/" from the title
          paragraph: '',
        });
      }
    });
  
    return chapters;
  };

  
  const modifyData = useCallback(async (originalData) => {
    const modifiedData = await Promise.all(originalData.map(async (chapter) => {
      const chapterTitle = chapter.title;
      const failedAttempts = []; // Array to collect failed attempts
      const updatedSections = await Promise.all(chapter.sections.map(async (section) => {
        let completionResult;
        let messageContent = ''; // Initialize messageContent
  
        try {
          completionResult = await fetchChapterCompletion(chapterTitle, section.title);
          if (completionResult) {
            messageContent = completionResult?.choices?.[0]?.message?.content || '';
            // console.log("chapterTitle", chapterTitle, "section.title", section.title, "messageContent", messageContent);
            // console.log(completionResult)
          }
        } catch (error) {
          console.error("Error fetching chapter completion:", error);
          // Collect the failed attempt in the array
          failedAttempts.push({ chapterTitle, sectionTitle: section.title });
          console.log("Failedssss", chapterTitle, section.title);
        }
  
        // If after the attempt there's no valid messageContent
        if (!messageContent) {
          messageContent = "Sorry, claim free credits by mailing";
        }
  
        const cleanedParagraph = messageContent.replace(/^(Chapter \d+:.*?\n\n)/, '');
  
        return {
          ...section,
          paragraph: cleanedParagraph,
        };
      }));
  
      // Retry failed attempts with a 1-second delay
      for (const failedAttempt of failedAttempts) {
        try {
          const { chapterTitle, sectionTitle } = failedAttempt;
          await new Promise(resolve => setTimeout(resolve, 1000)); // 1 second delay
          const completionResult = await fetchChapter(chapterTitle, sectionTitle);
          // console.log("Failed", chapterTitle, sectionTitle);
          if (completionResult) {
            const messageContent = completionResult?.choices?.[0]?.message?.content || '';
            // console.log("Retried: chapterTitle", chapterTitle, "sectionTitle", sectionTitle, "messageContent", messageContent);
            // Optionally, update the modifiedData here based on the successful retry.
            // Find the corresponding section in updatedSections and update it.
            const updatedSectionIndex = updatedSections.findIndex((section) => section.title === sectionTitle);
            if (updatedSectionIndex !== -1) {
              updatedSections[updatedSectionIndex] = {
                ...updatedSections[updatedSectionIndex],
                paragraph: messageContent.replace(/^(Chapter \d+:.*?\n\n)/, ''),
              };
            }
          }
        } catch (error) {
          console.error("Error retrying chapter completion:", error);
        }
      }
  
      return {
        ...chapter,
        sections: updatedSections,
      };
    }));
  
    return modifiedData;
  }, []);  
  

  
  
  


  const handleBookDescriptionChange = (e) => {
    const userInput = e.target.value;
    setBookDescription(userInput);
    // console.log("Title",bookTitle);
  };


  const fetchChatCompletion =  useCallback(async (serverPrompt) => {
    try {
        const response = await axios.post('https://backend-production-07.up.railway.app/api/fetchChatCompletion', { serverPrompt });
        const result = response.data;
        // console.log(result);
        setCompletionResult(result);
    } catch (error) {
        console.error(error);
    }
  },[]);

  //------------------------------------------------------------------------------------------------------------//

  const fetchChapter = async (BookChapterTitle, TopicName) => {
    try {
        const response = await axios.post('https://backend-production-07.up.railway.app/api/fetchChapter', { BookChapterTitle, TopicName });
        const result = response.data;
        // console.log(result);
        return result;
    } catch (error) {
        console.error(error);
        throw error; // or handle error appropriately
    }
  };

  //------------------------------------------------------------------------------------------------------------//

  const fetchTitleCompletion = async (userInputForTitle) => {
    try {
        const response = await axios.post('https://backend-production-07.up.railway.app/api/fetchNonFictionalTitleCompletion', { userInputForTitle });
        const result = response.data;
        // console.log(result);
        return result;
    } catch (error) {
        console.error(error);
        throw error; // or handle error appropriately
    }
  };

//------------------------------------------------------------------------------------------------------------//

const fetchChapterCompletion = async (BookChapterTitle, TopicName) => {
  try {
      const response = await axios.post('https://backend-production-07.up.railway.app/api/fetchChapterCompletion', { BookChapterTitle, TopicName });
      const result = response.data;
      // console.log(result);
      return result;
  } catch (error) {
      console.error(error);
      throw error; // or handle error appropriately
  }
};

const fetchUserCredits = async () => {
  try {
    // Replace 'your-credits-endpoint' with the actual endpoint to fetch user credits
    const response = await axios.get(`https://backend-production-bcc4.up.railway.app/fetch/${user.id}`);

    return response.data.total_credits; // Assuming the response contains the user's total credits
  } catch (error) {
    console.error('Error fetching user credits:', error);
    throw error;
  }
};

//------------------------------------------------------------------------------------------------------------//

const handleEmailMeClick = async () => {
  try {

    if (bookDescription.length <= 3) {
    setIsErrorModalOpen(true)
  }else{
    if(shown){
      setIsModalOpen(true)
      setShown(false)
      }
  }
    // Check the condition before proceeding
    if (bookDescription.length >= 3) {
      setIsLoading(true);

      // If already processing, add to the queue
      if (processing) {
        setProcessingQueue((prevQueue) => {
          prevQueue.enqueue(async () => {
            await processEmailMeClick(bookDescription);
          });
          return prevQueue;
        });
      } else {
        // Not processing, so directly call processEmailMeClick
        await processEmailMeClick(bookDescription);
      }
    } else {
      // Condition not met, set error message
      // console.log('Description should be at least 3 characters long');
    }
  } catch (error) {
    // Handle errors if needed
    console.error('Error:', error);
  }
};



const processEmailMeClick = async (bookDescription) => {
  try {

       // Check if the user has sufficient credits
       const userCredits = await fetchUserCredits(); // You need to implement this function to fetch user credits

       if (userCredits < 100) {
         // If the user doesn't have sufficient credits, redirect them to /balance
        //  console.error('Insufficient credits. Redirecting to /Balance.');
         navigate('/Credit');
         return;
       }

    // Set processing flag to true
    setProcessing(true);


    // console.log(bookDescription)

    // First, fetch the title
    const titleResult = await fetchTitleCompletion(bookDescription);


// First attempt to match double quotes
let generatedTitleMatch = titleResult.choices[0].message.content.match(/"([^"]*)"/);

// Check if the match is empty or null, then try single quotes
if (!generatedTitleMatch) {
   generatedTitleMatch = titleResult.choices[0].message.content.match(/'([^']*)'/);
}
    const generatedTitle = generatedTitleMatch ? generatedTitleMatch[1] : '';

    // Update the book title state if needed
    setBookTitle(generatedTitle);

    // console.log(generatedTitle)

    const requestTemplate = `Create a succinct and structured outline for a book titled "${generatedTitle}", encompassing 10 distinct chapters. Each chapter should be denoted by integers like “**{CHAPTER HEADING}**”, and for each chapter, furnish 4 detailed bullet points as subtopics like 1.1, 1.2, 1.3, 1.4, 2.1, 2.2, 2.3, 2.4, 3.1, 3.2, 3.3, 3.4 and don’t forget to end each topic with "/" like 3.1 Don’t let them do what they want to/ 3.2 Create a positive atmosphere/ 3.3 How to talk to someone/ . Ensure that each chapter's subtopics offer precise, actionable insights relevant to the overarching theme of the book. This outline should serve as a practical and informative guide on the subject matter, with each chapter comprising four essential points, avoiding unnecessary detail. Don't use roman numbers. Make sure Each chapter must have 4 subtopics in bullet points`;

    setUserRequest(requestTemplate);

    // Now, proceed with fetchChatCompletion
    await fetchChatCompletion(requestTemplate);


    // After processing, check if there are pending requests in the queue
    setProcessing(false);
    if (processingQueue.size > 0) {
      const nextRequest = processingQueue.dequeue();
      nextRequest();
    }
  } catch (error) {
    // Handle errors if needed
    console.error('Error:', error);

    // Ensure the processing flag is reset even in case of an error
    setProcessing(false);

    // If there are pending requests in the queue, dequeue the next request
    if (processingQueue.size > 0) {
      const nextRequest = processingQueue.dequeue();
      nextRequest();
    }
  }
};

const handleBookRequest = async (userId, bookDescription,bookTitle,timestamp) => {
  try {
    const response = await axios.post('https://backend-production-bcc4.up.railway.app/save-book-request', {
      user_id: userId,
      book_description: bookDescription , 
      book_title: bookTitle,
      book_request_id: timestamp,
    });
    console.log(response);
    console.log(response.data);
    console.log(userId)
  } catch (error) {
    console.error('Error reducing credits:', error);
  }
};



useEffect(() => {
  let attemptCount = 0;

  const fetchTimestampAndRender = async () => {
    const currentTimestamp = new Date().toLocaleString();
    const convertedTimestamp = convertTimestamp(currentTimestamp);
    setTimestamp(convertedTimestamp);
  };

  if(isFetchedTimestamp.current){
  fetchTimestampAndRender();
  isFetchedTimestamp.current = false;
  }

  if (completionResult) {
    if(user){

    const userId = user.id
    if( bookRequest){
    handleBookRequest(userId,bookDescription,bookTitle,timestamp )
    setBookRequest(false)
    }
  }
    const fetchDataAndSaveFile = async () => {
       if(!tryFetch){
       try {
        // console.log(completionResult.choices[0].message.content)
        let FetchedData = completionResult.choices[0].message.content
        let originalData = cleanData(FetchedData);
        // console.log("CleanData",originalData)
         originalData = parseInput(originalData);
        //  console.log("Parse",originalData)



// Validate the structure of originalData
while (attemptCount < 4 && !isValidStructure(originalData)) {
  console.error('Invalid structure in originalData. Refetching data...');

      // First, fetch the title
      const newTitle = await fetchTitleCompletion(bookTitle);

      // First attempt to match double quotes
      let newGeneratedTitleMatch = newTitle.choices[0].message.content.match(/"([^"]*)"/);
      
      // Check if the match is empty or null, then try single quotes
      if (!newGeneratedTitleMatch) {
        newGeneratedTitleMatch = newTitle.choices[0].message.content.match(/'([^']*)'/);
      }
          const generatedTitle = newGeneratedTitleMatch ? newGeneratedTitleMatch[1] : '';
      
          // Update the book title state if needed
          setBookTitle(generatedTitle);

    const requestRetryTemplate = `Create a succinct and structured outline for a book titled "${generatedTitle}", encompassing 10 distinct chapters. Each chapter should be denoted by integers like “**{CHAPTER HEADING}**”, and for each chapter, furnish 4 detailed bullet points as subtopics like 1.1, 1.2, 1.3, 1.4, 2.1, 2.2, 2.3, 2.4, 3.1, 3.2, 3.3, 3.4 and don’t forget to end each topic with "/" like 3.1 Don’t let them do what they want to/ 3.2 Create a positive atmosphere/ 3.3 How to talk to someone/ . Ensure that each chapter's subtopics offer precise, actionable insights relevant to the overarching theme of the book. This outline should serve as a practical and informative guide on the subject matter, with each chapter comprising four essential points, avoiding unnecessary detail. Don't use roman numbers. Make sure Each chapter must have 4 subtopics in bullet points`;


  // Refetch data
  await fetchChatCompletion(requestRetryTemplate);

  originalData = cleanData(completionResult.choices[0].message.content);
    originalData = parseInput(originalData);
    console.log("Parse",originalData)


  attemptCount++;

  // console.log("attemptCount",attemptCount,originalData)
  console.log("attemptCount",attemptCount)

  // Check if the structure is valid after parsing again, break the loop early if so
  if (isValidStructure(originalData)) {
    break;
  }
}
       // If still not valid after 4 attempts, abort
       if (isValidStructure(originalData)) {

        // console.log("originalData", originalData);
        // console.log("parsedChapters", parsedChapters);

        // console.log(timestamp)

        const bookType = "NonFictional";
  
        const saveFile = (jsonInput, bookDescription,bookTitle,userId,bookType, timestamp) => {
          const dataToSend = {
            bookDescription: bookDescription,
            jsonInput: jsonInput,
            bookTitle: bookTitle,
            userId:userId,
            bookType:bookType,
            timeStamp:timestamp
          };
          // console.log("dataToSend",dataToSend)
        
          fetch('https://backend-production-bcc4.up.railway.app/save-json', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataToSend),
          })
            .then(response => response.text())
            .then((data) => {
              console.log('Server Response:', data);
              const twelveDigitValue = data.trim().split(' ').pop();
              // console.log('Twelve Digit Value:', twelveDigitValue);
              navigate(`/${twelveDigitValue}`);
            })
            .catch((error) => {
              console.error('Error:', error);
            });
        };


          const handleReduceCredits = async (userIdo) => {
            try {
              const response = await axios.post('https://backend-production-bcc4.up.railway.app/reduce-credits', {
                user_id: userIdo, // replace with the actual user ID
                credits_to_reduce: 100 // replace with the desired amount to reduce
              });
              console.log(response.data);
              // console.log(userId)
            } catch (error) {
              console.error('Error reducing credits:', error);
            }
          };

          const logReduceCredits = async (userIdo, timestamp) => {
            try {
              const response = await axios.post('https://backend-production-bcc4.up.railway.app/save-reduced-credits', {
                user_id: userIdo, // replace with the actual user ID
                credits_to_reduce: 100, // replace with the desired amount to reduce
                used_for: "Crafting NonFiction Book",
                book_request_id: timestamp
              });
              console.log(response.data);
              // console.log(userId)
            } catch (error) {
              console.error('Error reducing credits:', error);
            }
          };



            if (isMounted.current && !isFunctionsCalled) {
              setIsFunctionsCalled(true);
              try {
                // Define your functions here or make sure they are accessible in this scope.
                const UserId = user.id;
                const parsedChapters = await modifyData(originalData);
          
                // Execute all functions in a controlled manner
                await Promise.all([
                  handleReduceCredits(UserId),
                  logReduceCredits(UserId, timestamp),
                  saveFile(parsedChapters, bookDescription, bookTitle, UserId, bookType, timestamp)
                ]);
          
              } catch (error) {
                console.error('Error:', error);
                // Reset the flag if there's an error so the user can retry
                setIsFunctionsCalled(false);
              }
            } else {
              console.log("The functions have already been called.");
            }

        

            isMounted.current = false; // Update the ref's current value



      } else {
        console.error('Still invalid structure in originalData after 4 attempts. Aborting.');
        setTryFetch(true)
        setIsFunctionsCalled(false);
        return
      }
      
      } catch (error) {
        console.error('Error:', error);
      }
    }
    };

    // Function to validate the structure of originalData
    const isValidStructure = (data) => {
      return (
        Array.isArray(data) &&
        data.every((chapter) =>
          chapter.hasOwnProperty('title') &&
          typeof chapter.title === 'string' &&
          chapter.hasOwnProperty('sections') &&
          Array.isArray(chapter.sections) &&
          chapter.sections.length === 4 && // Ensure there are exactly 4 sections
          chapter.sections.every((section) =>
            section.hasOwnProperty('title') &&
            typeof section.title === 'string' &&
            section.hasOwnProperty('paragraph') &&
            typeof section.paragraph === 'string'
          )
        )
      );
    };

    // Call the function
    fetchDataAndSaveFile();
  }
}, [completionResult, bookDescription, user, bookTitle,fetchChatCompletion, navigate, modifyData, tryFetch,userRequest ,timestamp,bookRequest, isFunctionsCalled]);
  

  return (
    <>
      {isLoading ? (
        <>
        <div className="flex items-center justify-center mt-">
          <Loading />
        </div>
        <div className="flex items-center justify-center rounded-lg px-8 mt-4 bg-blue-200"><FactDisplay/></div>
        <Generate isOpen={isModalOpen} setIsOpen={setIsModalOpen} />
        </>
      ) : (
        <div>
          <div>
            <h1
              style={{
                fontSize: '2rem',
                fontFamily: 'Poppins, sans-serif',
                fontWeight: 'bolder',
                color: '#D2B2F8',
              }}
            >
              📝Let's Write a Book about
            </h1>
            <BookDescriptionInput
              value={bookDescription}
              onChange={handleBookDescriptionChange}
              style={{
                fontSize: '1.5rem',
                fontFamily: 'Poppins, sans-serif',
                color: '#888888',
              }}
            />
            {bookDescription.trim() === '' && (
              <p style={{ fontFamily: 'Poppins, sans-serif', color: 'red' }}>
                Describe the book you want to generate.
              </p>
            )}
            <ol
              style={{
                fontSize: '0.95rem',
                fontFamily: 'Poppins, sans-serif',
                color: '#000000',
                marginTop: '1.5rem',
                textAlign: 'left',
              }}
            >
              <li>Guidelines for Maximizing the Quality of Book Content</li>
              <li>
                1️>> <b>Specific Details--></b> Provide specific details about the
                book's main topic, subtopics, key arguments, and any essential facts.
              </li>
              <li>
                2>> <b>Vision Outline--></b> Outline the book's purpose, message, and
                any specific angles or perspectives you want to explore.
              </li>
              <li>
                3>> <b>Target Audience--></b> Specify the intended reader and their
                level of expertise or background knowledge.
              </li>
            </ol>
            <div style={{ marginTop: '20px' }}>
              <DottedButton onClick={handleEmailMeClick} />
              <Pop isOpen={isErrorModalOpen} setIsOpen={setIsErrorModalOpen} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BookGen;
