// BookViewer.js
// Import necessary dependencies
import React, { useEffect, useState } from "react";
import { useParams, useNavigate, json } from "react-router-dom";
import Sidebar from "./Sidebar";
import SidebarMod from "./SidebarMod";
import LandingMenu from "../Fiction/LandingMenuSign";
import CustomButton from "../Fiction/CustomButton";
import BookPop from "../Fiction/BookPop";
import ConfirmPop from "../Fiction/ConfirmPop";
import { motion } from "framer-motion";
import "./BookViewer.css";
import axios from "axios";
import ProgressBar from "./ProgressBar";
import { useUser } from "@clerk/clerk-react";

const BookViewer = () => {
  const { twelveDigitValue } = useParams();
  const [sidebarData, setSidebarData] = useState(null);
  const [generated, setGenerated] = useState(null);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState();
  const [isModalOpen, setIsModalOpen] = useState();
  const [process, setProcess] = useState(false);
  const [shown, setShown] = useState(false);
  const [generation, setGeneration] = useState(false);
  const [bookCost, setBookCost] = useState("");
  const [buttonText, setButtonText] = useState("Generate");
  const [bookTitle, setBookTitle] = useState();
  const [bookGenre, setBookGenre] = useState();
  const [currentValue, setCurrentValue] = useState(0);
  const [maxValue, setMaxValue] = useState();
  const [fetchContent, setFetchingContent] = useState(false);
  const { user } = useUser();
  const [paid, setPaid] = useState(false);
  const navigate = useNavigate();
  const [userAccess, setUserAccess] = useState();

  const handleReduceCredits = async (userIdo, bookCost) => {
    try {
      const response = await axios.post(
        "https://backend-production-bcc4.up.railway.app/reduce-credits",
        {
          user_id: userIdo, // replace with the actual user ID
          credits_to_reduce: bookCost, // replace with the desired amount to reduce
        }
      );
      logReduceCredits(userIdo, bookCost);
      // console.log(response.data);
      // console.log(userId)
    } catch (error) {
      console.error("Error reducing credits:", error);
    }
  };

  const convertTimestamp = (timestamp) => {
    return timestamp.replace(/[\/,: ]/g, "");
  };

  const logReduceCredits = async (userIdo, bookCost) => {
    const currentTimestamp = new Date().toLocaleString();
    const convertedTimestamp = convertTimestamp(currentTimestamp);

    try {
      const response = await axios.post(
        "https://backend-production-bcc4.up.railway.app/save-reduced-credits",
        {
          user_id: userIdo, // replace with the actual user ID
          credits_to_reduce: bookCost, // replace with the desired amount to reduce
          used_for: "Crafting Fiction Book",
          book_request_id: convertedTimestamp,
        }
      );
      // console.log(response.data);
      setPaid(true);
      // console.log(userId)
    } catch (error) {
      console.error("Error reducing credits:", error);
    }
  };

  const fetchFictionChapterCompletion = async (
    BookChapterTitle,
    BookTitle,
    BookGenre,
    Topic
  ) => {
    try {
      const response = await axios.post(
        "https://backend-production-07.up.railway.app/api/fetchFictionChapter",
        { BookChapterTitle, BookTitle, BookGenre, Topic }
      );
      const result = response.data;
      // console.log(result);
      return result;
    } catch (error) {
      console.error(error);
      throw error; // or handle error appropriately
    }
  };

  const fetchFictionChapter = async (
    BookChapterTitle,
    BookTitle,
    BookGenre,
    Topic
  ) => {
    try {
      const response = await axios.post(
        "https://backend-production-07.up.railway.app/api/fetchFictionChapter",
        { BookChapterTitle, BookTitle, BookGenre, Topic }
      );
      const result = response.data;
      // console.log(result);
      return result;
    } catch (error) {
      console.error(error);
      throw error; // or handle error appropriately
    }
  };

  const modifyData = async (originalData) => {
    const modifiedData = await Promise.all(
      originalData.map(async (chapter) => {
        const chapterTitle = chapter.title;
        const failedAttempts = []; // Array to collect failed attempts
        const updatedSections = await Promise.all(
          chapter.sections.map(async (section) => {
            let completionResult;
            let messageContent = ""; // Initialize messageContent

            try {
              // newSectionTitle = await fetchFictionChapterStory(chapterTitle,bookTitle, bookGenre, section.title)
              completionResult = await fetchFictionChapterCompletion(
                chapterTitle,
                bookTitle,
                bookGenre,
                section.title
              );
              // console.log("<><><><><>",section.title)
              if (completionResult) {
                messageContent =
                  completionResult?.choices?.[0]?.message?.content || "";
                setCurrentValue((currentValue) => currentValue + 1);
                // console.log("chapterTitle", chapterTitle, "section.title", section.title, "messageContent", messageContent);
                // console.log(completionResult)
              }
            } catch (error) {
              console.error("Error fetching chapter completion:", error);
              // Collect the failed attempt in the array
              failedAttempts.push({
                chapterTitle,
                sectionTitle: section.title,
              });
              // console.log("Failedssss", chapterTitle, section.title);
            }

            // If after the attempt there's no valid messageContent
            if (!messageContent) {
              messageContent = "Sorry, claim free credits by mailing";
            }

            const cleanedParagraph = messageContent.replace(
              /^(Chapter \d+:.*?\n\n)/,
              ""
            );

            return {
              ...section,
              paragraph: cleanedParagraph,
            };
          })
        );

        // Retry failed attempts with a 1-second delay
        for (const failedAttempt of failedAttempts) {
          try {
            const { chapterTitle, sectionTitle } = failedAttempt;
            await new Promise((resolve) => setTimeout(resolve, 1000)); // 1 second delay
            const completionResult = await fetchFictionChapter(
              chapterTitle,
              bookTitle,
              bookGenre,
              sectionTitle
            );
            // console.log("Failed", chapterTitle, sectionTitle);
            if (completionResult) {
              const messageContent =
                completionResult?.choices?.[0]?.message?.content || "";
              setCurrentValue((currentValue) => currentValue + 1);
              // console.log("Retried: chapterTitle", chapterTitle, "sectionTitle", sectionTitle, "messageContent", messageContent);
              // Optionally, update the modifiedData here based on the successful retry.
              // Find the corresponding section in updatedSections and update it.
              const updatedSectionIndex = updatedSections.findIndex(
                (section) => section.title === sectionTitle
              );
              if (updatedSectionIndex !== -1) {
                updatedSections[updatedSectionIndex] = {
                  ...updatedSections[updatedSectionIndex],
                  paragraph: messageContent.replace(
                    /^(Chapter \d+:.*?\n\n)/,
                    ""
                  ),
                };
              }
            }
          } catch (error) {
            console.error("Error retrying chapter completion:", error);
          }
        }

        return {
          ...chapter,
          sections: updatedSections,
        };
      })
    );

    return modifiedData;
  };

  const handleGenerate = () => {
    if (process) {
      setIsModalOpen(true);
      setTimeout(() => {
        setShown(true);
        setGeneration(true);
      }, 500);
    } else {
      setIsErrorModalOpen(true);
      setTimeout(() => {
        setProcess(true);
        setButtonText("Confirm");
      }, 200);
    }
  };

  const updateData = (tempData) => {
    let finalData = tempData;

    // Try replacing 40.5
    let updated = finalData.replace(/(40\.5[^/]*)$/, "$1/");
    if (updated !== finalData) {
      return updated; // Return if this replacement made a change
    }

    // Try replacing 30.5
    updated = finalData.replace(/(30\.5[^/]*)$/, "$1/");
    if (updated !== finalData) {
      return updated; // Return if this replacement made a change
    }

    // Try replacing 20.5
    updated = finalData.replace(/(20\.5[^/]*)$/, "$1/");
    if (updated !== finalData) {
      return updated; // Return if this replacement made a change
    }

    // Try replacing 10.5
    updated = finalData.replace(/(10\.5[^/]*)$/, "$1/");
    if (updated !== finalData) {
      return updated; // Return if this replacement made a change
    }

    // Try replacing 5.5
    updated = finalData.replace(/(5\.5[^/]*)$/, "$1/");
    if (updated !== finalData) {
      return updated; // Return if this replacement made a change
    }

    return finalData; // Return the final data if no changes were made
  };

  const parseInput = (input) => {
    const chapters = [];
    let currentChapter = null;

    input.split(/\n+/).forEach((line) => {
      // Replace "CHAPTER" with "Chapter"
      line = line.replace(
        /\*\*\{CHAPTER (\d+): (.+?)\}\*\*/,
        (_, chapterNumber, chapterTitle) => {
          // Update chapter title to proper case
          const formattedChapterTitle = formatChapterTitle(chapterTitle);
          return `**{Chapter ${chapterNumber}: ${formattedChapterTitle}}**`;
        }
      );

      const matchChapter = line.match(/\*\*\{(.+?)\}\*\*/);
      const matchSection = line.match(/(\d+\.\d+)\s(.+?)\//);

      if (matchChapter) {
        currentChapter = {
          title: formatChapterTitle(matchChapter[1].replace("/", "")), // Remove "/" from the title and format to proper case
          sections: [],
        };
        chapters.push(currentChapter);
      } else if (matchSection && currentChapter) {
        currentChapter.sections.push({
          title: matchSection[2].replace("/", ""), // Remove "/" from the title
          paragraph: "",
        });
      }
    });

    return chapters;
  };

  const cleanData = (Data) => {
    // console.log("Data,",Data);

    // Initialize a flag to track what type of brace to replace with next.
    const FirstDataClean = Data;
    let replaceWithOpenBrace = true;

    // Replace "**" with "**{" or "}**" alternately throughout the string.
    let replacedData = FirstDataClean.replace(/\*\*/g, () => {
      if (replaceWithOpenBrace) {
        replaceWithOpenBrace = false;
        return "**{";
      } else {
        replaceWithOpenBrace = true;
        return "}**";
      }
    });

    // console.log("Data1,",replacedData);
    // Replace occurrences of "**{1." to "**{Chapter 1:" and so on up to 10.
    for (let i = 1; i <= 10; i++) {
      replacedData = replacedData.replace(
        new RegExp(`\\*\\*\\{${i}\\.`, "g"),
        `**{Chapter ${i}:`
      );
    }
    // console.log("Data2,",replacedData);

    // Trim the string to start from "**{Chapter 1:" onwards.
    let relevantData = replacedData.substring(
      replacedData.indexOf("**{Chapter 1:")
    );

    // console.log("Data3,",relevantData);

    // Replace specific sections with their numbered counterparts.
    // finalData = finalData.replace(/(1\.2|1\.3|1\.4|2\.2|2\.3|2\.4|3\.2|3\.3|3\.4|4\.2|4\.3|4\.4|5\.2|5\.3|5\.4|6\.2|6\.3|6\.4|7\.2|7\.3|7\.4|8\.2|8\.3|8\.4|9\.2|9\.3|9\.4|10\.2|10\.3|10\.4)/g, '/$1');

    // Replace /** and **/ with ** to clean up any formatting issues.
    let finalData = relevantData.replace(/\/\*\*/g, "**");
    finalData = finalData.replace(/\*\*\//g, "**");

    // console.log("Data4,",finalData);

    // Replace "**{Chapter 2" to "/**{Chapter 2" and so on up to 10 for additional chapters.
    for (let i = 2; i <= 10; i++) {
      const regex = new RegExp(`\\*\\*{Chapter ${i}`, "g");
      finalData = finalData.replace(regex, `/**{Chapter ${i}`);
    }

    // console.log("Data5,",finalData);

    // Remove slashes between curly braces to clean up any misplaced slashes.
    // finalData = finalData.replace(/({[^}]*?)\/([^}]*})/g, '$1$2');

    // Step 1: Replace all double asterisks temporarily with a placeholder.
    let tempData = finalData.replace(/\*\*/g, "DOUBLEAST");

    // console.log("Data6,",tempData);

    // Step 2: Remove all single asterisks.
    tempData = tempData.replace(/\*/g, "");

    // console.log("Data7,",tempData);

    // Step 3: Replace the placeholder back with double asterisks.
    tempData = tempData.replace(/DOUBLEAST/g, "**");

    // console.log("Data8,",tempData);

    // Add a "/" at the end of the line starting with "10.4."
    // finalData = tempData.replace(/(5\.4[^/]*)$/, '$1/');

    // Example usage
    let updatedData = updateData(tempData);

    // console.log("Data810,",updatedData);

    // Cut off everything after the last '/' to clean up the end.
    finalData = updatedData.substring(0, updatedData.lastIndexOf("/") + 1);

    // console.log("Data9,",finalData);

    finalData = finalData.replace(/\n/g, "/\n");

    // console.log("Data910",finalData)

    finalData = finalData.replace(/\n\n/g, "/ \n\n");

    // console.log("Data920",finalData)

    // Replace multiple consecutive slashes with a single slash
    tempData = finalData.replace(/\/{2,}/g, "/");

    // console.log("Data11,",tempData);

    // Return the cleaned and formatted data.
    return finalData + "/";
  };

  // Function to format chapter title to proper case
  const formatChapterTitle = (title) => {
    return title
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const filterChaptersWithFiveSections = (chapters) => {
    return chapters.filter((chapter) => chapter.sections.length === 5);
  };

  const fetchTitleCompletion = async (promptTitle) => {
    try {
      const response = await axios.post(
        "https://backend-production-07.up.railway.app/api/fetchTitle",
        { promptTitle }
      );
      const result = response.data;
      // console.log(result);
      return result;
    } catch (error) {
      console.error(error);
      throw error; // or handle error appropriately
    }
  };

  const fetchOutlineCompletion = async (promptOutline) => {
    try {
      const response = await axios.post(
        "https://backend-production-07.up.railway.app/api/fetchOutline",
        { promptOutline }
      );
      const result = response.data;
      // console.log(result);
      return result;
    } catch (error) {
      console.error(error);
      throw error; // or handle error appropriately
    }
  };

  const fetchUpdateOutline = async (bookTitle, jsonData, generatedBool) => {
    try {
      const twentyDigitValue = twelveDigitValue;
      const response = await axios.put(
        `https://backend-production-bcc4.up.railway.app/update-fiction-book/${twentyDigitValue}`,
        { bookTitle, jsonData, generatedBool }
      );
      const result = response.data;

      if (response.status === 200) {
        // Assuming a 200 status code for a successful update
        setTimeout(function () {
          window.location.reload();
        }, 1000); // Wait for 2000 milliseconds (2 seconds) before reloading
      }
      return result;
    } catch (error) {
      console.error(error);
      throw error; // or handle error appropriately
    }
  };

  //   const bookOutline = async (bookDesciption,bookGenre,bookPages) => {
  //     // Generate Book Title
  //     const promptTemplate = `Generate a title for a fictional book in the ${bookGenre} genre, centered around ${bookDesciption}`
  //     const titleResult = await fetchTitleCompletion(promptTemplate);

  //     // First attempt to match double quotes
  // let generatedTitleMatch = titleResult.choices[0].message.content.match(/"([^"]*)"/);

  // // Check if the match is empty or null, then try single quotes
  // if (!generatedTitleMatch) {
  //    generatedTitleMatch = titleResult.choices[0].message.content.match(/'([^']*)'/);
  // }
  //     const generatedTitle = generatedTitleMatch ? generatedTitleMatch[1] : '';

  //     // Generate Outline with generatedTitleMatch
  //     const bookChapters = bookPages/5
  //     const promptOutline = `Develop a comprehensive outline for a Fiction book called "${generatedTitleMatch}" in the ${bookGenre} genre, encompassing of ${bookChapters} distinct chapters Each with interesting plots and twists which keep the reader hooked. Each chapter should be denoted by integers like “**{CHAPTER HEADING}**”, and for each chapter, furnish 5 detailed bullet points as topics like 3.1, 3.2, 3.3, 3.4, 3.5 and don’t forget to end each topic with "/" like 3.1 Don’t let them do what they want to/ 3.2 Create a positive atmosphere/ 3.3 How to talk to someone/ . This outline should be as short, focused and detailed to serve as a structured framework for a practical and informative guide on the topic. Don't use roman numbers. Each chapter must have 5 bullet points. Also include character name and small scene description. Be Creative and Unique.`
  //     const outlineResult = await fetchOutlineCompletion(promptOutline)

  //     console.log(outlineResult.choices[0].message.content)
  //     const jsoNData = cleanData(outlineResult.choices[0].message.content);
  //     console.log("jsoNData",jsoNData)
  //     const cleanedJson = parseInput(jsoNData);
  //     console.log("cleanedJson",cleanedJson)
  //     const filteredJson = filterChaptersWithFiveSections(cleanedJson);
  //     console.log("filteredJson",filteredJson)

  //     // Update book data
  //     await fetchUpdateOutline(generatedTitle,filteredJson)
  //   }
  /////////////////
  async function generateBookTitle(description, genre) {
    const promptTemplate = `Generate a title for a fictional book in the ${genre} genre, centered around ${description}`;
    const titleResult = await fetchTitleCompletion(promptTemplate);
    // First attempt to match double quotes
    let generatedTitleMatch =
      titleResult.choices[0].message.content.match(/"([^"]*)"/);

    // // Check if the match is empty or null, then try single quotes
    if (!generatedTitleMatch) {
      generatedTitleMatch =
        titleResult.choices[0].message.content.match(/'([^']*)'/);
    }
    const generatedTitle = generatedTitleMatch ? generatedTitleMatch[1] : "";
    return generatedTitle;
    // const titleMatch = titleResult.choices[0].message.content.match(/"([^"]*)"/) || titleResult.choices[0].message.content.match(/'([^']*)'/);
    // return titleMatch ? titleMatch[1] : '';
  }

  async function generateBookTitle(description, genre) {
    const promptTemplate = `Generate a title for a fictional book in the ${genre} genre, centered around ${description}`;
    const titleResult = await fetchTitleCompletion(promptTemplate);
    // First attempt to match double quotes
    let generatedTitleMatch =
      titleResult.choices[0].message.content.match(/"([^"]*)"/);

    // // Check if the match is empty or null, then try single quotes
    if (!generatedTitleMatch) {
      generatedTitleMatch =
        titleResult.choices[0].message.content.match(/'([^']*)'/);
    }
    const generatedTitle = generatedTitleMatch ? generatedTitleMatch[1] : "";
    return generatedTitle;
    // const titleMatch = titleResult.choices[0].message.content.match(/"([^"]*)"/) || titleResult.choices[0].message.content.match(/'([^']*)'/);
    // return titleMatch ? titleMatch[1] : '';
  }

  async function regenerateBookTitle(description, genre) {
    const promptTemplate = `Generate a title for a fictional book, centered around ${description}`;
    const titleResult = await fetchTitleCompletion(promptTemplate);
    // First attempt to match double quotes
    let generatedTitleMatch =
      titleResult.choices[0].message.content.match(/"([^"]*)"/);

    // // Check if the match is empty or null, then try single quotes
    if (!generatedTitleMatch) {
      generatedTitleMatch =
        titleResult.choices[0].message.content.match(/'([^']*)'/);
    }
    const generatedTitle = generatedTitleMatch ? generatedTitleMatch[1] : "";
    return generatedTitle;
    // const titleMatch = titleResult.choices[0].message.content.match(/"([^"]*)"/) || titleResult.choices[0].message.content.match(/'([^']*)'/);
    // return titleMatch ? titleMatch[1] : '';
  }

  async function generateBookOutline(title, genre, pages) {
    const bookChapters = Math.ceil(pages / 5);
    const promptOutline = `Develop a comprehensive outline for a Fiction book called "${title}" in the ${genre} genre, encompassing of ${bookChapters} distinct chapters. Each with interesting plots and twists which keep the reader hooked. Each chapter should be denoted by integers like “**{CHAPTER HEADING}**”, and for each chapter, furnish 5 detailed bullet points as topics like 3.1, 3.2, 3.3, 3.4, 3.5 and don’t forget to end each topic with "/" like 3.1 Don’t let them do what they want to/ 3.2 Create a positive atmosphere/ 3.3 How to talk to someone/. This outline should be as short, focused and detailed to serve as a structured framework for a practical and informative guide on the topic. Don't use roman numbers. Each chapter must have 5 bullet points. Also include character name and small scene description. Be Creative and Unique.`;
    const model = "togethercomputer/llama-2-70b-chat";
    const maxToken = 3218;
    const outlineResult = await fetchOutlineCompletion(
      promptOutline,
      model,
      maxToken
    );
    const jsonData = cleanData(outlineResult.choices[0].message.content);
    const cleanedJson = parseInput(jsonData);
    return filterChaptersWithFiveSections(cleanedJson);
  }

  // async function genBookOutline(title, pages) {
  //   const bookChapters = Math.ceil(pages / 5);
  //   const promptOutline = `Develop a comprehensive outline for a Fiction book called "${title}", encompassing of ${bookChapters} distinct chapters. Each with interesting plots and twists which keep the reader hooked. Each chapter should be denoted by integers like “**{CHAPTER HEADING}**”, and for each chapter, furnish 5 detailed bullet points as topics like 3.1, 3.2, 3.3, 3.4, 3.5 and don’t forget to end each topic with "/" like 3.1 Don’t let them do what they want to/ 3.2 Create a positive atmosphere/ 3.3 How to talk to someone/. This outline should be as short, focused and detailed to serve as a structured framework for a practical and informative guide on the topic. Don't use roman numbers. Each chapter must have 5 bullet points. Also include character name and small scene description. Be Creative and Unique.`;
  //   const model = "meta-llama/Llama-3-8b-chat-hf"
  //   const maxToken = 5153
  //   const outlineResult = await fetchOutlineCompletion(promptOutline,model,maxToken);
  //   const jsonData = cleanData(outlineResult.choices[0].message.content);
  //   const cleanedJson = parseInput(jsonData);
  //   return filterChaptersWithFiveSections(cleanedJson);
  // }

  async function regenerateBookOutline(title, genre, pages) {
    const bookChapters = Math.ceil(pages / 5);
    const promptOutline = `Develop a comprehensive outline for a Fiction book called "${title}", encompassing of ${bookChapters} distinct chapters. Each with interesting plots and twists which keep the reader hooked. Each chapter should be denoted by integers like “**{CHAPTER HEADING}**”, and for each chapter, furnish 5 detailed bullet points as topics like 3.1, 3.2, 3.3, 3.4, 3.5 and don’t forget to end each topic with "/" like 3.1 Don’t let them do what they want to/ 3.2 Create a positive atmosphere/ 3.3 How to talk to someone/. This outline should be as short, focused and detailed to serve as a structured framework for a practical and informative guide on the topic. Don't use roman numbers. Each chapter must have 5 bullet points. Also include character name and small scene description. Be Creative and Unique.`;
    const model = "mistralai/Mixtral-8x7B-Instruct-v0.1";
    const maxToken = 18702;
    const outlineResult = await fetchOutlineCompletion(
      promptOutline,
      model,
      maxToken
    );
    const jsonData = cleanData(outlineResult.choices[0].message.content);
    const cleanedJson = parseInput(jsonData);
    return cleanedJson;
  }

  const bookOutline = async (bookDescription, bookGenre, bookPages) => {
    // Generate Book Title
    const title = await generateBookTitle(bookDescription, bookGenre);

    // Attempt to generate and validate the book outline with retries
    let outline = await generateBookOutline(title, bookGenre, bookPages);
    let attempts = 1;

    // Retry up to 2 times if the outline is empty
    while (outline.length === 0 && attempts < 3) {
      const newTitle = await regenerateBookTitle(bookDescription, bookGenre);
      console.log(
        `Retrying generation of book outline... Attempt ${attempts + 1}`
      );
      outline = await regenerateBookOutline(newTitle, bookGenre, bookPages);
      attempts++;
    }

    if (outline.length === 0) {
      outline = await regenerateBookOutline(title, bookGenre, bookPages);
      await fetchUpdateOutline(title, outline);
      console.log("Failed to generate a valid outline after 2 attempts.");
      console.log("Book data updated successfully.");
      return;
    }

    // Update book data
    await fetchUpdateOutline(title, outline);
    console.log("Book data updated successfully.");
  };

  /////////////////
  const getBookType = async () => {
    try {
      const response = await axios.get(
        `https://backend-production-bcc4.up.railway.app/get-type/${twelveDigitValue}`
      );
      // console.log('Book Type:', response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching book type:", error);
      throw error;
    }
  };

  useEffect(() => {
    const fetchJsonData = async () => {
      const fetchedBookType = await getBookType();
      if (fetchedBookType === "Fictional") {
        // console.log("True",fetchedBookType)

        try {
          const twentyDigitValue = twelveDigitValue;
          const fallbackResponse = await fetch(
            `https://backend-production-bcc4.up.railway.app/get-fiction-book/${twentyDigitValue}`
          );
          // console.log(fallbackResponse);
          const fallbackJsonData = await fallbackResponse.json();
          if (fallbackJsonData[0].book_title === null) {
            bookOutline(
              fallbackJsonData[0].book_description,
              fallbackJsonData[0].genre,
              fallbackJsonData[0].pages
            );
            setMaxValue(fallbackJsonData[0].pages);
          } else {
            // console.log("Not Empty")
          }

          // console.log(fallbackJsonData);
          setUserAccess(fallbackJsonData[0].user_id);
          setMaxValue(fallbackJsonData[0].pages);
          // console.log(fallbackJsonData[0].book_description)
          setBookGenre(fallbackJsonData[0].genre);
          setBookTitle(fallbackJsonData[0].book_title);
          setSidebarData(fallbackJsonData[0].json_data);
          // console.log(fallbackJsonData[0].json_data)
          setBookCost(fallbackJsonData[0].total_cost);
          if (fallbackJsonData[0].generated) {
            setGenerated(true);
            // console.log("True")
            // console.log(sidebarData)
            navigate(`/${twentyDigitValue}/0/0`);
          } else {
            setGenerated(false);
            // console.log("False")
            // console.log(sidebarData)
          }
        } catch (error) {
          console.error("TRUE Error fetching fallback JSON data:", error);
        }
      } else {
        console.log("False", fetchedBookType);

        try {
          const response = await fetch(
            `https://backend-production-bcc4.up.railway.app/get-json/${twelveDigitValue}`
          );
          // console.log(response);
          const jsonData = await response.json();
          setBookGenre(jsonData[0].genre);
          setBookTitle(jsonData[0].book_title);
          setBookCost(jsonData[0].total_cost);
          setSidebarData(jsonData);
          setGenerated(true);
          navigate(`/${twelveDigitValue}/0/0`);
        } catch (error) {
          console.error("FALSE Error fetching fallback JSON data:", error);
        }
      }

      const fetchUserCredits = async () => {
        try {
          // Replace 'your-credits-endpoint' with the actual endpoint to fetch user credits
          const response = await axios.get(
            `https://backend-production-bcc4.up.railway.app/fetch/${user.id}`
          );

          return response.data.total_credits; // Assuming the response contains the user's total credits
        } catch (error) {
          console.error("Error fetching user credits:", error);
          throw error;
        }
      };

      if (generation) {
        const userLogged = user.id;
        setFetchingContent(true);

        const userCredits = await fetchUserCredits(); // You need to implement this function to fetch user credits

        if (userCredits <= bookCost) {
          // If the user doesn't have sufficient credits, redirect them to /balance
          //  console.error('Insufficient credits. Redirecting to /Balance.');
          navigate("/Credit");
        } else {
          handleReduceCredits(userLogged, bookCost);
          const sideData = await modifyData(sidebarData);
          const generatedBool = true;
          fetchUpdateOutline(bookTitle, sideData, generatedBool);
          setSidebarData(sideData);
          setGeneration(false);
          setFetchingContent(false);
          setGenerated(true);
        }
      }
    };

    fetchJsonData();
  }, [twelveDigitValue, generation]);

  return (
    <div>
      <div>
        <LandingMenu />
      </div>
      {generation ? (
        <div className="flex mt-72 justify-center items-center">
          <motion.div
            className="relative box w-52 h-52 bg-purple-400"
            animate={{
              scale: [1, 2, 2, 1, 1],
              rotate: [0, 0, 180, 180, 0],
              borderRadius: ["0%", "0%", "50%", "50%", "0%"],
            }}
            transition={{
              duration: 2,
              ease: "easeInOut",
              times: [0, 0.2, 0.5, 0.8, 1],
              repeat: Infinity,
              repeatDelay: 1,
            }}
          />
          <motion.div
            className="relative box w-52 h-52 bg-purple-300"
            animate={{
              scale: [1, 2, 2, 1, 1],
              rotate: [0, 0, 180, 180, 0],
              borderRadius: ["30%", "20%", "100%", "100%", "40%"],
            }}
            transition={{
              duration: 2,
              ease: "easeInOut",
              times: [0, 0.2, 0.5, 0.8, 1],
              repeat: Infinity,
              repeatDelay: 2,
            }}
          />
          {fetchContent ? (
            <>
              <ProgressBar
                progress={currentValue}
                goal={maxValue}
                show={true}
              />
              <h1 className="absolute text-5xl text-white font-bold">
                Crafting Book
              </h1>
            </>
          ) : (
            <h1 className="absolute text-5xl text-white font-bold">
              Crafting Book
            </h1>
          )}
        </div>
      ) : (
        <>
          {sidebarData ? (
            <div>
              <div style={{ margin: "40px" }}>
                {generated ? (
                  <Sidebar initialData={sidebarData} />
                ) : (
                  <div>
                    {!shown && (
                      <div className="flex justify-center items-center mt-4 bg-purple-400 bg-opacity-40 backdrop-blur-md rounded-2xl shadow-xl p-2 mb-4 space-x-4">
                        <p className="text-white text-opacity-80">
                          Review the book outline before generating.
                        </p>
                        <CustomButton
                          ButtonName={buttonText}
                          onClick={handleGenerate}
                        />
                      </div>
                    )}
                    <SidebarMod initialData={sidebarData} />
                    {!shown && (
                      <div>
                        <ConfirmPop
                          isOpen={isModalOpen}
                          setIsOpen={setIsModalOpen}
                        />
                        <BookPop
                          isOpen={isErrorModalOpen}
                          setIsOpen={setIsErrorModalOpen}
                          bookCost={bookCost}
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="flex mt-72 justify-center items-center">
              <motion.div
                className="relative box w-52 h-52 bg-purple-400"
                animate={{
                  scale: [1, 2, 2, 1, 1],
                  rotate: [0, 0, 180, 180, 0],
                  borderRadius: ["0%", "0%", "50%", "50%", "0%"],
                }}
                transition={{
                  duration: 2,
                  ease: "easeInOut",
                  times: [0, 0.2, 0.5, 0.8, 1],
                  repeat: Infinity,
                  repeatDelay: 1,
                }}
              />
              <motion.div
                className="relative box w-52 h-52 bg-purple-300"
                animate={{
                  scale: [1, 2, 2, 1, 1],
                  rotate: [0, 0, 180, 180, 0],
                  borderRadius: ["30%", "20%", "100%", "100%", "40%"],
                }}
                transition={{
                  duration: 2,
                  ease: "easeInOut",
                  times: [0, 0.2, 0.5, 0.8, 1],
                  repeat: Infinity,
                  repeatDelay: 2,
                }}
              />
              <h1 className="absolute text-5xl text-white font-bold">
                Crafting Outline
              </h1>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default BookViewer;
