
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import FeaturedPlayListIcon from '@mui/icons-material/FeaturedPlayList';
import BookIcon from '@mui/icons-material/Book';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import CastleIcon from '@mui/icons-material/Castle';


const Feature = () => {
  return (
    <div className="p-8 mx-auto max-w-screen-lg">
      <div className="text-center mb-8">
        <h2 className="text-2xl font-bold leading-tight text-gray-900 sm:text-3xl mt-4">
          Roadmap
        </h2>
        <p className="text-base text-gray-700 mt-2">
        We're a bunch of AI enthusiasts dedicated to making AI as smooth as a penguin sliding on ice. Our epic game plan? Craft these mind-boggling tools
        </p>
      </div>
      <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
        {features.map((feature, index) => (
          <div key={index} className="bg-white p-6 rounded-lg shadow-md flex items-center">
            <div className="flex items-center justify-center w-12 h-12 mb-4 rounded-full bg-indigo-500">
              {feature.icon}
            </div>
            <div className="ml-4">
              <h6 className="text-lg font-bold leading-5 text-gray-900">{feature.title}</h6>
              <p className="text-sm text-gray-700 mt-2">{feature.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};


const features = [
  {
    title: "NonFictional Books",
    description: "Create informative and factual books.",
    icon: (
      <AutoStoriesIcon className='text-white'/>
    ),
  },
  {
    title: "Fictional Books",
    description: "Craft engaging and imaginative stories.",
    icon: (
      <CastleIcon className='text-white'/>
    ),
  },
  {
    title: "Small Story Books",
    description: "Create short and captivating storybooks.",
    icon: (
      <BookIcon className='text-white'/>
    ),
  },
  {
    title: "Moral Story Books",
    description: "Develop books with moral lessons and values.",
    icon: (
      <FeaturedPlayListIcon className='text-white'/>
    ),
  },
  {
    title: "Quotes Book",
    description: "Share inspiring and thought-provoking quotes.",
    icon: (
      <FormatQuoteIcon className='text-white'/>
    ),
  },
  {
    title: "Poems Book",
    description: "Compose beautiful and expressive poems.",
    icon: (
      <HistoryEduIcon className='text-white'/>
    ),
  },
  // {
  //   title: "Comic Books",
  //   description: "Design entertaining and visually appealing comics.",
  //   icon: (
  //     <svg
  //       className="w-6 h-6 text-white"
  //       stroke="currentColor"
  //       viewBox="0 0 24 24"
  //       fill="none"
  //       strokeWidth="2"
  //       strokeLinecap="round"
  //       strokeLinejoin="round"
  //     >
  //       {/* Comic Books icon */}
  //       <rect x="3" y="4" width="18" height="14" rx="2" ry="2" />
  //       <line x1="7" y1="4" x2="7" y2="18" />
  //       <line x1="17" y1="4" x2="17" y2="18" />
  //     </svg>
  //   ),
  // },
];


export default Feature;
