import React from 'react';
import InputBox from './FictionGen';
import "../NonFictional/glass.css";

const FictionDash = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '800px',
        backgroundSize: 'cover',
        backdropFilter: 'blur(10px)',
      }}
    >
      <div
        style={{
          width: '800px',
          height: '450px',
          background: 'rgba(255, 255, 255, 0.2)',
          borderRadius: '10px',
          boxShadow: '1px 0 4px 6px rgba(0, 0, 0, 0.1)',
          textAlign: 'center',
          padding: '20px',
        }}
      >
        <div className='justify-center items-center mr-2'>
           <InputBox/>
        </div>
      </div>
    </div>
  );
};

export default FictionDash;


