import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

class BookList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      books: [
        { id: 1, title: 'SaaS-cending Success: Navigating the Uncharted Waters of Software as a Service', author: 'NON FICTIONAL', link: '/SxlcaRUVmdZRAhXr' },
        { id: 2, title: 'Unleashing the Unstoppable You: A Journey to Confidence Through Self-Discovery and Empowerment', author: 'NON FICTIONAL', link: '/GwElbvGfAozKWQWi' },
        { id: 3, title: 'From Dirt to Dollars: The Ultimate Guide to Starting a Car Cleaning Business and Thriving in the Fast Lane', author: 'NON FICTIONAL', link: '/jMsGLuixaJLORBrp' },
        { id: 1, title: 'The Numbing Paradox: A Journey Through the Fractured Landscape of Emotion', author: 'NON FICTIONAL', link: '/txgdsfEbrnBRosZU' },
        { id: 2, title: 'Pain: The Silent Assassin - A Journey Through the Shadows of the Mind', author: 'NON FICTIONAL', link: '/sSsaMByufrKwi' },
        { id: 3, title: 'The Focus Paradox: Why Being Unfocused is the Key to Ultimate Productivity', author: 'NON FICTIONAL', link: '/YImWCaqxsyci' },
      ],
    };
  }

  render() {
    // Animation variants
    const listVariants = {
      hidden: { opacity: 0 },
      visible: {
        opacity: 1,
        transition: {
          when: "beforeChildren",
          staggerChildren: 0.1,
        },
      },
    };

    const itemVariants = {
      hidden: { y: 20, opacity: 0 },
      visible: {
        y: 0,
        opacity: 1,
        transition: {
          type: 'spring',
          stiffness: 100
        }
      },
    };

    return (
      <motion.div
        className="container mx-auto p-8"
        variants={listVariants}
        initial="hidden"
        animate="visible"
      >
        <h1 className="text-4xl font-extrabold text-center text-gray-800 mb-10">Recently Created Books</h1>
        <motion.div
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10"
          variants={listVariants}
        >
          {this.state.books.map((book) => (
            <motion.div
              key={book.id}
              className="bg-white rounded-xl overflow-hidden shadow-lg hover:shadow-2xl transition-shadow duration-300 ease-in-out"
              variants={itemVariants}
            >
              <div className="p-6">
                <p className="text-xs font-semibold text-purple-600 uppercase tracking-wide">{book.author}</p>
                <a
                  href={book.link}
                  className="block mt-2 text-lg font-semibold text-gray-800 hover:text-purple-600 transition duration-300"
                  aria-label="Read article"
                  title={book.title}
                >
                  {book.title}
                </a>
                <Link to={book.link} className="inline-block mt-4">
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className="text-sm px-5 py-2 bg-purple-200 text-purple-800 rounded-full hover:bg-purple-300 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-opacity-50 transition duration-300"
                  >
                    Read more
                  </motion.button>
                </Link>
              </div>
            </motion.div>
          ))}
        </motion.div>
      </motion.div>
    );
  }
}

export default BookList;

