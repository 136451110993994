import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useUser } from "@clerk/clerk-react";
import { Link } from 'react-router-dom'; 


const AllBooks = () => {
  const [books, setBooks] = useState([]);
  const { user } = useUser();
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedBookType, setSelectedBookType] = useState(''); // Default to all types
  const [selectedCreationFilter, setSelectedCreationFilter] = useState('desc'); // Options: 'asc', 'desc'

  useEffect(() => {
    if (user) {
      // Fetch all books for the specified user ID
      axios.get(`https://backend-production-bcc4.up.railway.app/get-all-books/${user.id}`)
        .then(response => {
          setBooks(response.data.books);
        })
        .catch(error => {
          console.error('Error fetching books:', error);
        });
    }
  }, [user]);

// Filter books based on the search query, book type, and creation date
const filteredBooks = books.filter(book =>
  book.bookDescription.toLowerCase().includes(searchQuery.toLowerCase()) &&
  (selectedBookType === '' || book.bookType === selectedBookType)
);

// Sort filteredBooks based on creation date
filteredBooks.sort((a, b) => {
  const dateA = new Date(a.createdAt);
  const dateB = new Date(b.createdAt);

  if (selectedCreationFilter === 'asc') {
    return dateA - dateB;
  } else if (selectedCreationFilter === 'desc') {
    return dateB - dateA; // Corrected the order for Newest First
  }
  return 0;
});


  return (
    <div className="container mx-auto p-6 bg-white shadow-lg rounded-lg mb-10">
      <h2 className="text-3xl font-semibold text-gray-800">All Books</h2>
      
      {/* Search input */}
      <input
        type="text"
        placeholder="Search by book description"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        className="mt-2 py-2 px-8 mr-3 border border-gray-300 rounded focus:outline-none focus:border-indigo-500"
      />

      {/* Book type filter */}
      <select
        value={selectedBookType}
        onChange={(e) => setSelectedBookType(e.target.value)}
        className="mt-2 p-2 px-6 mr-3 border border-gray-300 rounded"
      >
        <option value="">All Book Types</option>
        {/* Add options for each book type */}
        <option value="NonFictional">Non Fictional</option>
        <option value="Fictional">Fictional</option>
        {/* Add more options as needed */}
      </select>

      {/* Creation date filter */}
      <select
        value={selectedCreationFilter}
        onChange={(e) => setSelectedCreationFilter(e.target.value)}
        className="mt-2 p-2 border border-gray-300 rounded"
      >
        <option value="">Sort by Creation Date</option>
        <option value="asc">Oldest First</option>
        <option value="desc">Newest First</option>
      </select>

      {filteredBooks.length === 0 ? (
        <p className="text-gray-600">No books found for the specified user ID</p>
      ) : (
        <ul className="divide-y divide-gray-200">
          {filteredBooks.map((book) => (
            <li key={book.twelveDigitValue} className="py-4 sm:flex sm:items-center sm:justify-between">
              <div className="min-w-0 w-full">
                <p className="text-sm font-semibold leading-6 text-gray-900">{book.bookTitle}</p>
                <p className="mt-1 text-xs leading-5 text-gray-500">
                  {book.bookDescription}
                  <br />
                  <span className="mt-1 text-xs leading-5 text-gray-500">
                    {new Date(book.createdAt).toLocaleDateString()}
                  </span>
                </p>
              </div>
              <div className="mt-3 sm:mt-0 sm:flex sm:flex-col sm:items-end">
                <p className="text-sm leading-6 text-gray-900">
                  <div className="mr-3">{book.bookType}</div>
                </p>
                <Link
                  to={`/${book.twelveDigitValue}`}
                  className="text-blue-500 hover:underline mt-2 block sm:inline-block"
                >
                  <button className="bg-indigo-200 text-white rounded-2xl mt-2 sm:mt-0">
                    View Book
                  </button>
                </Link>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default AllBooks;
