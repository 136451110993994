import { useState, useEffect } from 'react'
import { Dialog } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import logo from '../logo.png';
import {Link} from "react-router-dom";
import { UserButton, UserProfile } from "@clerk/clerk-react";
import BookmarkIcon from '@mui/icons-material/Bookmark';
import WalletIcon from '@mui/icons-material/AccountBalanceWallet';
import axios from 'axios';

import { useUser } from "@clerk/clerk-react";

const navigation = [
  { name: 'Create', href: '/Create' },
  { name: 'Discord', href: 'https://discord.gg/eBYg9ZmjZa', target: '_blank', rel: 'noopener noreferrer' },
  { name: 'Support', href: '/Support' }
]

const navigat = [
  { name: 'Create', href: '/Create' },
  { name: 'Guide', href: '/Support' },
  { name: 'Support', href: '/Support' }
]

const LandingMenu = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [userId, setUserId] = useState();
  const [userData, setUserData] = useState({});
  const { user } = useUser();
  const [checked, setChecked] = useState(true);

  
  ////////////////
  const handleInsert = async () => {
    try {
      const requestBody = {
        user_id: user.id, // assuming 'user.id' contains the user's ID
        email: user.primaryEmailAddress.emailAddress // assuming 'user.email' contains the user's email
      };
  
      const response = await axios.post(`https://backend-production-bcc4.up.railway.app/insert`, requestBody);
      console.log(response.data);
      // window.location.reload(); // Uncomment this line if you need to reload the page after the operation
    } catch (error) {
      console.error('Error inserting row:', error);
    }
  };
  

  const handleFetch = async () => {
      try {
        const response = await axios.get(`https://backend-production-bcc4.up.railway.app/fetch/${user.id}`);
    
        const { status, data } = response;
    
        if (status === 200) {
          console.log(data)
          if(data.exists){
            setUserData(data)
            setChecked(false);
          } else {
            await handleInsert()
           await handleFetch()
           setChecked(false);
          }
  
          return data.exists;
        } else {
          console.error(`Failed to fetch user: ${status}`);
          return false;
        }
      } catch (error) {
        console.error(`Error checking user exists: ${error}`);
        return false;
      }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (user) {
        try {
          const response = await axios.get(`https://backend-production-bcc4.up.railway.app/fetch/${user.id}`);
          const { status, data } = response;
          if (status === 200 && data.exists) {
            setUserData(data);
          } else {
            const insertResponse = await axios.post(`https://backend-production-bcc4.up.railway.app/insert`, {
              user_id: user.id,
              email: user.primaryEmailAddress.emailAddress
            });
            console.log(insertResponse.data);
            fetchData();  // Recursively fetch data after insertion
          }
        } catch (error) {
          console.error(`Error fetching user data: ${error}`);
        }
      }
    };

    fetchData();
  }, [user]);
  ////////////////

  return (
    <div className="bg-white m-5 rounded-xl">
      <header className="absolute inset-x-0 top-0 z-50">
        <nav className="flex items-center justify-between p-6 lg:px-20" aria-label="Global">
          <div className="flex lg:flex-1">
            
          <Link to="/Home" className="-m-1.5 p-1.5">
  <span className="sr-only">Swift Ai Writer</span>
  <img
    className="h-12 w-auto"
    src={logo}
    alt=""
  />
</Link>
          </div>
          <div className="flex lg:hidden">
          <Link to={`/Credit`}> 
          <span className="ml-3 mr-3 font-semibold bg-purple-200 text-lg text-white-100 rounded-md p-1">
            {JSON.stringify(userData.total_credits, null, 2)}
            </span>
            </Link>
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          
          <div className="hidden lg:flex lg:gap-x-12">
            {navigat.map((item) => (
              <a key={item.name} href={item.href} className="text-sm font-semibold leading-6 text-gray-900">
                {item.name}
              </a>
            ))}
          </div>
          
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            <a className="text-sm font-semibold leading-6 text-gray-900">
            <div className="w-70 h-12 bg-purple-100 flex items-center justify-between rounded pl-4 pr-5 shadow-md">
            <Link to="/MyBook">
            <span className="ml-3 mr-3 font-semibold bg-purple-200 text-white-100 rounded-md p-1 pb-2 pl-3 pr-3 mt-2">
        <BookmarkIcon style={{ color: 'white' }} />
             Books</span>
      </Link>
      <Link to="/Credit">
      <span className="ml-3 mr-3 font-semibold bg-purple-200 text-white-100 rounded-md p-1">{JSON.stringify(userData.total_credits, null, 2)}</span>
      </Link>
      <UserButton />
    </div>
            </a>
          </div>
        </nav>
        <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
          <div className="fixed inset-0 z-50" />
          <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <a href="/Home" className="-m-1.5 p-1.5">
                <span className="sr-only">Swift AI Writer</span>
                <img
                  className="h-8 w-auto"
                  src={logo}
                  alt=""
                />
              </a>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      target={item.target || undefined}
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
                <div className="py-6">
                <Link to="/Account">
                <div className="w-70 h-12 bg-indigo-200 flex items-center justify-between rounded pl-4 pr-5 shadow-md">
               <UserButton />
                <span className="ml-3 mr-3 font-semibold text-lg text-white-300 rounded-md p-2 pr-4 pl-4">DashBoard</span>
               </div>
               </Link>
    <Link to="/MyBook">
          <div className="w-70 h-12 bg-indigo-200 flex items-center justify-between rounded pl-4 pr-5 shadow-md mt-3"> 
            <BookmarkIcon style={{ color: 'white' ,fontSize: 30}} />
          <span className="ml-3 mr-3 font-semibold text-lg text-white-300 rounded-md p-2 pr-4 pl-4">Books</span>
          </div>
    </Link>
    <Link to="/Credit">
    <div className="w-70 h-12 bg-indigo-200 flex items-center justify-between rounded pl-4 pr-5 shadow-md mt-3">
            <WalletIcon style={{ color: 'white' ,fontSize: 30}} />
      <span className="ml-3 mr-3 font-semibold text-lg text-white-300 rounded-md p-2 pr-4 pl-4">Credit</span>
    </div>
    </Link>
    
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>
      <div/>
      <div className="relative isolate px-6 pt-14 lg:px-8">
        </div>
        <div
          className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
          
        </div>
      </div>
  )
}

export default LandingMenu;