import React from 'react';
// import InputBox from '../BookGen';
import InputBox from './Cra';
import "./glass.css"

const GlassmorphicCard = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '800px',
        backgroundImage: `url('your-background-image.jpg')`, // Replace with your background image path
        backgroundSize: 'cover',
        backdropFilter: 'blur(10px)',
      }}
    >
      <div
        style={{
          width: '800px',
          height: '600px',
          background: 'rgba(255, 255, 255, 0.2)',
          borderRadius: '10px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          textAlign: 'center',
          padding: '20px',

        }}
      >

        <InputBox/>

      </div>
    </div>
  );
};

export default GlassmorphicCard;
