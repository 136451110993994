import React, { useEffect } from 'react';
import { initGA, trackPage } from './analytics';
import MenuHead from '../Components/Fiction/LandingMenuSign';
import AllBooks from '../Components/MyBook/AllBooks';
import { SignedOut, SignIn, SignedIn } from "@clerk/clerk-react";
import Footer from '../Components/Footer';


const MyBook = () => {
  useEffect(() => {
    initGA(); // This might be better placed in your index.js or App.js file
    trackPage(window.location.pathname + window.location.search);
  }, []);
  return (
    <div className="flex flex-col min-h-screen">
      <SignedOut>
        <div className="flex-grow flex items-center justify-center">
          <SignIn redirectUrl="/MyBook" />
        </div>
      </SignedOut>
      <SignedIn>
        <div className="flex-grow flex flex-col">
          <MenuHead />
          <div className="flex-grow">
            <AllBooks/>
          </div>
          <Footer />
        </div>
      </SignedIn>
    </div>
  );
};

export default MyBook;
