
import React, { useEffect } from 'react';
import { initGA, trackPage } from './analytics';
import MenuHead from '../Components/Fiction/LandingMenuSign';
import TransactionsList from '../Components/Credit/Transactions';
import Topup from '../Components/Credit/Topup'
import { SignedOut, SignIn, SignedIn, UserProfile } from "@clerk/clerk-react";
import Footer from '../Components/Footer';
import CreditList from '../Components/Credit/CreditLog';





function Fictional() {
  useEffect(() => {
    initGA(); // This might be better placed in your index.js or App.js file
    trackPage(window.location.pathname + window.location.search);
  }, []);
  
  return (
    <>
        <div className="h-screen">
          <SignedOut>
          <div className="flex items-center justify-center h-screen">
            <SignIn redirectUrl="/Credit"/>
          </div>
        </SignedOut>
    <SignedIn>
    <div>
      <MenuHead/>
      <div class='flex flex-col md:flex-row'>
    <div class='flex-1'>
        <Topup/>
        <TransactionsList/>
        <CreditList/>
    </div>
</div>

      <Footer/>

    </div>
    </SignedIn>
    </div>
    </>
  );
}

export default Fictional;
