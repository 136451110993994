import React from 'react';

const BookDescriptionInput = ({ value, onChange }) => {
  const maxCharacterLimit = 2000;

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    if (inputValue.length <= maxCharacterLimit) {
      onChange(e);
    }
  };

  return (
    <div>
      <textarea
        className="bg-gray-100 rounded-lg w-full p-6 text-gray-800 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 font-sans text-lg"
        placeholder="Enter book description..."
        value={value}
        onChange={handleInputChange}
        style={{ height: '165px', border: '2px solid #C697FF' }}
      />

      <div className="text-right text-gray-500">
        {value.length} / {maxCharacterLimit} characters
      </div>
    </div>
  );
};

export default BookDescriptionInput;
