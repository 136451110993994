import React, { useEffect } from 'react';
import { initGA, trackPage } from './analytics';
import MenuHead from '../Components/NonFictional/LandingMenu';
import Footer from '../Components/Footer';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';


const PrivacyPolicy = () => {
  useEffect(() => {
    initGA(); // This might be better placed in your index.js or App.js file
    trackPage(window.location.pathname + window.location.search);
  }, []);
  return (
    <>
        <div className="h-screen">
        <MenuHead/>
    <div>
    <section class="text-gray-600 body-font">
  <div class="container px-5 py-24 mx-auto flex flex-wrap">
    <h1 class="sm:text-6xl text-12xl text-purple-300 font-medium title-font mb-2 md:w-2/5">Privacy Policy</h1>
    <div class="md:w-3/5 md:pl-6">
    <div className="privacyPolicy">
        <div className='text-5xl text-gray-300' >1. Introduction</div>
        <div className='text-2xl '>Welcome to Swift AI Writer, a premier AI-powered writing service. We understand the importance of privacy in the digital age and are fully committed to protecting the personal information of our users. This Privacy Policy details how we collect, use, and safeguard your data, ensuring transparency and trust in our services.</div>

        <div className='text-5xl text-gray-300 mt-4' >2. Information We Collect</div>
        <div className='text-2xl '>To deliver our cutting-edge writing solutions, we gather various types of information:</div>
        <ul className='mt-4'>
        <div className='text-2xl '><li><strong>Personal Information:</strong> This includes but is not limited to your name, email address, and contact details, which you provide when registering with the Service or engaging in transactions.</li></div>
        <div className='text-2xl '><li><strong>Usage Information:</strong> We collect data related to how you use the Service, including IP address, browser type, device information, pages visited, and time spent on our platform.</li></div>
        <div className='text-2xl '><li><strong>User-Generated Content:</strong> Any documents, text, or other content you create, upload, or share using the Service is collected.</li></div>
        </ul>

        <div className='text-5xl text-gray-300 mt-4'>3. How We Use Your Information</div>
        <div className='text-2xl '>Your information is crucial in helping us provide a high-quality service:</div>
        <ul className='mt-4'>
        <div className='text-2xl '><li><strong>Service Provision:</strong> We use your information to operate, maintain, and provide the features and functionality of the Service.</li></div>
        <div className='text-2xl '><li><strong>Customer Support:</strong> Your personal details help us respond effectively to your support requests and feedback.</li></div>
        <div className='text-2xl '><li><strong>Personalization:</strong> We analyze the usage information to understand your preferences and habits, aiming to personalize your experience and present you with content that suits your interests.</li></div>
        <div className='text-2xl '><li><strong>Legal Compliance:</strong> We may use your information to comply with legal obligations, such as responding to legal processes or enforceable government requests.</li></div>
        </ul>

        <div className='text-5xl text-gray-300 mt-4' >4. Cookies and Tracking Technologies</div>
        <div className='text-2xl '>To enhance your experience:</div>
        <ul className='mt-4'>
        <div className='text-2xl '><li><strong>Cookies:</strong> We use cookies to store certain types of information each time you use our service. This allows for easier navigation, personalization, and a more streamlined service.</li></div>
        <div className='text-2xl '><li><strong>Tracking Technologies:</strong> Other technologies such as beacons, tags, and scripts are utilized to analyze trends, administer the website, and track users' movements around the Service.</li></div>
        </ul>

        <div className='text-5xl text-gray-300 mt-4' >5. Third-Party Links and Services</div>
        <div className='text-2xl '>While navigating our Service, you might encounter links to third-party websites or use third-party services:</div>
        <ul className='mt-4'>
        <div className='text-2xl '><li><strong>External Links:</strong> These websites have their own privacy policies and terms of use, which we strongly advise you to review. We do not endorse or assume any responsibility for the privacy practices of these external sites.</li></div>
        <div className='text-2xl '><li><strong>Third-Party Services:</strong> When you use third-party services integrated with Swift AI Writer, they may receive information about what you share with them. Their use of your information is governed by their respective privacy policies.</li></div>
        </ul>

        <div className='text-5xl text-gray-300 mt-4' >6. Data Security</div>
        <div className='text-2xl '>To secure your personal information:</div>
        <ul className='mt-4'>
        <div className='text-2xl '><li><strong>Security Measures:</strong> We employ industry-standard security measures, including encryption and physical security, to prevent unauthorized access and ensure data integrity.</li></div>
        <div className='text-2xl '><li><strong>Continuous Monitoring:</strong> Our systems are regularly reviewed and updated to tackle emerging security threats and vulnerabilities.</li></div>
        </ul>

        <div className='text-5xl text-gray-300 mt-4' >7. Your Choices</div>
        <div className='text-2xl '>You have significant control over your information:</div>
        <ul className='mt-4'>
        <div className='text-2xl '><li><strong>Account Settings:</strong> You can review, update, or delete your personal information through your account settings.</li></div>
        <div className='text-2xl '><li><strong>Communication Preferences:</strong> You can manage your preferences for marketing communications from us.</li></div>
        <div className='text-2xl '><li><strong>Data Rights:</strong> Depending on your location, you might have rights to access, rectify, or erase your personal data, or object to certain data processing activities.</li></div>
        </ul>

        <div className='text-2xl '>We encourage you to review this Privacy Policy regularly to stay informed about our information practices and the ways you can help protect your privacy. If you have any questions or concerns regarding this policy or our practices, please contact us directly. Your continued use of Swift AI Writer after any changes to these terms constitutes your consent to the changes.</div>
      </div>
      <div class="flex md:mt-4 mt-6">
      <a href='/Support' class="text-indigo-500 inline-flex items-center ml-4">
      <ChevronLeftIcon fontSize='large'/>
      <button class="inline-flex text-white bg-indigo-500 border-0 py-4 px-5 text-lg font-semibold focus:outline-none hover:bg-indigo-600 rounded">About</button>
      </a>
      <a href='/TermsOfService' class="text-indigo-500 inline-flex items-center ml-4">
        <button class="inline-flex text-white bg-indigo-500 border-0 py-4 px-5 text-lg font-semibold focus:outline-none hover:bg-indigo-600 rounded">Terms Of Service</button>
        <ChevronRightIcon fontSize='large'/>
        </a>
      </div>
    </div>
  </div>
</section>
<Footer/>
    </div>

    </div>
    </>
  );
}

export default PrivacyPolicy;