
import React from 'react';
import MenuHead from '../Components/NonFictional/LandingMenu';
import Selector from '../Components/Create/Create';
function Create() {
  return (
    <div>
      <MenuHead/>
<Selector/>
    </div>
  );
}

export default Create;
