// Sidebar.js

import { useParams, useNavigate } from 'react-router-dom';

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Sidebar.css';
import PageStats from './PageStats'

const Sidebar = ({ initialData, initialChapterIndex, initialSectionIndex }) => {
  const { twelveDigitValue } = useParams();
  const [data, setData] = useState(initialData);
  const [selectedSection, setSelectedSection] = useState(null);
  const [selectedChapterIndex, setSelectedChapterIndex] = useState(null);
  const [selectedSectionIndex, setSelectedSectionIndex] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [editedParagraph, setEditedParagraph] = useState('');

  const navigate = useNavigate();  // Add this line to get the history object

  const fetchChapterCompletion = async (bookChapterTitle, topicName) => {
    const url = 'https://api.together.xyz/v1/chat/completions';
    const apiKey = '589294927715a2799082908be7dc71c25c1c2d6058855018f5134b15bd5c69dc';

    const headers = new Headers({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${apiKey}`
    });

    const data = {
      model: 'mistralai/Mixtral-8x7B-Instruct-v0.1',
      max_tokens: 30024,
      messages: [
        {
          role: 'system',
          content: 'You are an AI assistant'
        },
        {
          role: 'user',
          content: `Write a 1000 English words book chapter named ${bookChapterTitle}. It will go through the following topics: ${topicName}. I don't want transition words. Don't write chapter name`
        }
      ]
    };

    const options = {
      method: 'POST',
      headers,
      body: JSON.stringify(data)
    };

    try {
      const response = await fetch(url, options);
      const result = await response.json();
      console.log(result);
      return result;
    } catch (error) {
      throw error;
    }
  };

  const handleSectionClick = (chapterIndex, sectionIndex) => {
    const selectedChapter = data[chapterIndex];
    const selectedSec = selectedChapter.sections[sectionIndex];

    setSelectedChapterIndex(chapterIndex); // Store the selected chapter index
    setSelectedSectionIndex(sectionIndex); // Store the selected section index

    setSelectedSection({
      chapterTitle: selectedChapter.title,
      sectionTitle: selectedSec.title,
      paragraph: selectedSec.paragraph,
    });

    if (selectedChapter) {
      navigate(`/${twelveDigitValue}/${0}/${0}`);
    };
    

    // Set the edited paragraph to the original paragraph when switching sections
    setEditedParagraph(selectedSec.paragraph);
    setEditMode(false);
  };

  const handleFetchAndUpdateClick = async () => {
    try {
      const result = await fetchChapterCompletion(
        selectedSection.chapterTitle,
        selectedSection.sectionTitle
      );

      console.log(result);

      // Update the paragraph with the fetched completion result
      setEditedParagraph(result.choices[0].message.content);
      console.log(editedParagraph);

      // Enter edit mode to allow the user to review and modify the fetched content
      setEditMode(true);

    } catch (error) {
      console.error('Error fetching chapter completion:', error);
    }
  };

  const handleEditClick = () => {
    setEditedParagraph(selectedSection.paragraph);
    setEditMode(true);
  };

  const handleSaveClick = async () => {
    // Send a request to the server to update the JSON file
    console.log(editedParagraph)
    console.log("selectedChapterIndex",selectedChapterIndex)
    console.log("selectedSectionIndex",selectedSectionIndex)
    try {
      await axios.post(`http://localhost:5001/update-json/${twelveDigitValue}`, {
        modifiedParagraph: editedParagraph,
        chapterIndex: selectedChapterIndex, // Pass the selected chapter index
        sectionIndex: selectedSectionIndex, // Pass the selected section index
      });


      // Refresh the data to reflect the changes
      // This assumes you have a function to fetch the updated data from the server
      // and set it to the component state (e.g., fetchDataAndUpdateState())
      // You need to implement this function based on your server-side logic.
      // Example: fetchDataAndUpdateState();

      // Exit edit mode after saving
      setEditMode(false);

          // Reload the entire page
    window.location.reload();

      console.log('Saved')
    } catch (error) {
      console.error('Error updating JSON file:', error);
    }
  };

  const handleCancelClick = () => {
    // Discard changes and exit edit mode
    setEditMode(false);
  };



  useEffect(() => {
    const handleSectionClick = (initialChapterIndex, initialSectionIndex) => {
      setData(data)
      if (data && data.length > 0) {
        const selectedChapter = data[initialChapterIndex];
        if (selectedChapter && selectedChapter.sections) {
          const selectedSec = selectedChapter.sections[initialSectionIndex];

          setSelectedChapterIndex(initialChapterIndex);
          setSelectedSectionIndex(initialSectionIndex);
  
          setSelectedSection({
            chapterTitle: selectedChapter.title,
            sectionTitle: selectedSec.title,
            paragraph: selectedSec.paragraph,
          });
        }
      }
    };
  
    handleSectionClick(initialChapterIndex, initialSectionIndex);
  }, [data, initialChapterIndex, initialSectionIndex]);
  
  return (
    <div className="sidebar-container">
      <div className="sidebar">
        <ul className="sidebar-list">
          {data.map((chapter, chapterIndex) => (
            <li key={chapterIndex} className="chapter">
              <p className="chapterTitle">{chapter.title.replace(/^Chapter (\d+): (.*)$/, '|$1| $2')}</p>
              <ul className="section-list">
                {chapter.sections.map((section, sectionIndex) => (
                  <li
                    key={sectionIndex}
                    className={`section ${selectedSection && selectedSection.sectionTitle === section.title ? 'selected' : ''}`}
                    onClick={() => handleSectionClick(chapterIndex, sectionIndex)}
                  >
                    {section.title}
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </div>
      {selectedSection && (
        <div className="content-display">
          {/* <h2>{selectedSection.chapterTitle}</h2>
          <h3>{selectedSection.sectionTitle}</h3> */}
          <div className={`button-container ${editMode ? 'edit-mode' : ''}`}>
            {editMode ? (
              <>
                <button onClick={handleSaveClick}>Save</button>
                <button className="cancel" onClick={handleCancelClick}>Cancel</button>
              </>
            ) : (
              <>
                <button onClick={handleEditClick}>Edit</button>
                <button onClick={handleFetchAndUpdateClick}>Fetch and Update</button>
              </>
            )}
          </div>
          {editMode ? (
            <textarea
              value={editedParagraph}
              onChange={(e) => setEditedParagraph(e.target.value)}
            />
          ) : (
            <>
            <p>{selectedSection.paragraph}</p>

            <PageStats selectedSection={selectedSection}/>
          </>
          )}
        </div>
      )}
    </div>
  );
};

export default Sidebar;