import React from 'react';

const DottedButton = ({ onClick }) => {
  const handleClick = () => {
    onClick(); // You can keep this line if you still want to invoke a click handler from the parent component
  };

  return (
    <button
      className="rounded-2xl border-2 border-dashed border-#C697FF bg-white px-6 py-3 font-semibold uppercase text-#C697FF text-base transition-all duration-300 hover:translate-x-[-4px] hover:translate-y-[-4px] hover:rounded-md hover:shadow-[4px_4px_0px_#C697FF] active:translate-x-[0px] active:translate-y-[0px] active:rounded-2xl active:shadow-none"
      onClick={handleClick}
    >
      Generate
    </button>
  );
};

export default DottedButton;
