// Sidebar.js


import React, { useState } from 'react';
import '../NonFictional/Sidebar.css';

const SidebarMod = ({ initialData }) => {
  const [data, setData] = useState(initialData);


  
  return (
    <div className="sidebar-container">
      <div className="sidebar">
        <ul className="sidebar-list">
          {data.map((chapter, chapterIndex) => (
            <li key={chapterIndex} className="chapter">
              <p className="chapterTitle">{chapter.title.replace(/^Chapter (\d+): (.*)$/, '|$1| $2')}</p>
              <ul className="section-list">
                {chapter.sections.map((section, sectionIndex) => (
                  <li
                    key={sectionIndex}
                    className={`section`}
                  >
                    {section.title}
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SidebarMod;