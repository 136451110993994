import React, { useEffect } from 'react';
import { initGA, trackPage } from './analytics';
import MenuHead from '../Components/Fiction/LandingMenuSign';
import GlassCard from '../Components/NonFictional/GlassCard';
import { SignedOut, SignIn, SignedIn } from "@clerk/clerk-react";


const NonFictional = () => {
  useEffect(() => {
    initGA(); // This might be better placed in your index.js or App.js file
    trackPage(window.location.pathname + window.location.search);

    // Redirect to talesage.com
    window.location.href = 'https://talesage.com';
  }, []);
  return (
    <div className="flex flex-col min-h-screen">
      <SignedOut>
        <div className="flex-grow flex items-center justify-center">
          <SignIn redirectUrl="/NonFictional" />
        </div>
      </SignedOut>
      <SignedIn>
        <div className="flex-grow flex flex-col">
          <MenuHead />
          <div className="flex-grow">
            <GlassCard />
          </div>
        </div>
      </SignedIn>
    </div>
  );
};

export default NonFictional;
