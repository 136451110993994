
import React, { useEffect } from 'react';
import { initGA, trackPage } from './analytics';
import MenuHead from '../Components/Fiction/LandingMenuSign';
import FictionDash from '../Components/Fiction/FictionDash';
import { SignedOut, SignIn, SignedIn } from "@clerk/clerk-react";
// import { useMediaQuery } from 'react-responsive';
// import FictionDashMobile from '../Components/Fiction/FictionDashMobile'



const Fictional = () => {
  // const isMobile = useMediaQuery({ maxWidth: 768 }) 
  useEffect(() => {
    initGA(); // This might be better placed in your index.js or App.js file
    trackPage(window.location.pathname + window.location.search);

    // Redirect to talesage.com
    window.location.href = 'https://talesage.com';
  }, []);
  return (
    <>
        <div className="h-screen">
          <SignedOut>
          <div className="flex items-center justify-center h-screen">
            <SignIn redirectUrl="/Fictional"/>
          </div>
        </SignedOut>
    <SignedIn>
    <div>
      <MenuHead/>
      {/* {!isMobile && ( */}
      <FictionDash/>
      {/* )} */}
      {/* {isMobile && ( */}
      {/* <FictionDashMobile/> */}
      {/* )} */}
    </div>
    </SignedIn>
    </div>
    </>
  );
}

export default Fictional;
