import React from 'react';
import { BrowserRouter as Router, Route, Navigate, Routes } from 'react-router-dom';

import Home from './Pages/Home';
import Support from "./Pages/Support";
import Create from "./Pages/Create";
import NonFictional from "./Pages/NonFictional";
import Fictional from "./Pages/Fictional";
import BookViewer from './Components/NonFictional/BookViewer';
import NewBookViewer from './Components/NonFictional/newBookViewer'
import MyBook from './Pages/MyBook'
import Credit from './Pages/Credit'
// import Poem from './Pages/Poem';
import Account from './Pages/Account';
// import Guide from './Pages/Guide';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import TermsOfService from './Pages/TermOfServices';
import Book from './Components/Fiction/Book'
// import Comic from './Pages/Comic'
import Add from './Pages/Add'


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate replace to="/home" />} />
        <Route path="/Home" element={<Home />} />
        <Route path="/support" element={<Support />} />
        <Route path="/create" element={<Create />} />
        <Route path="/NonFictional" element={<NonFictional />} />
        <Route path="/Fictional" element={<Fictional />} />
        <Route path="/MyBook" element={<MyBook />} />
        <Route path="/Credit" element={<Credit />} />
        <Route path="/Account" element={<Account />} />
        {/* <Route path="/Guide" element={<Guide />} /> */}
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/TermsOfService" element={<TermsOfService />} />
        {/* <Route path="/Comic" element={<Comic/>} /> */}
        <Route path="/:twelveDigitValue" element={<BookViewer />} />
        <Route path="/Book/:twelveDigitValue" element={<Book />} />
        <Route path="/:twelveDigitValue/:chapterIndex?/:sectionIndex?" element={<NewBookViewer />} />
        <Route path="/Add/:email/:price/:orderNumber" element={<Add/>} />

      </Routes>
    </Router>
  );
}

export default App;
