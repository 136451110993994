import React from 'react';

import MenuHead from '../Components/Fiction/LandingMenuSign';
import { SignedOut, SignIn, SignedIn, UserProfile } from "@clerk/clerk-react";
import Footer from '../Components/Footer';

const Account = () => {
  return (
    <>
        <div className="h-screen">
          <SignedOut>
          <div className="flex items-center justify-center h-screen">
            <SignIn redirectUrl="/account"/>
          </div>
        </SignedOut>
    <SignedIn>
    <div>
      <MenuHead/>
      <div className="flex justify-center">
        <UserProfile/>
</div>

      <Footer/>

    </div>
    </SignedIn>
    </div>
    </>
  );
}

export default Account;
