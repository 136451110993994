import React from 'react';
import { TbInfoTriangle } from "react-icons/tb";
import { CiImageOn } from "react-icons/ci";

const Alert = () => {

  return (
    <div>
      <div className="fixed w-[265px] text-black text-sm font-semibold top-4 right-4 border border-gray-300 bg-white rounded-xl p-2 backdrop-filter backdrop-blur-lg shadow-md bg-opacity-50">
      <TbInfoTriangle className='inline mr-1 mb-[0.5px]'/>
      <span className='text-black'>Do no close this window</span>
      </div>
      <div className="fixed w-[265px] text-black text-sm font-semibold top-16 right-4 border border-gray-300 bg-white rounded-xl p-2 backdrop-filter backdrop-blur-lg shadow-md bg-opacity-50">
      <CiImageOn className='inline mr-1 mb-[0.7px]'/>
      <span className='text-black'>Generating Image</span>
      </div>
      <div className="fixed w-[265px] text-black text-sm font-semibold top-28 right-4 border border-gray-300 bg-white rounded-xl p-2 backdrop-filter backdrop-blur-lg shadow-md bg-opacity-50">
      <span className='text-black'>⛁ 2 Credit Charged</span>
      </div>
    </div>
  );
};

export default Alert;
