import React from 'react';
import { FaCaretDown } from "react-icons/fa";
import styles from './FaqHome.module.css';

const FaqHome = () => {
  return (
    <div className={styles['faq-section-body']}>
    <div className={styles['faq-section']}>
      <header>
        <h2>FAQs</h2>
        <p>Answers to the most frequently asked questions.</p>
      </header>

      <details>
        <summary>
          <h4>What is Swift AI Writter?</h4>
          <span className="material-symbols-outlined">
            <FaCaretDown/>
          </span>
        </summary>
        <p>
        Swift AI Writer is an AI tool designed to enable users to effortlessly create fiction, non-fiction, storybooks, and quotes using a single prompt. You can also easily edit and download your generated content. We utilize open-source Large Language Models (LLM) to power our platform.
        </p>
      </details>

      <hr />

      <details>
        <summary>
          <h4>How does the AI create NonFiction books from a single prompt?</h4>
          <span className="material-symbols-outlined"> <FaCaretDown/></span>
        </summary>
        <p>
        Swift AI Writer accepts a user prompt and, through a series of actions, transforms it into a comprehensive book outline. This transformation involves expanding the prompt, making it more focused and detailed, aligned with the user's input. The tool then utilizes open-source Large Language Models (LLMs) to generate content for each chapter of the book. Once the book is generated, you will be directed to a dedicated link where you can easily edit or rewrite any specific chapter. After ensuring everything is correct, you can proceed to download the completed book.
        </p>
      </details>

      <hr />

      <details>
        <summary>
          <h4>Can I try the tool for free before subscribing?</h4>
          <span className="material-symbols-outlined"><FaCaretDown/></span>
        </summary>
        <p>
        Certainly, upon your initial sign-up, you will receive 300 credit points, sufficient for the creation of 3 NonFictional books or 1 Fictional Book.
        </p>
      </details>

      <hr />

      <details>
        <summary>
          <h4>How much it costs to create 1 Book</h4>
          <span className="material-symbols-outlined"><FaCaretDown/></span>
        </summary>
        <p>
        Creating a single non-fiction book requires 100 credit points.
        </p>
      </details>

      <hr />

      <details>
        <summary>
          <h4>What file formats can I export the generated content to?</h4>
          <span className="material-symbols-outlined"><FaCaretDown/></span>
        </summary>
        <p>
        You will have the option to download your book in .pdf format.
        </p>
      </details>

      <hr />

<details>
  <summary>
    <h4>How many pages does a Non Fiction book contain?</h4>
    <span className="material-symbols-outlined"><FaCaretDown/></span>
  </summary>
  <p>
  A Non Fiction book typically ranges from 80 to over 100 pages, with the average being around 85 pages.
  </p>
</details>

<hr />

<details>
  <summary>
    <h4>How much does it cost to create a Non Fiction book?</h4>
    <span className="material-symbols-outlined"><FaCaretDown/></span>
  </summary>
  <p>
  The creation of a non-fiction book requires 100 Swift AI Credits, and there is a charge of 1 credit per page for rewriting. However, downloading and editing are currently available at no extra cost
  </p>
</details>

      <hr />

      <details>
        <summary>
          <h4>What is the pricing structure for using this SaaS AI tool?</h4>
          <span className="material-symbols-outlined"><FaCaretDown/></span>
        </summary>
        <p>Embrace a pay-as-you-go model by adding credits to your account, which can then be utilized for Swift AI Writer services such as the Non-Fiction Book creator and more.</p>
      </details>
    </div>
    </div>
  );
};

export default FaqHome;
