// NewSidebar.js
import { useParams, useNavigate } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import React, { useState, useEffect } from 'react';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { useUser } from "@clerk/clerk-react";
import axios from 'axios';
import './Sidebar.css';
import PageStats from './PageStats'
import PdfGenerator from './Pdf';
import LinkIcon from '@mui/icons-material/Link';
import TwitterIcon from '@mui/icons-material/Twitter';
import { useMediaQuery } from 'react-responsive'; 
import Pop from './Fetch'
import { MdEdit } from "react-icons/md";
import { CiImageOn } from "react-icons/ci";
import { MdOutlineTipsAndUpdates } from "react-icons/md";
import { IoCloudDownloadOutline } from "react-icons/io5";
import FetchAlert from './FetchAlert';


const Sidebar = ({ initialData, initialChapterIndex, initialSectionIndex, Fiction,SidebarTitle,SidebarGenre,userAllowed}) => {
  const [isErrorModalOpen, setIsErrorModalOpen] = useState();
  const [shown, setShown] = useState(true)
  const { twelveDigitValue } = useParams();
  const [data, setData] = useState(initialData);
  const [selectedSection, setSelectedSection] = useState(null);
  const [selectedChapterIndex, setSelectedChapterIndex] = useState(null);
  const [selectedSectionIndex, setSelectedSectionIndex] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [editedParagraph, setEditedParagraph] = useState('');
  const [showShareOptions, setShowShareOptions] = useState(false);
  const [creditLogged, setCreditLogged] = useState(true);
  const [fiction, setFiction] = useState(false)

  const [allowed, setAllowed] = useState(false)
  const [genStat, setGenStat] = useState(false);

  const [showSidebar, setShowSidebar] = useState(true); // State to toggle between sidebar and content
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' }); // Checking for mobile screen


  const navigate = useNavigate();  // Add this line to get the history object
  const { user, isLoaded } = useUser();



  const currentURL = `https://swiftaiwriter.com/${twelveDigitValue}/0/0`

  const handleBackButtonClick = () => {
    setShowSidebar(true); // Show sidebar and hide content for mobile users
  };

  const handleShareClick = () => {
    setShowShareOptions(!showShareOptions);
  };

  const convertTimestamp = (timestamp) => {
    return timestamp.replace(/[\/,: ]/g, '');
  }

  const fetchTimestampAndRender = async () => {
    const currentTimestamp = new Date().toLocaleString();
    const convertedTimestamp = convertTimestamp(currentTimestamp);
    return convertedTimestamp;
  };


  const generateTweetURL = () => {
    const tweetText = `Hey, I just created a book on SwiftAIWriter.com—it's amazing! Check it out here: ${currentURL}`;
    const encodedTweetText = encodeURIComponent(tweetText);
    return `https://twitter.com/intent/tweet?text=${encodedTweetText}`;
  };

  const openTwitter = () => {
    const tweetURL = generateTweetURL();
    window.open(tweetURL, '_blank');
  };

  const handleReduceCredits = async (userId) => {
    try {
      const response = await axios.post('https://backend-production-bcc4.up.railway.app/reduce-credits', {
        user_id: userId, // replace with the actual user ID
        credits_to_reduce: 1 // replace with the desired amount to reduce
      });
      console.log(response.data);
      // console.log(userId)
    } catch (error) {
      console.error('Error reducing credits:', error);
    }
  };

  const logReduceCredits = async (userId, timeStamp) => {
    try {
      const response = await axios.post('https://backend-production-bcc4.up.railway.app/save-reduced-credits', {
        user_id: userId, // replace with the actual user ID
        credits_to_reduce: 1, // replace with the desired amount to reduce
        used_for: "Fetching Content",
        book_request_id: timeStamp
      });
      console.log(response.data);
      // console.log(userId)
    } catch (error) {
      console.error('Error reducing credits:', error);
    }
  };

  const handleImageClick = () => {
    navigate(`/Book/${twelveDigitValue}`);
  };


  const fetchChapterCompletion = async (BookChapterTitle, TopicName) => {
   setGenStat(true)
    try {
        const response = await axios.post('https://backend-production-07.up.railway.app/api/fetchChapterCompletion', { BookChapterTitle, TopicName });
        const result = response.data;
        // console.log(result);
        setGenStat(false)
        return result;
    } catch (error) {
        console.error(error);
        throw error; // or handle error appropriately
    }
  };

  const fetchFictionChapter = async (BookChapterTitle, BookTitle, BookGenre, Topic) => {
    setGenStat(true)
    try {
        const response = await axios.post('https://backend-production-07.up.railway.app/api/fetchFictionChapter', { BookChapterTitle, BookTitle, BookGenre, Topic});
        const result = response.data;
        // console.log(result);
        setGenStat(false)
        return result;
    } catch (error) {
        console.error(error);
        throw error; // or handle error appropriately
    }
  };

  const handleSectionClick = (chapterIndex, sectionIndex) => {
    const selectedChapter = data[chapterIndex];
    const selectedSec = selectedChapter.sections[sectionIndex];

    setSelectedChapterIndex(chapterIndex); // Store the selected chapter index
    setSelectedSectionIndex(sectionIndex); // Store the selected section index

    setSelectedSection({
      chapterTitle: selectedChapter.title,
      sectionTitle: selectedSec.title,
      paragraph: selectedSec.paragraph,
    });

    if (selectedChapter) {
      navigate(`/${twelveDigitValue}/${chapterIndex}/${sectionIndex}`);
    };
    
    if (isMobile) {
      setShowSidebar(false); // Hide sidebar and show content for mobile users
    }

    // Set the edited paragraph to the original paragraph when switching sections
    setEditedParagraph(selectedSec.paragraph);
    setEditMode(false);
  };

  const handleFetchAndUpdateClick = async () => {
    if(shown){
    setIsErrorModalOpen(true)
    setShown(false)
    } else {
    try {
      const timestamp = await fetchTimestampAndRender()
      const userId = user.id
      if (creditLogged){
        // Execute handleReduceCredits and logReduceCredits together
        Promise.all([
        handleReduceCredits(userId),
        logReduceCredits(userId,timestamp)
        ]);
        setCreditLogged(false)
        }
      const result = await fetchChapterCompletion(
        selectedSection.chapterTitle,
        selectedSection.sectionTitle
      );
      // console.log(result);
      // Update the paragraph with the fetched completion result
      setEditedParagraph(result.choices[0].message.content);
      // console.log(editedParagraph);

      // Enter edit mode to allow the user to review and modify the fetched content
      setEditMode(true);

    } catch (error) {
      console.error('Error fetching chapter completion:', error);
    }
  }
  };

  const handleFictionFetchAndUpdateClick = async () => {
    if(shown){
    setIsErrorModalOpen(true)
    setShown(false)
    } else {
    try {
      const timestamp = await fetchTimestampAndRender()
      const userId = user.id
      if (creditLogged){
        // Execute handleReduceCredits and logReduceCredits together
        Promise.all([
        handleReduceCredits(userId),
        logReduceCredits(userId,timestamp)
        ]);
        setCreditLogged(false)
        }
      const result = await fetchFictionChapter(
        selectedSection.chapterTitle,
        SidebarTitle,
        SidebarGenre,
        selectedSection.sectionTitle
      );
      // console.log(result);
      // Update the paragraph with the fetched completion result
      setEditedParagraph(result.choices[0].message.content);
      // console.log(editedParagraph);

      // Enter edit mode to allow the user to review and modify the fetched content
      setEditMode(true);

    } catch (error) {
      console.error('Error fetching chapter completion:', error);
    }
  }
  };

  const handleEditClick = () => {
    setEditedParagraph(selectedSection.paragraph);
    setEditMode(true);
  };



  const handleFictionSaveClick = async () => {
    // Send a request to the server to update the JSON file
    // console.log(editedParagraph)
    // console.log("selectedChapterIndex",selectedChapterIndex)
    // console.log("selectedSectionIndex",selectedSectionIndex)
    try {
      await axios.post(`https://backend-production-bcc4.up.railway.app/update-fetch-fiction-json/${twelveDigitValue}`, {
        modifiedParagraph: editedParagraph,
        chapterIndex: selectedChapterIndex, // Pass the selected chapter index
        sectionIndex: selectedSectionIndex, // Pass the selected section index
      });

      // Exit edit mode after saving
      setEditMode(false);

          // Reload the entire page
    window.location.reload();

      console.log('Saved')
    } catch (error) {
      console.error('Error updating JSON file:', error);
    }
  };

  const handleSaveClick = async () => {
    // Send a request to the server to update the JSON file
    // console.log(editedParagraph)
    // console.log("selectedChapterIndex",selectedChapterIndex)
    // console.log("selectedSectionIndex",selectedSectionIndex)
    try {
      await axios.post(`https://backend-production-bcc4.up.railway.app/update-json/${twelveDigitValue}`, {
        modifiedParagraph: editedParagraph,
        chapterIndex: selectedChapterIndex, // Pass the selected chapter index
        sectionIndex: selectedSectionIndex, // Pass the selected section index
      });

      // Exit edit mode after saving
      setEditMode(false);

          // Reload the entire page
    window.location.reload();

      console.log('Saved')
    } catch (error) {
      console.error('Error updating JSON file:', error);
    }
  };

  const handleCancelClick = () => {
    // Discard changes and exit edit mode
    setEditMode(false);
  };

  const checkForAccess = async (userId) => {
    // console.log(userId)
    if (userId === userAllowed) {
      setAllowed(true)
    } else {
      setAllowed(false)
    }
  }



  useEffect(() => {
    
    if (isLoaded===true) {
      const userId = user.id
       checkForAccess(userId)
       const Email = user.emailAddresses[0].emailAddress
      //  console.log("EMAIM", Email)
    }

    if(Fiction){
      setFiction(true)
    }
    const handleSectionClick = (initialChapterIndex, initialSectionIndex) => {

      if (data && data.length > 0) {
        const selectedChapter = data[initialChapterIndex];
        if (selectedChapter && selectedChapter.sections) {
          const selectedSec = selectedChapter.sections[initialSectionIndex];

          setSelectedChapterIndex(initialChapterIndex);
          setSelectedSectionIndex(initialSectionIndex);
  
          setSelectedSection({
            chapterTitle: selectedChapter.title,
            sectionTitle: selectedSec.title,
            paragraph: selectedSec.paragraph,
          });
        }
      }
    };
  
    handleSectionClick(initialChapterIndex, initialSectionIndex);
  }, [data, initialChapterIndex, initialSectionIndex]);
  
  return (
    <div className="sidebar-container">
{genStat && <FetchAlert />}
      {/* Sidebar */}
      {showSidebar || !isMobile ? (
        <div className="sidebar lg:w-1/4 md:w-1/3 sm:w-full">
          <ul className="sidebar-list">
            {data.map((chapter, chapterIndex) => (
              <li key={chapterIndex} className="chapter">
                <p className="chapterTitle">{chapter.title.replace(/^Chapter (\d+): (.*)$/, '|$1| $2')}</p>
                <ul className="section-list">
                  {chapter.sections.map((section, sectionIndex) => (
                    <li
                      key={sectionIndex}
                      className={`section ${selectedSection && selectedSection.sectionTitle === section.title ? 'selected' : ''}`}
                      onClick={() => handleSectionClick(chapterIndex, sectionIndex)}
                    >
                      {section.title}
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
        </div>
      ) : null}

      {/* Content Display */}
      {selectedSection && (!showSidebar || !isMobile) && (
        <div className={`content-display ${editMode ? 'no-padding' : ''} lg:w-3/4 md:w-2/3 sm:w-full`}>
                    {/* Mobile Back Button inside content display */}
                    {isMobile && (
            <button className="back-button fixed left-8 top-30 bg-opacity-30 backdrop-filter backdrop-blur-md rounded-lg bg-indigo-400 " onClick={handleBackButtonClick}>Back</button>
          )}
          {allowed && (
          <div className={`button-container ${editMode ? 'edit-mode' : ''} flex flex-wrap justify-center`}>
          {editMode ? (
  <>
  
    {fiction ? (
      <>
      <button onClick={handleFictionSaveClick}>Save</button>
      </>
    ) : (
      <button onClick={handleSaveClick}>Save</button>
    )}
      <button className="cancel" onClick={handleCancelClick}>Cancel</button>
  </>
) : (
  <>
     {isMobile ? (<div>
        <MdEdit onClick={handleEditClick} className='h-7 w-7 text-blue-300'/>
        </div>
      ) : (
        <button onClick={handleEditClick}>Edit</button>
      )}
    {fiction ? (
      <>
      {isMobile ? (<div>
        <MdOutlineTipsAndUpdates onClick={handleFictionFetchAndUpdateClick} className='h-7 w-7 text-blue-400' />
        </div>
      ) : (
        <button onClick={handleFictionFetchAndUpdateClick}>Fetch and Update</button>
      )}
        {isMobile ? (<div>
        <CiImageOn onClick={handleImageClick} className='h-7 w-7 text-blue-500'/>
        </div>
      ) : (
        <button onClick={handleImageClick}>Image</button>
      )}
      </>
    ) : (<>
      {isMobile ? (<div>
        <MdOutlineTipsAndUpdates onClick={handleFetchAndUpdateClick} className='h-7 w-7 text-blue-500'/>
        </div>
      ) : (
        <button onClick={handleFetchAndUpdateClick}>Fetch and Update</button>
      )}
      </>
    )}
    <PDFDownloadLink document={<PdfGenerator data={initialData}/>} fileName="Ebook.pdf">
      {isMobile ? (<div>
        <IoCloudDownloadOutline className='h-7 w-7 text-blue-400'/>
        </div>
      ) : (
      <button>Download</button>
      )}
    </PDFDownloadLink>
    <div className="flex items-center bg-purple-200 rounded-lg px-4">
      <button
        onClick={handleShareClick}
        className={`${
          showShareOptions ? 'bg-purple-200 text-white' : 'bg-purple-200 text-white'
        } py-2.5 px-5 rounded`}
      >
        Share
      </button>
      {showShareOptions && (
        <div className="flex gap-2 items-center ml-2">
          <CopyToClipboard text={currentURL}>
            <button className="bg-purple-200 text-white py-2 px-4 rounded-lg">
              <LinkIcon className="w-6 h-6 text-white-500" />
            </button>
          </CopyToClipboard>
          <button onClick={openTwitter} className="bg-purple-200 text-white py-2 px-4 rounded">
            <TwitterIcon className="w-6 h-6 text-white-500" />
          </button>
        </div>
      )}
    </div>
  </>
)}
          </div>
          )}
          {editMode ? (
            <div className='edit-container'>
              {isMobile ? (
        <textarea 
        className="textarea w-full text-sm" // Ensure textarea is full width on mobile
        value={editedParagraph}
        onChange={(e) => setEditedParagraph(e.target.value)}
      />
      ) : (
        <textarea 
                className="textarea w-full" // Ensure textarea is full width on mobile
                value={editedParagraph}
                onChange={(e) => setEditedParagraph(e.target.value)}
              />
      )}
            </div>
          ) : (
            <div>
              <p>{selectedSection.paragraph}</p>
              <PageStats selectedSection={selectedSection} />
            </div>
          )}
        </div>
      )}
      <Pop isOpen={isErrorModalOpen} setIsOpen={setIsErrorModalOpen} />
    </div>
  );
}

export default Sidebar;