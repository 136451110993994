import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import ProgressBar from "./ProgressBar";
import styled from 'styled-components';
import Alert from "./Alert";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const ProgressBarContainer = styled.div`
  
  // Media query for devices with width less than 768px
  @media (max-width: 768px) {
    transform: rotate(90deg);
    transform-origin: center center;
    width: 100vh;
    height: 100vw;
    overflow-x: hidden;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(90deg);
  }
`;

const DigitalBook = ({
  data,
  bookName,
  bookGenre,
  bookImageQuality,
  bookImage,
  userId,
  digitValue,
  bookPages,
  LastImage
}) => {
  const [currentPage, setCurrentPage] = useState(0);
  const charsPerPage = 1000;
  const [pageStartIndices, setPageStartIndices] = useState([]);
  const [chapterTitles, setChapterTitles] = useState([]);
  const [image, setImageBase64] = useState();
  const [check, setCheck] = useState(true);
  const [currentPageInSection, setCurrentPageInSection] = useState(1);
  const [jumpToPage, setJumpToPage] = useState('');
  const [generation, setGeneration] = useState();
  const [showBar, setShowBar] = useState(false);
  const [currentValue, setCurrentValue] = useState(0)
  const [maxValue, setMaxValue] = useState(bookPages)
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(false);





  
  useEffect(() => {
    setCurrentPageInSection(1); // Reset to 1 when the component mounts or data changes
    console.log(maxValue)
  }, [currentValue]);

  // useEffect(() => {
  //   setCurrentPageInSection(1); // Reset to 1 when the component mounts or data changes
  //   console.log(maxValue)
  // }, [data]);

  const [isDarkMode, setIsDarkMode] = useState(true);
  const [isSide, setSide] = useState(false);


  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  const toggleSide = () => {
    setSide(!isSide);
  };



  const getSectionIndex = (page) => {
    // Logic to determine the section index based on the page number
    return chapterTitles[page]?.sectionNumber - 1;
  };

  const updateCurrentPageInSection = (newCurrentPage) => {
    const newSectionIndex = getSectionIndex(newCurrentPage);
    const currentSectionIndex = getSectionIndex(currentPage);
  
    if (newSectionIndex !== currentSectionIndex) {
      // Reset to 1 if we're moving to a different section
      setCurrentPageInSection(1);
    } else {
      // Adjust the page within the section
      if (newCurrentPage > currentPage) {
        // Moving to the next page
        setCurrentPageInSection(currentPageInSection + 1);
      } else {
        // Moving to the previous page
        setCurrentPageInSection(currentPageInSection > 1 ? currentPageInSection - 1 : 1);
      }
    }
  };
  
  



//   const removeSpacesAndAlphabets = (str) => {
//     const match = str.match(/\b\d{1,2}\b/);
//     return match ? parseInt(match[0], 10) : null;
// }

  // const reactIndex = () => {
  //   const chapNo  = (getChapterAndSection().chapterTitle)
  //   const secNo = (getChapterAndSection().sectionNumber)
  //   const ChaptrNo = removeSpacesAndAlphabets(chapNo)
  //   setFetchedImageReq(true)
  //   setSectionIndex(secNo-1)
  //   setChapterIndex(ChaptrNo-1)
  //   setSectionForImage(secNo)
  //   setChapterForImage(ChaptrNo)x

  //   console.log("IMAGECODE","CHAPTER",ChaptrNo,"SECTION",secNo,"PAGE",currentPageInSection)
  // }

  useEffect(() => {
    fetchSavedImage(currentPage, digitValue);
  }, [currentPage]);

  

  useEffect(() => {
    // if(check){
    //     reactIndex()
    //     setCheck(false)       
    // }

    let tempCumulativeText = "";
    let tempPageStartIndices = [0];
    let tempChapterTitles = [];
    console.log(currentPageInSection)
    data.forEach((chapter, chapterIndex) => {
      chapter.sections.forEach((section, sectionIndex) => {
        const sectionText = section.paragraph + " ";
        tempCumulativeText += sectionText;
        let currentCharCount = tempCumulativeText.length;

        while (
          tempPageStartIndices[tempPageStartIndices.length - 1] <
          currentCharCount
        ) {
          let endIndex = Math.min(
            tempPageStartIndices[tempPageStartIndices.length - 1] +
              charsPerPage,
            currentCharCount
          );
          if (endIndex < currentCharCount) {
            while (
              endIndex < currentCharCount &&
              tempCumulativeText[endIndex] !== " "
            ) {
              endIndex++;
            }
          }
          tempPageStartIndices.push(endIndex);
          tempChapterTitles.push({
            chapterTitle: chapter.title,
            sectionNumber: sectionIndex + 1,
          });
        }
      });
    });

    setPageStartIndices(tempPageStartIndices);
    setChapterTitles(tempChapterTitles);
  }, [data,check]);

  const totalPages = Math.ceil(pageStartIndices.length);

  const nextPage = () => {
    if (currentPage < totalPages - 1) {
      setIsNextButtonDisabled(true);
      const newCurrentPage = currentPage + 1;
      setCurrentPage(newCurrentPage);
      updateCurrentPageInSection(newCurrentPage);
      setCheck(true);
      
      // Re-enable the button after 600 milliseconds
      setTimeout(() => {
        setIsNextButtonDisabled(false);
      }, 600);
    }
  };
  
  const previousPage = () => {
    if (currentPage > 0) {
      setIsButtonDisabled(true);
      const newCurrentPage = currentPage - 1;
      setCurrentPage(newCurrentPage);
      updateCurrentPageInSection(newCurrentPage);
      setCheck(true);

      // Re-enable the button after 600 milliseconds
      setTimeout(() => {
        setIsButtonDisabled(false);
      }, 600);
    }
  };

  const convertTimestamp = (timestamp) => {
    return timestamp.replace(/[\/,: ]/g, '');
  }

  const fetchTimestampAndRender = () => {
    const currentTimestamp = new Date().toLocaleString();
    const convertedTimestamp = convertTimestamp(currentTimestamp);
    return convertedTimestamp
  };
  
  
  const handleReduceCredits = async (userId) => {
    try {
      const response = await axios.post('https://backend-production-bcc4.up.railway.app/reduce-credits', {
        user_id: userId, // replace with the actual user ID
        credits_to_reduce: 1 // replace with the desired amount to reduce
      });
      console.log(response.data);
      // console.log(userId)
    } catch (error) {
      console.error('Error reducing credits:', error);
    }
  };

  const logReduceCredits = async (userId,timeStamp) => {
    try {
      const response = await axios.post('https://backend-production-bcc4.up.railway.app/save-reduced-credits', {
        user_id: userId, // replace with the actual user ID
        credits_to_reduce: 2, // replace with the desired amount to reduce
        used_for: "Regenrate Fiction Image",
        book_request_id: timeStamp
      });
      console.log(response.data);
      // console.log(userId)
    } catch (error) {
      console.error('Error reducing credits:', error);
    }
  };


  const getTextSlice = () => {
    const start = pageStartIndices[currentPage];
    const end =
      currentPage + 1 < pageStartIndices.length
        ? pageStartIndices[currentPage + 1]
        : start + charsPerPage;
    return data
      .flatMap((chapter) =>
        chapter.sections.flatMap((section) => section.paragraph + " ")
      )
      .join("")
      .slice(start, end);
  };

  const getChapterAndSection = () => {
    return chapterTitles[currentPage] || { chapterTitle: "", sectionNumber: 0 };
  };

  //////////////

  const fetchScene = useCallback(async (Chapter) => {
    try {
      const response = await axios.post(
        `https://backend-production-07.up.railway.app/api/fetchScene`,
        { Chapter }
      );
      const result = response.data;
      console.log(result);
      return result;
    } catch (error) {
      console.error(error);
    }
  }, []);

  const fetchMissingScene = useCallback(async (Chapter) => {
    try {
      const response = await axios.post(
        `https://backend-production-07.up.railway.app/api/fetchMissingScene`,
        { Chapter }
      );
      const result = response.data;
      console.log(result);
      return result;
    } catch (error) {
      console.error(error);
    }
  }, []);

  const fetchRetryScene = useCallback(async (Chapter) => {
    try {
      const response = await axios.post(
        `https://backend-production-07.up.railway.app/api/fetchRetryScene`,
        { Chapter }
      );
      const result = response.data;
      // console.log(result);
      return result;
    } catch (error) {
      console.error(error);
    }
  }, []);

  const fetchSaveScene = useCallback(async (sceneData) => {
    try {
      const twentyDigitValue = digitValue;
      const response = await axios.post(
        `https://backend-production-bcc4.up.railway.app/save-scene/${twentyDigitValue}`,
        { sceneData }
      );
      const result = response.data;
      // console.log(result);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const fetchImages = async (prompt) => {
    try {
      const response = await axios.post(
        "https://backend-production-07.up.railway.app/api/fetchImage",
        { prompt }
      );
      // console.log(response);
      if (response.data.output && response.data.output.choices) {
        const base64Images = response.data.output.choices.map(
          (choice) => choice.image_base64
        );
        const imageUrl = `data:image/jpeg;base64,${base64Images}`;
        return imageUrl;
      }
    } catch (err) {
      console.error("Error fetching images:", err);
      console.log("Failed to fetch images");
    }
  };

  const fetchRetryImage = async (prompt) => {
    try {
      const response = await axios.post(
        "https://backend-production-07.up.railway.app/api/fetchRetryImage",
        { prompt }
      );
      // console.log(response);
      if (response.data.output && response.data.output.choices) {
        const base64Images = response.data.output.choices.map(
          (choice) => choice.image_base64
        );
        const imageUrl = `data:image/jpeg;base64,${base64Images}`;
        return imageUrl;
      }
    } catch (err) {
      console.error("Error fetching images:", err);
      console.log("Failed to fetch images");
    }
  };

  const getChapterValue = (number) => {
    if (number >= 0 && number <= 199) {
      return Math.floor(number / 5) + 1;
    }
    return null; // or any default value for numbers outside 0-49
  };

  const getSectionValue = (number) => {
    const remainder = number % 5;
    switch (remainder) {
      case 0:
        return 1;
      case 1:
        return 2;
      case 2:
        return 3;
      case 3:
        return 4;
        case 4:
        return 5;
      default:
        return null; // for numbers not matching any of the specified patterns
    }
  };
 
  const fetchSavedScene = async (startX, startY) => {
    console.log("START X and Y", startX, startY)
    try {
      if (startY === 5) {
        startX++;
        startY = 0;
      }
  
      const twentyDigitValue = digitValue;
      const response = await axios.get(
        `http://localhost:5001/fetch-book-data/${twentyDigitValue}`
      );
      const result = response.data;
      let scenes = JSON.parse(result[0].scene);
      console.log("SCENES", scenes);
      console.log(scenes.length);
  
      for (let y = startY; y < scenes.length; y++) {
        console.log("YCOUNT", y);
        let rawData = scenes[y].scene;
        let pars = rawData;
  
        for (let x = Math.max(startX, 0); x < 5; x++) { // Start from startX or 0 if startX is negative
          console.log("ParsX1", x, pars[x]);
          try {
            const fetchedImage = await fetchImages(pars[x]);
            // Success: handle the fetched image
            const chapter = getChapterValue(y);
            const section = getSectionValue(y);
            const fileName = x + 1;
            const book_id = twentyDigitValue;
            const base64 = fetchedImage;
            const image = fileName;
            const newLastScene = y
            setCurrentValue(currentValue => currentValue + 1);
            await saveImage(base64, chapter, section, image, book_id, newLastScene);
          } catch (error) {
            // Failure: record the failed fetch for retry
            console.error("Fetch failed, will retry later", error);
            // Retry logic here
            try {
              console.log("Retrying fetch for", pars[x]);
              const fetchedImage = await fetchRetryImage(pars[x]);
              const chapter = getChapterValue(y);
              const section = getSectionValue(y);
              const fileName = x + 1;
              const book_id = twentyDigitValue;
              const base64 = fetchedImage;
              const image = fileName;
              const newLastScene = y
              setCurrentValue(currentValue => currentValue + 1);
              await saveImage(base64, chapter, section, image, book_id, newLastScene);
            } catch (retryError) {
              console.error("Error in fetchRetryImage", retryError);
              // Handle the case where retry also fails
            }
          }
        }
        // Reset startX to 0 after the first iteration, to start from beginning in subsequent scenes
        startX = 0;
      }
    } catch (error) {
      console.error("Error fetching scene:", error);
    }
  };
  
  

  const saveImage = async (base64, chapter, section, image, book_id,newLastScene) => {
    try {

        const response = await axios.post('https://backend-production-bcc4.up.railway.app/upload-image', {
            base64: base64,
            chapter: chapter,
            section: section,
            image: image,
            book_id: book_id,
            newLastScene: newLastScene,
            newLastImage: image,
        });

      console.log("Data submitted:", response.data);
      console.log(response);
    } catch (error) {
      console.error("Error sending image to server:", error);
    }
  };

  const fetchImageCount = async () => {
    try {
        const twentyDigitValue = digitValue;
        const response = await axios.get(`https://backend-production-bcc4.up.railway.app/count-book/${twentyDigitValue}`)
      console.log(response);
      console.log(response.data.total);
        return response.data.total
    } catch (error) {
      console.error("Error sending image to server:", error);
    }
  };

  const fetchSavedImage = async (fileName, digitValue) => {
    console.log(fileName, digitValue);
    try {
      const response = await axios.get(`https://backend-production-bcc4.up.railway.app/fetch-image`, {
        params: {
          image: fileName,
          BookId: digitValue,
        },
      });


      setImageBase64(response.data.base64);
      // setImageFound(false)
      // setFetchedImageReq(false)
      console.log(response.data.base64);
      console.log("Fetched data:", response.data);
    } catch (error) {
      console.error("Error fetching image:", error);
      // setImageFound(true)
  }
  };

  const parseScenesNumbered = (data) => {
    console.log(data);
    const scenes = data.split(/\d\.\s/);
    return scenes.slice(1);
  };

  const parseScenesTitled = (data) => {
    return data.trim().split("\n\n");
  };

  const handleParse = (data) => {
    console.log(data);
  
    // Indices you want to remove, default setting
    let indicesToRemove = [0, 2, 4, 6, 8, 10];
  
    let scenes = parseScenesTitled(data);
  
    if (scenes[2] === undefined) {
      console.log("Use another parsing method");
      scenes = parseScenesNumbered(data);
      console.log("Raw Updated");
    }
  
    // Check if any scene contains "Keywords"
    // and adjust indicesToRemove if necessary
    const containsKeywords = scenes.some(scene => scene.includes("Keywords"));
    if (containsKeywords) {
      indicesToRemove = [1, 3, 5, 7, 9, 11];
    }
    const containsCaption = scenes.some(scene => scene.includes("Caption"));
    if (containsKeywords) {
      indicesToRemove = [1, 3, 5, 7, 9, 11];
    }
  
    if (scenes[11] === undefined) {
      scenes = scenes;
    } else {
      scenes = scenes.filter((_, index) => !indicesToRemove.includes(index));
    }
  
    return scenes;
  };
  

  const fetchBookPresence = async () => {
    const twentyDigitValue = digitValue
    try {
        const response = await axios.get(`https://backend-production-bcc4.up.railway.app/check-book/${twentyDigitValue}`);
        return (response.data.found);
    } catch (err) {
        console.log('Failed to fetch data. Please try again.');
        console.error(err);
    }
};

const fetchBookData = async () => {
    const twentyDigitValue = digitValue
    try {
        const response = await axios.get(`https://backend-production-bcc4.up.railway.app/fetch-book-data/${twentyDigitValue}`);
        console.log(response)
        return response.data;
    } catch (error) {
        console.error('Error fetching book data:', error);
        // Handle the error appropriately in your UI
        throw error;
    }
};

const saveNewImage = async (chapter, section, image, book_id, base64) => {
    const inputData = {
      chapter,
      section,
      image,
      book_id,
      base64
    };
  
    try {
      const response = await axios.post('https://backend-production-bcc4.up.railway.app/upload-image-only', inputData);
      console.log('Success:', response.data);
      return response.data; // Or just return response to get the full response object.
    } catch (error) {
      console.error('Error occurred:', error);
      throw error; // Rethrow the error if you want the calling function to handle it.
    }
  };

  const uploadImage = async ( image, book_id, imageBase) => {
    const inputData = {
      image,
      book_id,
      base64:imageBase
    };
  
    try {
      const response = await axios.post('https://backend-production-bcc4.up.railway.app/upload-image', inputData);
      console.log('Success:', response.data);
      return response.data; // Or just return response to get the full response object.
    } catch (error) {
      console.error('Error occurred:', error);
      throw error; // Rethrow the error if you want the calling function to handle it.
    }
  };

  const updateImageFetched = async (ImageNo) => {

    try {
        const response = await axios.post('https://backend-production-bcc4.up.railway.app/update-last-image-no', {
            twentyDigitValue: digitValue,
            lastImage: ImageNo
        });
      console.log('Success:', response.data);
      return response.data; // Or just return response to get the full response object.
    } catch (error) {
      console.error('Error occurred:', error);
      throw error; // Rethrow the error if you want the calling function to handle it.
    }
  };

const handleFetchMissingImage = async (x,scene) => {
    const newImageFetched = await fetchMissingScene(scene)
    console.log("FORRRRR",x,scene)
    const newFetchedImage = await fetchImages(newImageFetched.choices[0].message.content);
    console.log(newFetchedImage)
    console.log(currentPage,digitValue)

        await uploadImage(x, digitValue, newFetchedImage)
         // Reload the page to reflect the changes
}

const handleRegenerateImage = async (x,scene) => {
  const newRegenImageFetched = await fetchScene(scene)
  console.log("FORRRRR",x,scene)
  console.log("REGEN REGEN", newRegenImageFetched)
  const newFetchedImage = await fetchImages(newRegenImageFetched.choices[0].message.content);
  console.log(newFetchedImage)
  console.log(currentPage,digitValue)

      await uploadImage(x, digitValue, newFetchedImage)
       // Reload the page to reflect the changes
}

const getSlice = (Page) => {
    const start = pageStartIndices[Page];
    const end =
    Page + 1 < pageStartIndices.length
        ? pageStartIndices[Page + 1]
        : start + charsPerPage;
    return data
      .flatMap((chapter) =>
        chapter.sections.flatMap((section) => section.paragraph + " ")
      )
      .join("")
      .slice(start, end);
  };

  const fetchGenerateImages = async (startX) => {
    console.log("STARTX", startX)
    const failedFetches = []; // Array to keep track of failed fetches

    for(let x = startX; x < totalPages; x++) {
        try {
            setCurrentPage(x);
            const scene = getSlice(x);
            console.log(scene);
            await handleFetchMissingImage(x, scene);
            await updateImageFetched(x);
            setCurrentValue(currentValue => currentValue + 1);
            console.log(x);
        } catch (error) {
            console.error(`Fetch failed for page ${x}:`, error);
            failedFetches.push(x); // Add the failed page number to the array
        }
    }

    // Handle retries for failed fetches
    if (failedFetches.length > 0) {
        console.log(`Retrying failed fetches for pages: ${failedFetches.join(", ")}`);
        for (let i = 0; i < failedFetches.length; i++) {
            const x = failedFetches[i];
            try {
                setCurrentPage(x);
                const scene = getSlice(x);
                console.log(scene);
                setCurrentValue(currentValue => currentValue + 1);
                await handleFetchMissingImage(x, scene);
                await updateImageFetched(x);
                console.log(x);
            } catch (error) {
                console.error(`Retry failed for page ${x}:`, error);
                // Handle the failed retry, e.g., log it or add it to a different array for further handling
            }
        }
    }
}

const fetchUserCredits = async () => {
  try {
    // Replace 'your-credits-endpoint' with the actual endpoint to fetch user credits
    const response = await axios.get(`https://backend-production-bcc4.up.railway.app/fetch/${userId}`);

    return response.data.total_credits; // Assuming the response contains the user's total credits
  } catch (error) {
    console.error('Error fetching user credits:', error);
    throw error;
  }
};

const handleImageGeneration = async () => {
   const timeStamp = fetchTimestampAndRender()
    setIsButtonClicked(true);
    setIsNextButtonDisabled(true)
    setIsButtonDisabled(true)
    console.log("DATATATA",currentPage,LastImage,maxValue)
    if(LastImage===null){
      setCurrentPage(0)
        setShowBar(true)
        setMaxValue(totalPages-1)
        fetchGenerateImages(0)
        setIsButtonClicked(false);
      setIsNextButtonDisabled(false)
      setIsButtonDisabled(false) 
    } else {
        console.log("LASTIM",LastImage)
    console.log("DATATATA IMP",LastImage,maxValue)
    const lastImage = LastImage+1
    if(lastImage==totalPages){
      console.log("GENERATE NEW IMAGES")
      const scene = getSlice(currentPage);
      const userCredits = await fetchUserCredits();
               if (userCredits >= 2) {
                handleReduceCredits(userId)
                logReduceCredits(userId,timeStamp)
                      console.log(scene);
                      await handleRegenerateImage(currentPage, scene);
                // console.log("CurrentImage",currentPage)
                setIsButtonClicked(false);
                setIsNextButtonDisabled(false)
                setIsButtonDisabled(false)  
                } else {
                  setIsButtonClicked(false);
                setIsNextButtonDisabled(false)
                setIsButtonDisabled(false)  
                }
      setCurrentPage(currentPage)
    } else {
                setShowBar(true)
                setMaxValue(totalPages-1)
        const CurVal = await fetchImageCount()
        console.log(CurVal)
    setCurrentValue(CurVal)
      await fetchGenerateImages(LastImage+1)
      setIsButtonClicked(false);
      setIsNextButtonDisabled(false)
      setIsButtonDisabled(false) 
    }
  }
}

//   const handleMissingImages = async () => {
//     setIsButtonClicked(true);
//     setIsNextButtonDisabled(true)
//     setIsButtonDisabled(true)
//     const present = await fetchBookPresence()
//     if(present){
//         console.log("Book Id is present")
//        const response = await fetchBookData()
//        console.log(response)
//        console.log(response[0].image_status)
//        console.log(response[0].LastScene)
//        const fetchedLastScene = response[0].LastScene
//        console.log(response[0].LastImage)
//        const fetchedLastImage = response[0].LastImage

//        if(fetchedLastScene === bookPages-1){
//         if(fetchedLastImage === 5) {
//            await handleFetchMissingImage()
//           await fetchSavedImage(chapterForImage, sectionForImage, currentPageInSection, digitValue)

//             setIsButtonClicked(false);
//       setIsNextButtonDisabled(false)
//       setIsButtonDisabled(false) 
//         } else {
//             setShowBar(true)
//            const CurVal = await fetchImageCount()
//         setCurrentValue(CurVal)
//         console.log(currentPage)
//         setMaxValue(bookPages*5)
//         console.log(maxValue)
//             console.log("Start from Last Image")
//       await fetchSavedScene(fetchedLastScene,fetchedLastImage)

//       setIsButtonClicked(false);
//       setIsNextButtonDisabled(false)
//       setIsButtonDisabled(false) 
//         }
//        } else {
//         setShowBar(true)
//         const CurVal = await fetchImageCount()
//         console.log(CurVal)
//     setCurrentValue(CurVal)
//     console.log(currentPage)
//     setMaxValue(bookPages*5)
//     console.log(maxValue)
//         console.log("Start from Image")
//       await fetchSavedScene(fetchedLastScene,fetchedLastImage)

//        setIsButtonClicked(false);
//        setIsNextButtonDisabled(false)
//        setIsButtonDisabled(false)
//     }

//     } else {
//         await handleGenerateClick()
//         setGeneration(false)
//         setShowBar(false)
//         setCurrentValue(0)
//         setIsButtonClicked(false);
//         setIsNextButtonDisabled(false)
//         setIsButtonDisabled(false)
//     }

//   }

  const handleGenerateClick = async () => {
    setGeneration(true)
    setShowBar(true)
    const scenes = [];

    const processSection = async (section, chapterIndex, sectionIndex) => {
        let maxAttempts = 3; // Set the maximum number of fetch attempts
        let attempt = 0;
        let parsedData;
      
        while (attempt < maxAttempts) {
          try {
            // Use fetchRetryScene if it's a retry attempt, else use fetchScene
            const fetchFunction = attempt > 0 ? fetchRetryScene : fetchScene;
            const fetched = await fetchFunction(section.paragraph);
            console.log("Attempt:", attempt + 1, "Fetched data:", fetched); // Log to see the structure and attempt number
      
            if (!fetched || !fetched.choices) {
              throw new Error("Invalid or missing data in fetched response");
            }
      
            const parsed = fetched.choices[0].message.content;
            parsedData = handleParse(parsed);
      
            // Check if parsedData (scene) has 6 items
            if (parsedData && parsedData.length === 6) {
                setCurrentValue(currentValue => currentValue + 1);
              return {
                SceneNo: `${chapterIndex}/${sectionIndex}`,
                scene: parsedData,
              };
            } else {
                console.log("PARSEDED",parsed)
                console.log("FEFETHED",fetched)
              console.warn(`Attempt ${attempt + 1}: Scene data does not contain 6 items, found ${parsedData.length}. Retrying with fetchRetryScene...`);
            }
          } catch (error) {
            console.error("Error in processSection:", error);
            // Optionally, handle specific errors and decide whether to retry based on the error type
          }
      
          attempt++;
        }
      
        console.error(`Failed to fetch valid scene data after ${maxAttempts} attempts.`);
        return null; // Or handle as appropriate when all attempts fail
      };
      
      

    // Function to process a batch of sections
    const processBatch = async (batch) => {
      return Promise.all(
        batch.map(({ section, chapterIndex, sectionIndex }) =>
          processSection(section, chapterIndex, sectionIndex)
        )
      );
    };

    // Create batches and process them
    let batch = [];
    for (const [chapterIndex, chapter] of data.entries()) {
      for (const [sectionIndex, section] of chapter.sections.entries()) {
        batch.push({ section, chapterIndex, sectionIndex });

        if (
          batch.length === 30 ||
          (chapterIndex === data.length - 1 &&
            sectionIndex === chapter.sections.length - 1)
        ) {
          const batchScenes = await processBatch(batch);
          scenes.push(...batchScenes);
          batch = []; // Reset batch for next iteration
        }
      }
    }

    // Convert scenes to JSON
    const json = JSON.stringify(scenes);

    console.log("JSOOONM", scenes);

    // Call fetchSave to save the JSON data
    try {
      const response = await fetchSaveScene(scenes);
      console.log("Data saved successfully", response);
    } catch (error) {
      console.error("Error saving data", error);
    }
  };

  const handleJumpToPage = () => {
    const pageNumber = parseInt(jumpToPage, 10);
    if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber - 1); // since pages are 0-indexed
      setJumpToPage(''); // Reset the input field
      setCheck(true);
    } else {
      alert(`Please enter a valid page number between 1 and ${totalPages}`);
    }
  };


  return (
<ProgressBarContainer>
  <div className={`flex flex-col items-center justify-center h-screen transition-colors duration-300 ${isDarkMode ? 'bg-gray-900 text-white' : 'bg-white text-gray-900'}`}>

    <div className="flex shadow-lg rounded-lg overflow-hidden mb-4 justify-end"> {/* Adjust image to the right */}
      <div className="flex-auto p-8 relative">
        

        <div className="relative w-full h-full left-8">
          <img src={image} className={`transition duration-300 ease-in-out w-full h-full object-cover rounded-md ${isDarkMode ? 'bg-gray-800' : 'bg-white'}`} alt="Description" />
          <div className={`absolute bottom-0 left-0 w-full p-4 bg-gradient-to-t from-gray-900 via-transparent to-transparent bg-black bg-opacity-40 ${isDarkMode ? 'text-white' : 'text-gray-900'} rounded-b-md`}>
            {currentPage < totalPages && (
              <>
                <h2 className={`text-xl font-semibold ${isDarkMode ? 'text-white' : 'text-white'}`}>
                  {getChapterAndSection().chapterTitle} - S
                  {getChapterAndSection().sectionNumber}
                </h2>
                <div className="text-lg leading-relaxed text-white ">
                  {getTextSlice()}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>

    

     {/* Glassmorphism container positioned vertically on the left */}
    <div style={{
      backdropFilter: 'blur(10px)',
      backgroundColor: isDarkMode ? 'rgba(31, 41, 55, 0.5)' : 'rgba(255, 255, 255, 0.5)', // Adjust the rgba for dark/light mode
      borderRadius: '20px',
      padding: '15px',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      border: '1px solid rgba(255, 255, 255, 0.2)',
      WebkitBackdropFilter: 'blur(10px)'
    }} className="absolute top-1/2 left-4 -translate-y-1/2 transform">

      <div className="flex flex-col items-center">

                {/* Glassmorphism container for Page Number */}
                <div style={{
          backdropFilter: 'blur(10px)',
          backgroundColor: isDarkMode ? 'rgba(31, 41, 55, 0.5)' : 'rgba(255, 255, 255, 0.5)',
          borderRadius: '10px',
          padding: '10px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          border: '1px solid rgba(255, 255, 255, 0.2)',
          WebkitBackdropFilter: 'blur(10px)'
        }} className="relative mb-3 p-2 rounded-md">
          <span className={`text-lg ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
            Page {currentPage + 1} of {totalPages}
          </span>
        </div>



        {!bookImage && (
          <>
            <button
              onClick={() => !isButtonClicked && handleImageGeneration()}
              className={`transition duration-300 ease-in-out mb-2 text-white font-bold py-2 px-4 rounded-lg shadow-lg ${isDarkMode ? 'bg-gray-700 hover:bg-gray-600' : 'bg-blue-600 hover:bg-blue-500'} ${isButtonClicked ? 'opacity-50 cursor-not-allowed' : ''}`}
              disabled={isButtonClicked}
            >
              Generate Images
            </button>

            <button
              onClick={toggleDarkMode}
              className={`transition duration-300 ease-in-out mb-2 text-white font-bold py-2 px-4 rounded-lg shadow-lg ${isDarkMode ? 'bg-gray-700 hover:bg-gray-600' : 'bg-blue-600 hover:bg-blue-500'}`}
            >
              {isDarkMode ? 'Light Mode' : 'Dark Mode'}
            </button>

            <div className="flex items-center">
              <input
                type="number"
                value={jumpToPage}
                onChange={(e) => setJumpToPage(e.target.value)}
                placeholder="Go to page..."
                className={`transition duration-300 ease-in-out border py-[6px] px-2 rounded-l-lg w-40 text-center ${isDarkMode ? 'bg-gray-800 border-gray-700 text-white' : 'bg-white border-gray-300 text-gray-900'}`}
              />
              
              <button
                onClick={handleJumpToPage}
                className={`transition duration-300 ease-in-out text-white font-bold py-2 px-4 rounded-r-lg shadow-lg ${isDarkMode ? 'bg-gray-700 hover:bg-gray-600' : 'bg-blue-600 hover:bg-blue-500'}`}
                disabled={isNextButtonDisabled}
              >
                Go
              </button>
            </div>
            <div className="flex mt-4">
        <button
          className={`transition duration-300 ease-in-out mb-2 text-white font-bold py-2 px-4 rounded-lg shadow-lg ${isDarkMode ? 'bg-gray-700 hover:bg-gray-600' : 'bg-blue-600 hover:bg-blue-500'}`}
          onClick={previousPage}
          disabled={isButtonDisabled}
        >
          <FaChevronLeft  className="text-3xl"/>
        </button>
        <button
          className={`transition duration-300 ease-in-out mb-2 text-white font-bold py-2 px-4 rounded-lg shadow-lg ml-4 ${isDarkMode ? 'bg-gray-700 hover:bg-gray-600' : 'bg-blue-600 hover:bg-blue-500'}`}
          onClick={nextPage}
          disabled={isNextButtonDisabled}
        >
          <FaChevronRight className="text-3xl" />
        </button>
        </div>
          </>
        )}
      </div>
    </div>
    <ProgressBar progress={currentValue} goal={maxValue} show={showBar} task={false}/>
    {isButtonClicked && (<Alert/>)}
  </div>
</ProgressBarContainer>
  );
};

export default DigitalBook;