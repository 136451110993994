import React, { useState } from 'react';
import BookCreationInvoice from './BookCreationInvoice';


const FictionTabsMobile = (tab) => {
    const [activeTab, setActiveTab] = useState(tab);
    const [activeSetting, setActiveSetting] = useState('Language');
    const [language, setLanguage] = useState('English');
    const [genre, setGenre] = useState('Adventure');
    const [pages, setPages] = useState('25');
    const [image, setImage] = useState('Base');
    const [currentPage, setCurrentPage] = useState(0);


    const genres = [
        ['Mystery', 'Fantasy', 'Horror', 'Romance'],
        ['Adventure', 'Science Fiction', 'Thriller', 'Dystopian | Utopian'],
        // Add more pages as needed
    ];




    return (
        <div className="border-2 border-purple-300 flex items-center bg-purple-300 rounded-3xl justify-center">
            <div className="bg-white w-[400px] mx-4 h-72 rounded-3xl shadow-lg">
            <div className="flex border-10  border-purple-600 m-2">
    <button
        className={`flex-1 text-xl px-6 py-2 rounded-xl mr-2 border ${activeTab === 'Settings' ? 'bg-purple-100 text-purple-600 border-purple-100' : 'bg-white text-purple-400 border-white'}`}
        onClick={() => setActiveTab('Settings')}
    >
        Settings
    </button>
    <button
        className={`flex-1 text-xl px-6 py-2 rounded-xl mr-2 border ${activeTab === 'Invoice' ? 'bg-purple-100 text-purple-600 border-purple-100' : 'bg-white text-purple-400 border-white'}`}
        onClick={() => setActiveTab('Invoice')}
    >
        Invoice
    </button>
</div>

{activeTab === 'Invoice' && (
                    <div className='relative mx-3'>
        <div className="absolute top-[-30px] w-[350px] p-5 right-[-15px] justify-center ">
            <BookCreationInvoice 
       language={language} 
       genre={genre} 
       pages={pages} 
       image={image}/>
        </div>
        </div>
                 )}


                <div className="border-t border-gray-200 pt-1">

                    {activeTab === 'Settings' && (
                <div className="flex mb-1 border-10 border-purple-600">
                    <button
                        className={`flex-1 text-xl px-3 py-2 rounded-xl mx-1 ${activeSetting === 'Language' ? 'bg-indigo-300 text-white border-purple-100' : 'bg-white text-purple-400 border-white'}`}
                        onClick={() => setActiveSetting('Language')}
                    >
                        Language
                    </button>
                    <button
                        className={`flex-1 text-xl px-4 py-2 rounded-xl mx-1 ${activeSetting === 'Genre' ? 'bg-indigo-300 text-white border-purple-100' : 'bg-white text-purple-400 border-white'}`}
                        onClick={() => setActiveSetting('Genre')}
                    >
                        Genre
                    </button>
                    <button
                        className={`flex-1 text-xl px-4 py-2 rounded-xl mx-1 ${activeSetting === 'Pages' ? 'bg-indigo-300 text-white border-purple-100' : 'bg-white text-purple-400 border-white'}`}
                        onClick={() => setActiveSetting('Pages')}
                    >
                        Pages
                    </button>
                    <button
                        className={`flex-1 text-xl px-4 py-2 rounded-xl mx-1 ${activeSetting === 'Image' ? 'bg-indigo-300 text-white border-purple-100' : 'bg-white text-purple-400 border-white'}`}
                        onClick={() => setActiveSetting('Image')}
                    >
                        Image
                    </button>
                    </div>
                    )}

<div className='border-t border-gray-200'>
{activeTab === 'Settings' && activeSetting === 'Language' && (
        <div className="p-5 justify-center mt-4">
            <button 
                className={`text-xl px-6 py-2 rounded-lg m-1 ${language === 'Japanese' ? 'bg-purple-300 text-white' : 'bg-white text-purple-400'}`} 
                onClick={() => setLanguage('Japanese')}>
                Japanese
            </button>
            <button 
                className={`text-xl px-6 py-2 rounded-lg m-1 ${language === 'English' ? 'bg-purple-300 text-white' : 'bg-white text-purple-400'}`} 
                onClick={() => setLanguage('English')}>
                English
            </button>
            <button 
                className={`text-xl px-6 py-2 rounded-lg m-1 ${language === 'Spanish' ? 'bg-purple-300 text-white' : 'bg-white text-purple-400'}`} 
                onClick={() => setLanguage('Spanish')}>
                Spanish
            </button>
            <button 
                className={`text-xl px-6 py-2 rounded-lg m-1 ${language === 'French' ? 'bg-purple-300 text-white' : 'bg-white text-purple-400'}`} 
                onClick={() => setLanguage('French')}>
                French
            </button>
        </div>
)}

{activeTab === 'Settings' && activeSetting === 'Genre' && (
   <div className="justify-center mt-1">
   {genres[currentPage].map((g) => (
       <button
           key={g}
           className={`text-xl px-6 py-2 rounded-lg m-1 ${genre === g ? 'bg-purple-300 text-white' : 'bg-white text-purple-400'}`}
           onClick={() => setGenre(g)}
       >
           {g}
       </button>
   ))}
   <div>
       {currentPage > 0 && (
           <button
               className="text-xl px-6 py-2 rounded-lg m-1"
               onClick={() => setCurrentPage(currentPage - 1)}
           >
               Back
           </button>
       )}
       {currentPage < genres.length - 1 && (
           <button
               className="text-xl px-6 py-2 rounded-lg m-1"
               onClick={() => setCurrentPage(currentPage + 1)}
           >
               More
           </button>
       )}
   </div>
</div>
)}


         {activeTab === 'Settings' && activeSetting === 'Pages' && (
        <div className="p-5 justify-center mt-4">
            <button 
                className={`text-xl px-6 py-2 rounded-lg m-1 ${pages === '25' ? 'bg-purple-300 text-white' : 'bg-white text-purple-400'}`} 
                onClick={() => setPages('25')}>
                25
            </button>
            <button 
                className={`text-xl px-6 py-2 rounded-lg m-1 ${pages === '50' ? 'bg-purple-300 text-white' : 'bg-white text-purple-400'}`} 
                onClick={() => setPages('50')}>
                50
            </button>
            <button 
                className={`text-xl px-6 py-2 rounded-lg m-1 ${pages === '75' ? 'bg-purple-300 text-white' : 'bg-white text-purple-400'}`} 
                onClick={() => setPages('75')}>
                75
            </button>
            <button 
                className={`text-xl px-6 py-2 rounded-lg m-1 ${pages === '100' ? 'bg-purple-300 text-white' : 'bg-white text-purple-400'}`} 
                onClick={() => setPages('100')}>
                100
            </button>
            <button 
                className={`text-xl px-6 py-2 rounded-lg m-1 ${pages === '150' ? 'bg-purple-300 text-white' : 'bg-white text-purple-400'}`} 
                onClick={() => setPages('150')}>
                150
            </button>
            <button 
                className={`text-xl px-6 py-2 rounded-lg m-1 ${pages === '200' ? 'bg-purple-300 text-white' : 'bg-white text-purple-400'}`} 
                onClick={() => setPages('200')}>
                200
            </button>
        </div>
                 )}

{activeTab === 'Settings' && activeSetting === 'Image' && (
        <div className="p-5 justify-center mt-4">
            <button 
                className={`relative text-xl px-6 py-2 rounded-lg m-1 ${image === 'Base' ? 'bg-purple-300 text-white' : 'bg-white text-purple-400'}`} 
                onClick={() => setImage('Base')}>
                Base
                <span className="absolute top-0 right-5 inline-flex items-center justify-center px-3 py-1 text-xs font-bold leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-purple-400 rounded-full">
                   Free
              </span>
            </button>
            <button 
                className={`relative text-xl px-6 py-2 rounded-lg m-1 ${image === 'Standard' ? 'bg-purple-300 text-white' : 'bg-white text-purple-400'}`} 
                onClick={() => setImage('Standard')}>
                Standard
                <span className="absolute top-0 right-5 inline-flex items-center justify-center px-3 py-1 text-xs font-bold leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-purple-400 rounded-full">
                   1
              </span>
            </button>
            <button 
                className={`relative text-xl px-6 py-2 rounded-lg m-1 ${image === 'Ultra' ? 'bg-purple-300 text-white' : 'bg-white text-purple-400'}`} 
                onClick={() => setImage('Ultra')}>
                Ultra
                <span className="absolute top-0 right-5 inline-flex items-center justify-center px-3 py-1 text-xs font-bold leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-purple-400 rounded-full">
                   2
              </span>
            </button>
            <button 
                className={`relative text-xl px-6 py-2 rounded-lg m-1 ${image === 'UltraPlus' ? 'bg-purple-300 text-white' : 'bg-white text-purple-400'}`} 
                onClick={() => setImage('UltraPlus')}>
                Ultra+
                <span className="absolute top-0 right-5 inline-flex items-center justify-center px-3 py-1 text-xs font-bold leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-purple-400 rounded-full">
                   4
              </span>
            </button>
        </div>
                 )}

                    </div>
                </div>
            </div>
        </div>
    );
};

export default FictionTabsMobile;
