import React, {useState} from 'react';
import OutlinePop from './OutlinePop';

const BookCreationInvoice = ({ language, genre, pages, image, prompt }) => {

    const [isFinalModalOpen, setIsFinalModalOpen] = useState();
    const [shownOuline,setShownOutline] = useState(true);

  const handleOutline = () => {
    if(shownOuline){
      setIsFinalModalOpen(true)
      setShownOutline(false)
    } else {
        console.log(prompt)
        console.log(language)
        console.log(genre)
        console.log(pages)
        console.log(image)
        console.log(totalCost)
        // Check for Credits
        // Reduce 10 credits and 
        // Save data and Redirect
  }
}

    // Calculating the cost
    let imageCost = 0;
    if (image === 'Standard') {
        imageCost = parseInt(pages) * 1;
    } else if (image === 'Ultra') {
        imageCost = parseInt(pages) * 2;
    } else if (image === 'UltraPlus') {
        imageCost = parseInt(pages) * 4;
    }

    const pageCost = parseInt(pages) * 10;
    const totalCost = pageCost + imageCost;

    return (
        <div className="px-6 py-4 bg-white rounded-lg shadow-md max-w-md mx-auto justify-center mt-6 border-t border-gray-200">
            <div className="mb-4 space-y-2">
                <div className="flex justify-between">
                    <span className="font-medium text-gray-600">Language:</span>
                    <span className="text-gray-500">{language}</span>
                </div>
                <div className="flex justify-between">
                    <span className="font-medium text-gray-600">Genre:</span>
                    <span className="text-gray-500">{genre}</span>
                </div>
                <div className="flex justify-between">
                    <span className="font-medium text-gray-600">Pages:</span>
                    <span className="text-gray-500">{pages} | ⛁{pageCost}</span>
                </div>
                <div className="flex justify-between">
                    <span className="font-medium text-gray-600">Image:</span>
                    <span className="text-gray-500">
                        {image} {image === 'Base' ? '' : `| ⛁${imageCost}`}
                    </span>
                </div>
            </div>
            <div className="border-t border-gray-200 pt-4">
                <div className="flex justify-between">
                    <button className="font-semibold text-white px-4 py-1 bg-purple-300 rounded-md text-lg"  onClick={handleOutline}>Pay & Generate</button>
                    <span className="font-semibold text-purple-600 text-lg">⛁{totalCost}</span>
                </div>
            </div>
            <OutlinePop isOpen={isFinalModalOpen} setIsOpen={setIsFinalModalOpen} />
        </div>
    );
};

export default BookCreationInvoice;
