import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';

// You might want to use a more formal font for a research paper look
// Import fonts if you have custom fonts, Font.register() etc.

// Create styles that mimic the formal layout of a research paper
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
    padding: 40,
    fontFamily: 'Times-Roman', // Choose a more academic font if available
  },
  chapterTitlePage: {
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  chapterTitle: {
    fontSize: 24,
    textAlign: 'center',
    marginBottom: 20, // Added spacing for a better look
  },
  sectionTitle: {
    fontSize: 20,
    marginBottom: 10, // Increased spacing and font size for clarity
    textTransform: 'uppercase', // Some papers use uppercase for section titles
  },
  paragraph: {
    fontSize: 12,
    textAlign: 'justify',
    lineHeight: 1.5, // Research papers often use increased line height for readability
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 10,
    bottom: 25,
    left: 0,
    right: 0,
    textAlign: 'center',
  },
  header: {
    fontSize: 10,
    textAlign: 'right',
    padding: 10,
    margin: 10,
  },
  footer: { // Footer is typically where the page number would go in many papers
    position: 'absolute',
    fontSize: 10,
    bottom: 20,
    left: 0,
    right: 0,
    textAlign: 'center',
    borderTop: 1,
    paddingTop: 5,
  },
  outline: {
    marginBottom: 20,
    paddingLeft: 20,
  },
  tocParagraph: {
    fontSize: 12,
    textAlign: 'justify',
    lineHeight: 1.5,
    marginLeft: 20, // Add left margin for the bullet point
  },
});

const PdfGenerator = ({ data }) => (
  <Document title="Ebook" author="Swift AI Writer" keywords="Ebook">
    
 {/* Table of Contents */}
 <Page style={styles.page}>
      <Text style={styles.chapterTitle}>Table of Contents</Text>
      {data.map((chapter, chapterIndex) => (
        <View key={`toc_chapter_${chapterIndex}`} style={styles.section}>
          <Text style={styles.sectionTitle}>{chapter.title}</Text>
          {chapter.sections.map((section, sectionIndex) => (
            <Text
              key={`toc_section_${chapterIndex}_${sectionIndex}`}
              style={styles.tocParagraph} // Use the updated style for Table of Contents
            >
              • {section.title}
            </Text>
          ))}
        </View>
      ))}
      {/* Page number for Table of Contents */}
      <Text style={styles.footer} render={({ pageNumber }) => `${pageNumber}`} fixed />
    </Page>

    {/* Main Content */}
    {data.map((chapter, chapterIndex) => (
      <React.Fragment key={`chapter_${chapterIndex}`}>
        <Page style={styles.page}>
          <View style={styles.chapterTitlePage}>
            <Text style={styles.chapterTitle}>{chapter.title}</Text>
          </View>
          {/* Here we render the page number conditionally */}
          {chapter.sections.length > 0 && (
            <Text
              style={styles.footer}
              render={({ pageNumber }) => `${pageNumber}`}
              fixed
            />
          )}
        </Page>

        {chapter.sections.map((section, sectionIndex) => (
          <Page key={`section_${chapterIndex}_${sectionIndex}`} style={styles.page}>
            {/* Page header with potentially a running title */}
            <Text style={styles.header} fixed>
              {chapter.title}: {section.title}
            </Text>
            <View style={styles.section}>
              <Text style={styles.sectionTitle}>{section.title}</Text>
              <Text style={styles.paragraph}>{section.paragraph}</Text>
            </View>
            <Text
              style={styles.footer}
              render={({ pageNumber, totalPages }) =>  // Footer for page numbers
                `${pageNumber} of ${totalPages}`
              }
              fixed
            />
          </Page>
        ))}
      </React.Fragment>
    ))}
  </Document>
);

export default PdfGenerator;