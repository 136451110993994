import React, { useEffect, useState } from "react";
import { motion, useMotionValue, useSpring, useTransform } from "framer-motion";
import bookpage1 from "../Home/bookpage1.png";
import bookpage2 from "../Home/bookpage2.png";
import bookpage3 from "../Home/bookpage3.png";
import bookpage4 from "../Home/bookpage4.png";
import '../show.css';

const Showcase = () => {
  const [imageIndex, setImageIndex] = useState(1);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setImageIndex((prevIndex) => (prevIndex % 10) + 1);
    }, 3000);

    return () => clearInterval(intervalId);
  }, []);

  const imageSources = [
    bookpage1,
    bookpage2,
    bookpage3,
    bookpage4,
    // Add more image sources as needed
  ];
  
  const getImageSource = () => {
    const index = (imageIndex - 1) % imageSources.length;
    return imageSources[index];
  };

  return (
    <div className="grid w-full place-content-center px-4 py-12 text-slate-900" style={{ padding: '0px 40px', borderRadius: '20px', backgroundImage: 'linear-gradient(135deg, rgba(255, 0, 150, 0.5), rgba(0, 150, 255, 0.5))' }}>
      <div className="flex justify-center">
        <div className="flex-card" style={{ marginRight: '30px' }}>
          <TiltCard autoTilt={true} imageSrc={getImageSource()} />
        </div>
      </div>
    </div>
  );
};

const TiltCard = ({ autoTilt,imageSrc }) => {
  const x = useMotionValue(0);
  const y = useMotionValue(0);

  const mouseXSpring = useSpring(x, { damping: 10, stiffness: 50 });
  const mouseYSpring = useSpring(y, { damping: 10, stiffness: 50 });

  const rotateX = useTransform(
    mouseYSpring,
    [-0.5, 0.5],
    ["17.5deg", "-17.5deg"]
  );
  const rotateY = useTransform(
    mouseXSpring,
    [-0.5, 0.5],
    ["-17.5deg", "17.5deg"]
  );

  useEffect(() => {
    if (autoTilt) {
      const tiltInterval = setInterval(() => {
        // Simulate automatic movement of the card
        x.set(Math.sin(Date.now() * 0.00071));
        y.set(Math.cos(Date.now() * 0.00071));
      }, 180); // 60 FPS simulation

      return () => {
        clearInterval(tiltInterval);
      };
    }
  }, [autoTilt,x,y]);

  return (
    <motion.div
      style={{
        rotateY,
        rotateX,
        transformStyle: "preserve-3d",
        backgroundColor: "#EBE9E0",
        border: "2px solid #000000",
      }}
      className="relative h-96 w-72 rounded-xl "
    >
      <img
        src={imageSrc}
        alt="Law Of Attraction"
        style={{
          transform: "translateZ(75px)",
          transformStyle: "preserve-3d",
          width: "100%",
          height: "100%",
          borderRadius: "20px",
          border: "2px solid #000000",
        }}
      />
    </motion.div>
  );
};

export default Showcase;
