import React, { useState,useEffect } from 'react';
import { initGA, trackPage } from './analytics';
import { useParams } from 'react-router-dom';

function AddPage() {
  useEffect(() => {
    initGA(); // This might be better placed in your index.js or App.js file
    trackPage(window.location.pathname + window.location.search);
  }, []);

  const { email, price, orderNumber } = useParams();
  const [code, setCode] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent form submission from reloading the page
    if (code.length !== 6) {
      alert('Code must be 6 digits!');
      return;
    }
    setIsSubmitting(true);

    // Format endpoint with actual path parameters
    const endpoint = `https://backend-production-f884.up.railway.app/Add/${email}/${price}/${orderNumber}`;

    // Example POST request with fetch
    try {
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Add-Code': `${code}`
        },
        // Pass the code as a number in the body
        body: JSON.stringify({ code: code }),
      });

      if (response.ok) {
        // Handle success
        alert('Request successful!');
      } else {
        // Handle error response
        alert('Request failed!');
      }
    } catch (error) {
      // Handle network error
      console.error('Request error:', error);
      alert('Request error. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center">
      <div className="bg-white shadow-md rounded-lg px-8 pt-6 pb-8 mb-4">
        <h2 className="block text-gray-700 text-xl font-bold mb-2">Add Order</h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <input
            type="number"
            placeholder="Enter your 6-digit code"
            className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md text-sm shadow-sm placeholder-gray-400 focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            maxLength="6"
          />
          <button
            type="submit"
            disabled={isSubmitting}
            className={`w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''}`}
          >
            {isSubmitting ? 'Submitting...' : 'ADD'}
          </button>
        </form>
      </div>
    </div>
  );
}

export default AddPage;
