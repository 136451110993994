import React, { useEffect } from 'react';
import { initGA, trackPage } from './analytics';

import Landing from '../Components/Home/Landing';
import Stats from "../Components/Home/Stats";
// import IntroVideo from '../Components/IntroVideo';
import FaqHome from '../Components/Home/FaqHome';
import Footer from '../Components/Footer';
import BookList from '../Components/Home/BookList';
import FeaturePage from '../Components/Home/FeaturePage';
import Products from '../Components/Home/Products'




function Home() {
  useEffect(() => {
    initGA(); // This might be better placed in your index.js or App.js file
    trackPage(window.location.pathname + window.location.search);
    // Redirect to talesage.com
    window.location.href = 'https://talesage.com';
  }, []);
  
  return (
    <div>
      <Landing/>
      <Stats/>
            <FeaturePage/>
            <Products/>
      <BookList/>
      <FaqHome/>
      <Footer/>
    </div>
  );
}

export default Home;
