import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useUser } from '@clerk/clerk-react';

const TransactionsList = () => {
  const [transactions, setTransactions] = useState([]);
  const { user } = useUser();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [transactionsPerPage] = useState(3);

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await axios.get(`https://backend-production-bcc4.up.railway.app/get-all-transactions/${user.id}`);
        setTransactions(response.data); // Assuming the data is an array directly
        // console.log(response.data)
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch transactions');
        setLoading(false);
      }
    };

    if (user.id) {
      fetchTransactions();
    }
  }, [user.id]);

  const totalPageNum = Math.ceil(transactions.length / transactionsPerPage);

  const indexOfFirstTransaction = (currentPage - 1) * transactionsPerPage;
  const indexOfLastTransaction = indexOfFirstTransaction + transactionsPerPage;
  const currentTransactions = transactions.slice(indexOfFirstTransaction, indexOfLastTransaction);

  const pageNumbers = [];
  for (let i = 1; i <= totalPageNum; i++) {
    pageNumbers.push(i);
  }

  let maxPageNumberLimit = currentPage + 2;
  let minPageNumberLimit = currentPage - 1;

  if (maxPageNumberLimit > totalPageNum) {
    maxPageNumberLimit = totalPageNum;
    minPageNumberLimit = totalPageNum - 3;
  }

  if (minPageNumberLimit < 1) {
    minPageNumberLimit = 1;
    maxPageNumberLimit = 4 > totalPageNum ? totalPageNum : 4;
  }

  const handlePrevBtn = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNextBtn = () => {
    setCurrentPage(currentPage + 1);
  };

  if (loading) return <div className="text-center py-10 text-lg">Loading...</div>;
  if (error) return <div className="text-center text-red-600 py-10">{error}</div>;

  return (
    <div className="container mx-auto my-10 p-8 bg-white shadow-lg rounded-lg">
      <h2 className="text-3xl font-semibold text-gray-800 mb-4">Transactions</h2>
      {currentTransactions.length === 0 ? (
        <p className="text-gray-600 text-lg">No transactions found for user.</p>
      ) : (
        <ul className="list-disc list-inside">
          {currentTransactions.map((transaction, index) => (
            <li key={index} className="border-b text-lg last:border-b-0 py-4 flex justify-between">
              <div>
                <div className="text-gray-800 text-lg ">Order Number: {transaction.orderNumber}</div>
                <div className="font-semibold text-lg text-gray-600">Paid: ${transaction.price/1000}</div>
              </div>
              <div>
              <div className="text-gray-800 text-lg ">{(transaction.price/3000)*100} Credits Added</div>
              <div className="text-gray-600 text-sm self-end">Transaction Date: {new Date(transaction.created_at).toLocaleDateString()}</div>
              </div>
            </li>
          ))}
        </ul>
      )}

      <div className="flex justify-center mt-4">
        <button className="mx-1 px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300" onClick={handlePrevBtn} disabled={currentPage === 1}>Previous</button>
        {pageNumbers.slice(minPageNumberLimit - 1, maxPageNumberLimit).map(number => (
          <button key={number} className={`mx-1 px-4 py-2 ${currentPage === number ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-800'} rounded hover:bg-blue-400`} onClick={() => setCurrentPage(number)}>
            {number}
          </button>
        ))}
        <button className="mx-1 px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300" onClick={handleNextBtn} disabled={currentPage === totalPageNum}>Next</button>
      </div>
    </div>
  );
};

export default TransactionsList;
