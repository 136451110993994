import React, { useState, useEffect, useCallback, useRef } from 'react';
import BookDescriptionInput from '../BookDescriptionInput';
import CustomButton from './CustomButton';
import { useNavigate } from 'react-router-dom';
import AsyncQueue from 'async-queue';
import { useUser } from "@clerk/clerk-react";
import axios from 'axios';
import Loading from'../NonFictional/Loading';
import FactDisplay from '../NonFictional/FactDisplay';
import Generate from '../NonFictional/Generate';
import FictionTabs from './FictionTabs';
// import InvoiceTabs from './InvoiceTabs';
import { useMediaQuery } from 'react-responsive';
import FictionTabsMobile from './FictionTabsMobile';
// import OutlinePop from './OutlinePop';
import Pop from './Pop';


// Function to format chapter title to proper case
const formatChapterTitle = (title) => {
  return title
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};



const FictionGen = () => {
  const [bookDescription, setBookDescription] = useState('');
  const [isErrorModalOpen, setIsErrorModalOpen] = useState();

  const [isFinalModalOpen, setIsFinalModalOpen] = useState();
  const [shownOuline,setShownOutline] = useState(true);

  const [isModalOpen, setIsModalOpen] = useState()
  const [userRequest, setUserRequest] = useState('');
  const [completionResult, setCompletionResult] = useState(null);
  const [shown, setShown] = useState(true)
  const [invoiceShown, setInvoiceShown] = useState(true)
  const [bookTitle, setBookTitle] = useState('');
  const [timestamp, setTimestamp] = useState('');
  const isMounted = useRef(true);
  const isFetchedTimestamp = useRef(true);
  const [fictionProgressStatus, setFictionProgressStatus] = useState('About');
  const FictionProgress = ["About","Settings","Invoice","Final"]

  const [tryFetch,setTryFetch] = useState(false);

  const [currentTab, setCurrentTab] = useState('Settings');

  const { user } = useUser();

  const [processingQueue, setProcessingQueue] = useState(new AsyncQueue());
  const [processing, setProcessing] = useState(false);

  const [isLoading, setIsLoading] = useState(false); // New loading state

  const [bookRequest, setBookRequest] = useState(true);

  const [isFunctionsCalled, setIsFunctionsCalled] = useState(false);



  const navigate = useNavigate();


  function convertTimestamp(timestamp) {
    return timestamp.replace(/[\/,: ]/g, '');
  }

  const isMobile = useMediaQuery({ maxWidth: 768 }) 


  const cleanData = (Data) => {
    // console.log("Data,",Data);

    // Initialize a flag to track what type of brace to replace with next.
    const FirstDataClean = Data
    let replaceWithOpenBrace = true;
  
    // Replace "**" with "**{" or "}**" alternately throughout the string.
    let replacedData = FirstDataClean.replace(/\*\*/g, () => {
      if (replaceWithOpenBrace) {
        replaceWithOpenBrace = false;
        return '**{';
      } else {
        replaceWithOpenBrace = true;
        return '}**';
      }
    });

    // console.log("Data1,",replacedData);
    // Replace occurrences of "**{1." to "**{Chapter 1:" and so on up to 10.
    for (let i = 1; i <= 10; i++) {
      replacedData = replacedData.replace(new RegExp(`\\*\\*\\{${i}\\.`,'g'), `**{Chapter ${i}:`);
    }
    // console.log("Data2,",replacedData);
  
    // Trim the string to start from "**{Chapter 1:" onwards.
    let relevantData = replacedData.substring(replacedData.indexOf("**{Chapter 1:"));

    // console.log("Data3,",relevantData);

  
    // Replace specific sections with their numbered counterparts.
    // finalData = finalData.replace(/(1\.2|1\.3|1\.4|2\.2|2\.3|2\.4|3\.2|3\.3|3\.4|4\.2|4\.3|4\.4|5\.2|5\.3|5\.4|6\.2|6\.3|6\.4|7\.2|7\.3|7\.4|8\.2|8\.3|8\.4|9\.2|9\.3|9\.4|10\.2|10\.3|10\.4)/g, '/$1');
  
    // Replace /** and **/ with ** to clean up any formatting issues.
    let finalData = relevantData.replace(/\/\*\*/g, '**');
    finalData = finalData.replace(/\*\*\//g, '**');

    // console.log("Data4,",finalData);
  
    // Replace "**{Chapter 2" to "/**{Chapter 2" and so on up to 10 for additional chapters.
    for (let i = 2; i <= 10; i++) {
      const regex = new RegExp(`\\*\\*{Chapter ${i}`, 'g');
      finalData = finalData.replace(regex, `/**{Chapter ${i}`);
    }

    // console.log("Data5,",finalData);

  
    // Remove slashes between curly braces to clean up any misplaced slashes.
    // finalData = finalData.replace(/({[^}]*?)\/([^}]*})/g, '$1$2');

      // Step 1: Replace all double asterisks temporarily with a placeholder.
  let tempData = finalData.replace(/\*\*/g, "DOUBLEAST");

  // console.log("Data6,",tempData);

  // Step 2: Remove all single asterisks.
  tempData = tempData.replace(/\*/g, "");

  // console.log("Data7,",tempData);

  // Step 3: Replace the placeholder back with double asterisks.
  tempData = tempData.replace(/DOUBLEAST/g, "**");

  // console.log("Data8,",tempData);

    // Add a "/" at the end of the line starting with "10.4."
    finalData = tempData.replace(/(10\.4[^/]*)$/, '$1/');

    // console.log("Data810,",finalData);


              // Cut off everything after the last '/' to clean up the end.
     finalData = finalData.substring(0, finalData.lastIndexOf('/') + 1);

    // console.log("Data9,",finalData);

    finalData = finalData.replace(/\n/g, '/\n');

    // console.log("Data910",finalData)

    finalData = finalData.replace(/\n\n/g, '/ \n\n');

    // console.log("Data920",finalData)


              // Replace multiple consecutive slashes with a single slash
              tempData = finalData.replace(/\/{2,}/g, '/');

              // console.log("Data11,",tempData);

    
    // Return the cleaned and formatted data.
    return finalData + '/';
  };



  

  const parseInput = (input) => {
    const chapters = [];
    let currentChapter = null;
  
    input.split(/\n+/).forEach((line) => {
      // Replace "CHAPTER" with "Chapter"
      line = line.replace(/\*\*\{CHAPTER (\d+): (.+?)\}\*\*/, (_, chapterNumber, chapterTitle) => {
        // Update chapter title to proper case
        const formattedChapterTitle = formatChapterTitle(chapterTitle);
        return `**{Chapter ${chapterNumber}: ${formattedChapterTitle}}**`;
      });
  
      const matchChapter = line.match(/\*\*\{(.+?)\}\*\*/);
      const matchSection = line.match(/(\d+\.\d+)\s(.+?)\//);
  
      if (matchChapter) {
        currentChapter = {
          title: formatChapterTitle(matchChapter[1].replace('/', '')), // Remove "/" from the title and format to proper case
          sections: [],
        };
        chapters.push(currentChapter);
      } else if (matchSection && currentChapter) {
        currentChapter.sections.push({
          title: matchSection[2].replace('/', ''), // Remove "/" from the title
          paragraph: '',
        });
      }
    });
  
    return chapters;
  };

  
  const modifyData = useCallback(async (originalData) => {
    const modifiedData = await Promise.all(originalData.map(async (chapter) => {
      const chapterTitle = chapter.title;
      const failedAttempts = []; // Array to collect failed attempts
      const updatedSections = await Promise.all(chapter.sections.map(async (section) => {
        let completionResult;
        let messageContent = ''; // Initialize messageContent
  
        try {
          completionResult = await fetchChapterCompletion(chapterTitle, section.title);
          if (completionResult) {
            messageContent = completionResult?.choices?.[0]?.message?.content || '';
            console.log("chapterTitle", chapterTitle, "section.title", section.title, "messageContent", messageContent);
            console.log(completionResult)
          }
        } catch (error) {
          console.error("Error fetching chapter completion:", error);
          // Collect the failed attempt in the array
          failedAttempts.push({ chapterTitle, sectionTitle: section.title });
          console.log("Failedssss", chapterTitle, section.title);
        }
  
        // If after the attempt there's no valid messageContent
        if (!messageContent) {
          messageContent = "Sorry, claim free credits by mailing";
        }
  
        const cleanedParagraph = messageContent.replace(/^(Chapter \d+:.*?\n\n)/, '');
  
        return {
          ...section,
          paragraph: cleanedParagraph,
        };
      }));
  
      // Retry failed attempts with a 1-second delay
      for (const failedAttempt of failedAttempts) {
        try {
          const { chapterTitle, sectionTitle } = failedAttempt;
          await new Promise(resolve => setTimeout(resolve, 1000)); // 1 second delay
          const completionResult = await fetchChapter(chapterTitle, sectionTitle);
          // console.log("Failed", chapterTitle, sectionTitle);
          if (completionResult) {
            const messageContent = completionResult?.choices?.[0]?.message?.content || '';
            // console.log("Retried: chapterTitle", chapterTitle, "sectionTitle", sectionTitle, "messageContent", messageContent);
            // Optionally, update the modifiedData here based on the successful retry.
            // Find the corresponding section in updatedSections and update it.
            const updatedSectionIndex = updatedSections.findIndex((section) => section.title === sectionTitle);
            if (updatedSectionIndex !== -1) {
              updatedSections[updatedSectionIndex] = {
                ...updatedSections[updatedSectionIndex],
                paragraph: messageContent.replace(/^(Chapter \d+:.*?\n\n)/, ''),
              };
            }
          }
        } catch (error) {
          console.error("Error retrying chapter completion:", error);
        }
      }
  
      return {
        ...chapter,
        sections: updatedSections,
      };
    }));
  
    return modifiedData;
  }, []);  
  

  
  
  


  const handleBookDescriptionChange = (e) => {
    const userInput = e.target.value;
    setBookDescription(userInput);
    console.log("Title",bookTitle);
  };

  const bookIdeas = [
    'A mystery novel set in a futuristic city, where a detective solves crimes using advanced technology.',
    'A fantasy tale about a lost kingdom and the young hero destined to find it.',
    'A science fiction story about an interstellar war and the unlikely heroes who save the galaxy.',
'A historical fiction about a forbidden romance during the Renaissance era.',
'A dystopian novel where society lives under a dome due to a polluted environment.',
'A thriller about a heist gone wrong in a bustling metropolis.',
'A magical realism story set in a small village where everyone has a secret power.',
'A horror tale about a haunted house that holds a dark family secret.',
'A comedy about a time traveler who accidentally changes historical events.',
'A steampunk adventure featuring a group of rebels fighting against a tyrannical government.',
'A space opera about a rogue pilot and her adventures among the stars.',
'A cyberpunk narrative exploring the impact of technology on human identity.',
'A coming-of-age story set in a fantasy world where magic is forbidden.',
'A paranormal romance between a human and a ghost living in an ancient mansion.',
'A mystery involving a series of cryptic messages found in old books.',
'A post-apocalyptic tale of survival in a world overrun by zombies.',
'A superhero story about a group of teenagers discovering their powers.',
'A psychological thriller about a detective with a split personality.',
'A war drama set in an alternate universe where magic is used in combat.',
'A speculative fiction exploring a world where dreams can be recorded and watched.',
'A dark comedy about a dysfunctional family of witches trying to live a normal life.',
'A gothic novel set in a Victorian-era mansion with a mysterious past.',
'An urban fantasy about mythical creatures living secretly in a modern city.',
'A sci-fi thriller involving a conspiracy about alien contact on Earth.',
'A romantic comedy set in a world where love is determined by a matchmaking algorithm.',
'A spy thriller in a world where everyone has access to advanced surveillance technology.',
'A time loop story about a character living the same day over and over.',
'A high fantasy epic about a war between gods and mortals.',
'An adventure novel about the search for a legendary artifact in an ancient jungle.',
'A slice-of-life story about a group of friends navigating the challenges of adulthood.',
'A noir detective story set in a city where it never stops raining.',
'A survival tale about a stranded astronaut on a hostile alien planet.',
'A political thriller set in a world where corporations rule entire countries.',
'A romantic drama about star-crossed lovers in a time of war.',
'An epic fantasy about a quest to overthrow a corrupt empire.',
'A comedy about a group of friends accidentally starting a successful business.',
'A supernatural story involving a town where the dead return every Halloween.',
'A historical adventure about explorers discovering a lost civilization.',
'A young adult novel about teenagers with superpowers in a government academy.',
'A sci-fi story about humans colonizing a new planet, but finding it already inhabited.',
'A mystery where ancient artifacts predict future events.',
'A horror story about a family cursed by an ancestral ghost.',
'A road trip adventure during a zombie apocalypse.',
'A satire about a society obsessed with virtual reality.',
'A fantasy romance between a mermaid and a human.',
'A dystopian tale about a world without water.',
'A historical fiction set during the fall of the Berlin Wall.',
'A cybernetic revolt in a futuristic megacity.',
'A dark comedy about an amateur detective solving petty crimes.',
'A psychological thriller about an insomniac who starts living in his dreams.',
'A space exploration story with a twist of alien mythology.',
'A steampunk adventure to find a legendary flying city.',
'A magical school where students learn to control time.',
'A post-apocalyptic tale of a society living underground.',
'A gothic horror story set in an isolated mountain village.',
'An epic tale of kingdoms clashing over an ancient prophecy.',
'A romance that blooms over a shared love of cooking in a competitive culinary world.',
'A wild west fantasy with magic duels and outlaw wizards.',
'A mystery set in a futuristic city where memories can be altered.',
'A pirate adventure on the high seas with a supernatural twist.',
'A comedy about a group of aliens trying to understand human life.',
'A superhero story where the hero loses their powers.',
'An urban fantasy about a secret society of werewolves.',
'A sci-fi thriller about cloning extinct species.',
'A romantic comedy set during a series of mistaken identity mishaps.',
'A spy novel in a world where spies are celebrities with their own fan followings.',
'A time travel story about fixing small historical mistakes with big consequences.',
'An adventure story about a treasure hunt in ancient ruins.',
'A slice-of-life story about a group of artists living in a bustling city.',
'An alternate history where the industrial revolution never happened.',
  ];
  
  
  const generateIdea = () => {
    const randomIndex = Math.floor(Math.random() * bookIdeas.length);
    setBookDescription(bookIdeas[randomIndex]);
  };


  const handleBack = () => {
    // Find the current index of fictionProgressStatus in FictionProgress array
    const currentIndex = FictionProgress.indexOf(fictionProgressStatus);

    // Check if the current index is not the first element
    if (currentIndex > 0) {
      setFictionProgressStatus(FictionProgress[0]);
    }
  }


  const handleNext = () => {

    if (bookDescription.length <= 3) {
      setIsErrorModalOpen(true)
    }else{ 
    // Find the current index of fictionProgressStatus in FictionProgress array
    const currentIndex = FictionProgress.indexOf(fictionProgressStatus);

    // Check if the current index is not the first element
    if (currentIndex <= 2) {
      setFictionProgressStatus(FictionProgress[1]);
    }
  }
}


  const handleReset = () => {
    setBookDescription('')
  }


  const fetchChatCompletion =  useCallback(async (serverPrompt) => {
    try {
        const response = await axios.post('https://jack-l3eo.onrender.com/api/fetchChatCompletion', { serverPrompt });
        const result = response.data;
        // console.log(result);
        setCompletionResult(result);
    } catch (error) {
        console.error(error);
    }
  },[]);

  //------------------------------------------------------------------------------------------------------------//

  const fetchChapter = async (BookChapterTitle, TopicName) => {
    try {
        const response = await axios.post('https://chapter-0pxb.onrender.com/api/fetchChapter', { BookChapterTitle, TopicName });
        const result = response.data;
        // console.log(result);
        return result;
    } catch (error) {
        console.error(error);
        throw error; // or handle error appropriately
    }
  };

  //------------------------------------------------------------------------------------------------------------//

  const fetchTitleCompletion = async (userInputForTitle) => {
    try {
        const response = await axios.post('https://jack-l3eo.onrender.com/api/fetchTitleCompletion', { userInputForTitle });
        const result = response.data;
        // console.log(result);
        return result;
    } catch (error) {
        console.error(error);
        throw error; // or handle error appropriately
    }
  };

//------------------------------------------------------------------------------------------------------------//

const fetchChapterCompletion = async (BookChapterTitle, TopicName) => {
  try {
      const response = await axios.post('https://jack-l3eo.onrender.com/api/fetchChapterCompletion', { BookChapterTitle, TopicName });
      const result = response.data;
      // console.log(result);
      return result;
  } catch (error) {
      console.error(error);
      throw error; // or handle error appropriately
  }
};

const fetchUserCredits = async () => {
  try {
    // Replace 'your-credits-endpoint' with the actual endpoint to fetch user credits
    const response = await axios.get(`https://ai-writer-xow5.onrender.com/fetch/${user.id}`);

    return response.data.total_credits; // Assuming the response contains the user's total credits
  } catch (error) {
    console.error('Error fetching user credits:', error);
    throw error;
  }
};

//------------------------------------------------------------------------------------------------------------//

const handleEmailMeClick = async () => {
  try {


    if(invoiceShown){
      setIsFinalModalOpen(true)
      setInvoiceShown(false)
      }

        if (bookDescription.length >= 3) {
          setIsLoading(true);
  
    // Check the condition before proceeding
      setIsLoading(true);

      // If already processing, add to the queue
      if (processing) {
        setProcessingQueue((prevQueue) => {
          prevQueue.enqueue(async () => {
            await processEmailMeClick(bookDescription);
          });
          return prevQueue;
        });
      } else {
        // Not processing, so directly call processEmailMeClick
        await processEmailMeClick(bookDescription);
      }
    } else {
      // Condition not met, set error message
      // console.log('Description should be at least 3 characters long');
      }
  } catch (error) {
    // Handle errors if needed
    console.error('Error:', error);
  }
};



const processEmailMeClick = async (bookDescription) => {
  try {

       // Check if the user has sufficient credits
       const userCredits = await fetchUserCredits(); // You need to implement this function to fetch user credits

       if (userCredits < 0) {
         // If the user doesn't have sufficient credits, redirect them to /balance
        //  console.error('Insufficient credits. Redirecting to /Balance.');
         navigate('/Credit');
         return;
       }

    // Set processing flag to true
    setProcessing(true);

    // console.log(bookDescription)

    // First, fetch the title
    const titleResult = await fetchTitleCompletion(bookDescription);


// First attempt to match double quotes
let generatedTitleMatch = titleResult.choices[0].message.content.match(/"([^"]*)"/);

// Check if the match is empty or null, then try single quotes
if (!generatedTitleMatch) {
   generatedTitleMatch = titleResult.choices[0].message.content.match(/'([^']*)'/);
}
    const generatedTitle = generatedTitleMatch ? generatedTitleMatch[1] : '';

    // Update the book title state if needed
    setBookTitle(generatedTitle);

    // console.log(generatedTitle)

    const requestTemplate = `Develop a comprehensive outline for a book called "${generatedTitle}", encompassing 10 distinct chapters. Each chapter should be denoted by integers like “**{CHAPTER HEADING}**”, and for each chapter, furnish 4 detailed bullet points as topics like 3.1, 3.2, 3.3, 3.4 and don’t forget to end each topic with "/" like 3.1 Don’t let them do what they want to/ 3.2 Create a positive atmosphere/ 3.3 How to talk to someone/ . This outline should be as short, focused and detailed to serve as a structured framework for a practical and informative guide on the topic. Don't use roman numbers. Each chapter must have 4 bullet points`;

    setUserRequest(requestTemplate);

    // Now, proceed with fetchChatCompletion
    await fetchChatCompletion(requestTemplate);


    // After processing, check if there are pending requests in the queue
    setProcessing(false);
    if (processingQueue.size > 0) {
      const nextRequest = processingQueue.dequeue();
      nextRequest();
    }
  } catch (error) {
    // Handle errors if needed
    console.error('Error:', error);

    // Ensure the processing flag is reset even in case of an error
    setProcessing(false);

    // If there are pending requests in the queue, dequeue the next request
    if (processingQueue.size > 0) {
      const nextRequest = processingQueue.dequeue();
      nextRequest();
    }
  }
};

const handleBookRequest = async (userId, bookDescription,bookTitle,timestamp) => {
  try {
    const response = await axios.post('https://ai-writer-xow5.onrender.com/save-book-request', {
      user_id: userId,
      book_description: bookDescription , 
      book_title: bookTitle,
      book_request_id: timestamp,
    });
    console.log(response);
    console.log(response.data);
    console.log(userId)
  } catch (error) {
    console.error('Error reducing credits:', error);
  }
};



useEffect(() => {
  let attemptCount = 0;

  const fetchTimestampAndRender = async () => {
    const currentTimestamp = new Date().toLocaleString();
    const convertedTimestamp = convertTimestamp(currentTimestamp);
    setTimestamp(convertedTimestamp);
  };

  if(isFetchedTimestamp.current){
  fetchTimestampAndRender();
  isFetchedTimestamp.current = false;
  }

  if (completionResult) {
    if(user){

    const userId = user.id
    if( bookRequest){
    handleBookRequest(userId,bookDescription,bookTitle,timestamp )
    setBookRequest(false)
    }
  }
    const fetchDataAndSaveFile = async () => {
       if(!tryFetch){
       try {
        // console.log(completionResult.choices[0].message.content)
        let FetchedData = completionResult.choices[0].message.content
        let originalData = cleanData(FetchedData);
        // console.log("CleanData",originalData)
         originalData = parseInput(originalData);
        //  console.log("Parse",originalData)



// Validate the structure of originalData
while (attemptCount < 4 && !isValidStructure(originalData)) {
  console.error('Invalid structure in originalData. Refetching data...');

      // First, fetch the title
      const newTitle = await fetchTitleCompletion(bookTitle);


      // First attempt to match double quotes
      let newGeneratedTitleMatch = newTitle.choices[0].message.content.match(/"([^"]*)"/);
      
      // Check if the match is empty or null, then try single quotes
      if (!newGeneratedTitleMatch) {
        newGeneratedTitleMatch = newTitle.choices[0].message.content.match(/'([^']*)'/);
      }
          const generatedTitle = newGeneratedTitleMatch ? newGeneratedTitleMatch[1] : '';
      
          // Update the book title state if needed
          setBookTitle(generatedTitle);

    const requestRetryTemplate = `Develop a comprehensive outline for a book called "${generatedTitle}", encompassing 10 distinct chapters. Each chapter should be denoted by integers like “**{CHAPTER HEADING}**”, and for each chapter, furnish 4 detailed bullet points as topics like 1.1, 1.2, 1.3, 1.4, 2.1, 2.2, 2.3, 2.4, 3.1, 3.2, 3.3, 3.4 and don’t forget to end each topic with "/" like 3.1 Don’t let them do what they want to/ 3.2 Create a positive atmosphere/ 3.3 How to talk to someone/ . This outline should be as short, focused and detailed to serve as a structured framework for a practical and informative guide on the topic. Don't use roman numbers. Make sure Each chapter must have 4 bullet points`;


  // Refetch data
  await fetchChatCompletion(requestRetryTemplate);

  originalData = cleanData(completionResult.choices[0].message.content);
    originalData = parseInput(originalData);
    console.log("Parse",originalData)


  attemptCount++;

  // console.log("attemptCount",attemptCount,originalData)
  console.log("attemptCount",attemptCount)

  // Check if the structure is valid after parsing again, break the loop early if so
  if (isValidStructure(originalData)) {
    break;
  }
}
       // If still not valid after 4 attempts, abort
       if (isValidStructure(originalData)) {

        // console.log("originalData", originalData);
        // console.log("parsedChapters", parsedChapters);

        // console.log(timestamp)
  
        const saveFile = (jsonInput, bookDescription,bookTitle,userId,bookType, timestamp) => {
          const dataToSend = {
            bookDescription: bookDescription,
            jsonInput: jsonInput,
            bookTitle: bookTitle,
            userId:userId,
            bookType:bookType,
            timeStamp:timestamp
          };
          // console.log("dataToSend",dataToSend)
        
          fetch('https://save-2zje.onrender.com/save-json', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataToSend),
          })
            .then(response => response.text())
            .then((data) => {
              console.log('Server Response:', data);
              const twelveDigitValue = data.trim().split(' ').pop();
              // console.log('Twelve Digit Value:', twelveDigitValue);
              navigate(`/${twelveDigitValue}`);
            })
            .catch((error) => {
              console.error('Error:', error);
            });
        };


          const handleReduceCredits = async (userIdo) => {
            try {
              const response = await axios.post('https://ai-writer-xow5.onrender.com/reduce-credits', {
                user_id: userIdo, // replace with the actual user ID
                credits_to_reduce: 100 // replace with the desired amount to reduce
              });
              console.log(response.data);
              // console.log(userId)
            } catch (error) {
              console.error('Error reducing credits:', error);
            }
          };

          const logReduceCredits = async (userIdo, timeStampo) => {
            try {
              const response = await axios.post('https://ai-writer-xow5.onrender.com/save-reduced-credits', {
                user_id: userIdo, // replace with the actual user ID
                credits_to_reduce: 100, // replace with the desired amount to reduce
                used_for: "Crafting NonFIction Book",
                book_request_id: timeStampo
              });
              console.log(response.data);
              // console.log(userId)
            } catch (error) {
              console.error('Error reducing credits:', error);
            }
          };



            if (isMounted.current && !isFunctionsCalled) {
              setIsFunctionsCalled(true);
              try {
                // Define your functions here or make sure they are accessible in this scope.
                const UserId = user.id;
                const parsedChapters = await modifyData(originalData);

                const BookType = "Fiction";
                // Execute all functions in a controlled manner
                await Promise.all([
                  handleReduceCredits(UserId),
                  logReduceCredits(UserId, timestamp),
                  saveFile(parsedChapters, bookDescription, bookTitle, UserId, BookType, timestamp)
                ]);
          
              } catch (error) {
                console.error('Error:', error);
                // Reset the flag if there's an error so the user can retry
                setIsFunctionsCalled(false);
              }
            } else {
              console.log("The functions have already been called.");
            }

        

            isMounted.current = false; // Update the ref's current value



      } else {
        console.error('Still invalid structure in originalData after 4 attempts. Aborting.');
        setTryFetch(true)
        setIsFunctionsCalled(false);
        return
      }
      
      } catch (error) {
        console.error('Error:', error);
      }
    }
    };

    // Function to validate the structure of originalData
    const isValidStructure = (data) => {
      return (
        Array.isArray(data) &&
        data.every((chapter) =>
          chapter.hasOwnProperty('title') &&
          typeof chapter.title === 'string' &&
          chapter.hasOwnProperty('sections') &&
          Array.isArray(chapter.sections) &&
          chapter.sections.length === 4 && // Ensure there are exactly 4 sections
          chapter.sections.every((section) =>
            section.hasOwnProperty('title') &&
            typeof section.title === 'string' &&
            section.hasOwnProperty('paragraph') &&
            typeof section.paragraph === 'string'
          )
        )
      );
    };

    // Call the function
    fetchDataAndSaveFile();
  }
}, [completionResult, bookDescription, user, bookTitle,fetchChatCompletion, navigate, modifyData, tryFetch,userRequest ,timestamp,bookRequest, isFunctionsCalled]);
  

  return (
    <>
      {isLoading ? (
        <>
        <div className="flex items-center justify-center">
          <Loading />
        </div>
        <div className="flex items-center justify-center rounded-lg px-8 mt-4 bg-blue-200"><FactDisplay/></div>
        <Generate isOpen={isModalOpen} setIsOpen={setIsModalOpen} />
        </>
      ) : (
        <div>
        <div>
          <div style={{ marginTop: '20px' }}>
            {fictionProgressStatus === 'About' && !isMobile && (
              <>
              <h1 style={{ fontSize: '2rem', fontFamily: 'Poppins, sans-serif', fontWeight: 'bolder', color: '#D2B2F8' }}>
            ⚔️ Craft a Fiction Book
          </h1>
                <div className='mt-8 mr-3'>
                <BookDescriptionInput value={bookDescription} onChange={handleBookDescriptionChange} style={{ fontSize: '1.5rem', fontFamily: 'Poppins, sans-serif', color: '#888888' }} />
                <div className='flex justify-center items-center space-x-7 mt-3'>
                  <CustomButton ButtonName={"BrainStorm"} onClick={generateIdea}/>
                  <CustomButton ButtonName={"Next"} onClick={handleNext}/>
                  <CustomButton ButtonName={"Reset"} onClick={handleReset}/>
                  <Pop isOpen={isErrorModalOpen} setIsOpen={setIsErrorModalOpen} />

                  </div>
                </div>
              </>
            )}

{fictionProgressStatus === 'About' && isMobile && (
              <>
              <h1 style={{ fontSize: '2rem', fontFamily: 'Poppins, sans-serif', fontWeight: 'bolder', color: '#D2B2F8' }}>
            ⚔️ Craft a Non Fiction Book
          </h1>
                <div className='w-26 mr-4'>
                <BookDescriptionInput value={bookDescription} onChange={handleBookDescriptionChange} style={{ fontSize: '1.5rem', fontFamily: 'Poppins, sans-serif', color: '#888888' }} />
                <div className='flex justify-center items-center space-x-7'>
                  <CustomButton ButtonName={"BrainStorm"} onClick={generateIdea}/>
                  <CustomButton ButtonName={"Next"} onClick={handleNext}/>
                  <CustomButton ButtonName={"Reset"} onClick={handleReset}/>
                  <Pop isOpen={isErrorModalOpen} setIsOpen={setIsErrorModalOpen} />

                  </div>
                </div>
              </>
            )}
    </div>

            {fictionProgressStatus === 'Settings' && !isMobile && (
        <div>
          <FictionTabs prompt={bookDescription}/>
          <div className='flex justify-center items-center space-x-12 mt-0'>
          </div>
        </div>
      )}

      {fictionProgressStatus === 'Settings' && isMobile &&
        <div>
          <FictionTabsMobile tabSelected="Settings"/>
          <div className='flex justify-center items-center space-x-7 mt-12'>
          </div>
        </div>
      }
          </div>
          </div>
      )}
    </>
  );
};

export default FictionGen;