import React, { useState , useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Loading from '../NonFictional/Loading'
import OutlinePop from './OutlinePop';
import { useUser } from "@clerk/clerk-react";


const FictionTabs = ({prompt }) => {
  const [isLoading, setIsLoading] = useState(false);
    const [activeTab, setActiveTab] = useState('Settings');
  const [timestamp, setTimestamp] = useState('');
  const [totalCost, setTotalCost] = useState('')
  const [imageCost, setImageCost] = useState('')
  const [pageCost, setPageCost] = useState('')
  const [userID, setUserID] = useState('')



    const [activeSetting, setActiveSetting] = useState('Language');
    const [language, setLanguage] = useState('English');
    const [genre, setGenre] = useState('Adventure');
    const [pages, setPages] = useState('25');
    const [image, setImage] = useState('Standard');
  const isFetchedTimestamp = useRef(true);
  const { user } = useUser();
  const navigate = useNavigate();




    const [isFinalModalOpen, setIsFinalModalOpen] = useState();
    const [shownOuline,setShownOutline] = useState(true);

      const convertTimestamp = (timestamp) => {
        return timestamp.replace(/[\/,: ]/g, '');
      }

      const saveFile = async (bookDescription,bookTitle,userId,bookType, convertedTimestamp, language, genre, pages, image) => {
        try {
            const response = await axios.post('https://backend-production-bcc4.up.railway.app/save-fiction-json', {
          bookDescription: bookDescription,
          jsonInput: convertedTimestamp,
          bookTitle: bookTitle,
          userId:userId,
          bookType:bookType,
          timeStamp:convertedTimestamp,
          language: language,
          genre: genre,
          pages: pages,
          imageQuality: image,
          totalCost: totalCost,
        });

        const data = response.data;
        // console.log('Server Response:', response.data);
        const modifiedTwelveDigitValue = data.trim().split(' ').pop();
        navigate(`/${modifiedTwelveDigitValue}`);
      } catch (error) {
        console.error('Error saving Book:', error);
      }
    };

      const fetchUserCredits = async () => {
        try {
          // Replace 'your-credits-endpoint' with the actual endpoint to fetch user credits
          const response = await axios.get(`https://backend-production-bcc4.up.railway.app/fetch/${userID}`);
      
          return response.data.total_credits; // Assuming the response contains the user's total credits
        } catch (error) {
          console.error('Error fetching user credits:', error);
          throw error;
        }
      };

      const handleReduceCredits = async (userIdo) => {
        try {
          const response = await axios.post('https://backend-production-bcc4.up.railway.app/reduce-credits', {
            user_id: userIdo, // replace with the actual user ID
            credits_to_reduce: 10 // replace with the desired amount to reduce
          });
          console.log(response.data);
          // console.log(userId)
        } catch (error) {
          console.error('Error reducing credits:', error);
        }
      };

      const logReduceCredits = async (userIdo, convertedTimestamp) => {
        try {
          const response = await axios.post('https://backend-production-bcc4.up.railway.app/save-reduced-credits', {
            user_id: userIdo, // replace with the actual user ID
            credits_to_reduce: 10, // replace with the desired amount to reduce
            used_for: "Creating Fiction Book Outline",
            book_request_id: convertedTimestamp
          });
          console.log(response.data);
          // console.log(userId)
        } catch (error) {
          console.error('Error reducing credits:', error);
        }
      };

    const fetchDataAndSaveFile = async () => {
        const fetchTimestampAndRender = async () => {
            const currentTimestamp = new Date().toLocaleString();
            const convertedTimestamp = convertTimestamp(currentTimestamp);
            setTimestamp(convertedTimestamp);
            return convertedTimestamp;
          };

          const convertedTimestamp = await fetchTimestampAndRender();

          const bookType = "Fictional";

          await handleReduceCredits(userID);
         await logReduceCredits(userID, convertedTimestamp);
        //  console.log("Im here")
        //  console.log(prompt, prompt, userID, bookType, convertedTimestamp, language, genre, pages, image)
        await saveFile(prompt, prompt, userID, bookType, convertedTimestamp, language, genre, pages, image);
        }

        const handleOutline = async () => {
            if(shownOuline){
                setIsFinalModalOpen(true)
                setShownOutline(false)
            } else {
                // Check for credits
                const userCredits = await fetchUserCredits();
               if (userCredits >= totalCost) {
                    setIsLoading(true)
                   await fetchDataAndSaveFile()
                }
            }
        }

    

    const handleButtonClick = (newImage) => {
        setImage(newImage);
    
        setTimeout(() => {
          setActiveTab('Invoice');
        }, 10);
      };

    useEffect(() => {
        const calculateCosts = () => {
            let imageCalCost = 0;
    
            if (image === 'Standard') {
                imageCalCost = parseInt(pages) * 2;
            } else if (image === 'Ultra') {
                imageCalCost = parseInt(pages) * 4;
            } else if (image === 'UltraPlus') {
                imageCalCost = parseInt(pages) * 8;
            }
    
            const pageCalCost = parseInt(pages) * 10;
            setImageCost(imageCalCost); // Sets the state for image cost
            setPageCost(pageCalCost); // Sets the state for page cost
            
            // Directly calculate totalCost here as we now have the updated values
            const totalCalCost = pageCalCost + imageCalCost;
            setTotalCost(totalCalCost); // Sets the state for total cost
        };
    
        // Call the function to calculate costs
        calculateCosts();
    

        if(user) {
            const userLogged = user.id
            setUserID(userLogged)
        }

    }, [image,pages]);

    return (
        <div>
        {isLoading ? (
          <>
          <div className="flex items-center justify-center">
            <Loading />
          </div>
          </>
        ) : (
            <div className="h-[360px] flex items-center bg-purple-300 rounded-3xl justify-center">
            <div className="bg-white w-full mx-4 h-[320px] rounded-3xl shadow-lg">
            <div className="flex border-10 border-purple-600 m-2">
        <button
        className={`flex-1 text-xl px-6 py-2 rounded-xl mr-2 border ${activeTab === 'Settings' ? 'bg-purple-100 text-purple-600 border-purple-100' : 'bg-white text-purple-400 border-white'}`}
        onClick={() => setActiveTab('Settings')}
    >
        Settings
    </button>
    <button
        className={`flex-1 text-xl px-6 py-2 rounded-xl mr-2 border ${activeTab === 'Invoice' ? 'bg-purple-100 text-purple-600 border-purple-100' : 'bg-white text-purple-400 border-white'}`}
        onClick={() => setActiveTab('Invoice')}
    >
        Invoice
    </button>
    </div>

    {activeTab === 'Invoice' && (
                    <div className='relative'>
        <div className="absolute top-[-30px] w-[725px] p-5 justify-center ">
        <div className="px-6 py-4 bg-white rounded-lg shadow-md max-w-md mx-auto justify-center mt-6 border-t border-gray-200">
            <div className="mb-4 space-y-2">
                <div className="flex justify-between">
                    <span className="font-medium text-gray-600">Language:</span>
                    <span className="text-gray-500">{language}</span>
                </div>
                <div className="flex justify-between">
                    <span className="font-medium text-gray-600">Genre:</span>
                    <span className="text-gray-500">{genre}</span>
                </div>
                <div className="flex justify-between">
                    <span className="font-medium text-gray-600">Pages:</span>
                    <span className="text-gray-500">{pages} | ⛁{pageCost}</span>
                </div>
                <div className="flex justify-between">
                    <span className="font-medium text-gray-600">Image:</span>
                    <span className="text-gray-500">
                        {image} {image === 'Base' ? '' : `| ⛁${imageCost}`}
                    </span>
                </div>
            </div>
            <div className="border-t border-gray-200 pt-4">
                <div className="flex justify-between">
                    <button className="font-semibold text-white px-4 py-1 bg-purple-300 rounded-md text-lg"  onClick={handleOutline}>Pay & Generate</button>
                    <span className="font-semibold text-purple-600 text-lg">⛁{totalCost}</span>
                </div>
            </div>
            <OutlinePop isOpen={isFinalModalOpen} setIsOpen={setIsFinalModalOpen} />
        </div>
        </div>
        </div>
                 )}
                 {activeTab === 'Settings' && (
                <div className="border-t border-gray-200 pt-1">
                 </div>
                 )}

                    {activeTab === 'Settings' && (
                <div className="flex mb-1 border-10 border-purple-600">
                    <button
                        className={`flex-1 text-xl px-4 py-2 rounded-xl mx-1 ${activeSetting === 'Language' ? 'bg-indigo-300 text-white border-purple-100' : 'bg-white text-purple-400 border-white'}`}
                        onClick={() => setActiveSetting('Language')}
                    >
                        Language
                    </button>
                    <button
                        className={`flex-1 text-xl px-4 py-2 rounded-xl mx-1 ${activeSetting === 'Genre' ? 'bg-indigo-300 text-white border-purple-100' : 'bg-white text-purple-400 border-white'}`}
                        onClick={() => setActiveSetting('Genre')}
                    >
                        Genre
                    </button>
                    <button
                        className={`flex-1 text-xl px-4 py-2 rounded-xl mx-1 ${activeSetting === 'Pages' ? 'bg-indigo-300 text-white border-purple-100' : 'bg-white text-purple-400 border-white'}`}
                        onClick={() => setActiveSetting('Pages')}
                    >
                        Pages
                    </button>
                    <button
                        className={`flex-1 text-xl px-4 py-2 rounded-xl mx-1 ${activeSetting === 'Image' ? 'bg-indigo-300 text-white border-purple-100' : 'bg-white text-purple-400 border-white'}`}
                        onClick={() => setActiveSetting('Image')}
                    >
                        Image
                    </button>
                    </div>
                    )}

<div className='border-t border-gray-200'>
{activeTab === 'Settings' && activeSetting === 'Language' && (
        <div className="p-5 justify-center mt-12">
            <button 
                className={`relative text-xl px-6 py-2 rounded-lg mx-2 ${language === 'Japanese' ? 'bg-purple-300 text-white' : 'bg-white text-purple-400'}`} 
                onClick={() => setLanguage('Japanese') & setActiveSetting('Genre')}>
                Japanese
                <span className="absolute top-0 right-5 inline-flex items-center justify-center px-3 py-1 text-xs font-bold leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-purple-400 rounded-full">
                   BETA
              </span>
            </button>
            <button 
                className={`text-xl px-6 py-2 rounded-lg m-1 mx-2 ${language === 'English' ? 'bg-purple-300 text-white' : 'bg-white text-purple-400'}`} 
                onClick={() => setLanguage('English') & setActiveSetting('Genre')}>
                English
            </button>
            <button 
                className={`relative text-xl px-6 py-2 rounded-lg m-1 mx-2 ${language === 'Spanish' ? 'bg-purple-300 text-white' : 'bg-white text-purple-400'}`} 
                onClick={() => setLanguage('Spanish') & setActiveSetting('Genre')}>
                Spanish
                <span className="absolute top-0 right-5 inline-flex items-center justify-center px-3 py-1 text-xs font-bold leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-purple-400 rounded-full">
                   BETA
              </span>
            </button>
            <button 
                className={`relative text-xl px-6 py-2 rounded-lg m-1 mx-2 ${language === 'French' ? 'bg-purple-300 text-white' : 'bg-white text-purple-400'}`} 
                onClick={() => setLanguage('French') & setActiveSetting('Genre')}>
                French
                <span className="absolute top-0 right-5 inline-flex items-center justify-center px-3 py-1 text-xs font-bold leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-purple-400 rounded-full">
                   BETA
              </span>
            </button>
            <div className='flex items-center justify-center'>
            <p className='mt-[60px] font-semibold rounded-t-lg bg-purple-300 text-white w-[300px]'>Choose One</p>
            </div>
        </div>
)}

{activeTab === 'Settings' && activeSetting === 'Genre' && (
    <div className="justify-center mt-12">

        <div>
        <button 
                className={`text-xl px-6 py-2 rounded-lg m-1 ${genre === 'Mystery' ? 'bg-purple-300 text-white' : 'bg-white text-purple-400'}`} 
                onClick={() => setGenre('Mystery') & setActiveSetting('Pages')}>
                Mystery
            </button>
            <button 
                className={`text-xl px-6 py-2 rounded-lg m-1 ${genre === 'Fantasy' ? 'bg-purple-300 text-white' : 'bg-white text-purple-400'}`} 
                onClick={() => setGenre('Fantasy') & setActiveSetting('Pages')}>
                Fantasy
            </button>
            <button 
                className={`text-xl px-6 py-2 rounded-lg m-1 ${genre === 'Science Fiction' ? 'bg-purple-300 text-white' : 'bg-white text-purple-400'}`} 
                onClick={() => setGenre('Science Fiction') & setActiveSetting('Pages')}>
                Science Fiction
            </button>
            <button 
                className={`text-xl px-6 py-2 rounded-lg m-1 ${genre === 'Romance' ? 'bg-purple-300 text-white' : 'bg-white text-purple-400'}`} 
                onClick={() => setGenre('Romance') & setActiveSetting('Pages')}>
                Romance
            </button>
        </div>


        <div>
        <button 
                className={`text-xl px-6 py-2 rounded-lg m-1 ${genre === 'Thriller' ? 'bg-purple-300 text-white' : 'bg-white text-purple-400'}`} 
                onClick={() => setGenre('Thriller') & setActiveSetting('Pages')}>
                Thriller
            </button>
            <button 
                className={`text-xl px-6 py-2 rounded-lg m-1 ${genre === 'Dystopian & Utopian' ? 'bg-purple-300 text-white' : 'bg-white text-purple-400'}`} 
                onClick={() => setGenre('Dystopian & Utopian') & setActiveSetting('Pages')}>
                Dystopian | Utopian
            </button>
            <button 
                className={`text-xl px-6 py-2 rounded-lg m-1 ${genre === 'Horror' ? 'bg-purple-300 text-white' : 'bg-white text-purple-400'}`} 
                onClick={() => setGenre('Horror') & setActiveSetting('Pages')}>
                Horror
            </button>
            <button 
                className={`text-xl px-6 py-2 rounded-lg m-1 ${genre === 'Adventure' ? 'bg-purple-300 text-white' : 'bg-white text-purple-400'}`} 
                onClick={() => setGenre('Adventure') & setActiveSetting('Pages')}>
                Adventure
            </button>
        </div>
    </div>
)}


         {activeTab === 'Settings' && activeSetting === 'Pages' && (
        <div className="p-5 justify-center mt-12">
            <button 
                className={`text-xl px-6 py-2 rounded-lg m-1 ${pages === '25' ? 'bg-purple-300 text-white' : 'bg-white text-purple-400'}`} 
                onClick={() => setPages('25') & setActiveSetting('Image')}>
                25
            </button>
            <button 
                className={`text-xl px-6 py-2 rounded-lg m-1 ${pages === '50' ? 'bg-purple-300 text-white' : 'bg-white text-purple-400'}`} 
                onClick={() => setPages('50') & setActiveSetting('Image')}>
                50
            </button>
          {/*  <button 
                className={`text-xl px-6 py-2 rounded-lg m-1 ${pages === '75' ? 'bg-purple-300 text-white' : 'bg-white text-purple-400'}`} 
                onClick={() => setPages('75') & setActiveSetting('Image')}>
                75
            </button>
             <button 
                className={`text-xl px-6 py-2 rounded-lg m-1 ${pages === '100' ? 'bg-purple-300 text-white' : 'bg-white text-purple-400'}`} 
                onClick={() => setPages('100') & setActiveSetting('Image')}>
                100
            </button>
            <button 
                className={`text-xl px-6 py-2 rounded-lg m-1 ${pages === '150' ? 'bg-purple-300 text-white' : 'bg-white text-purple-400'}`} 
                onClick={() => setPages('150') & setActiveSetting('Image')}>
                150
            </button>
            <button 
                className={`text-xl px-6 py-2 rounded-lg m-1 ${pages === '200' ? 'bg-purple-300 text-white' : 'bg-white text-purple-400'}`} 
                onClick={() => setPages('200') & setActiveSetting('Image')}>
                200
            </button> */}
        </div>
                 )}

                 

{activeTab === 'Settings' && activeSetting === 'Image' && (
        <div className="p-5 justify-center mt-12">
            <button 
                className={`relative text-xl px-6 py-2 rounded-lg m-1 ${image === 'Standard' ? 'bg-purple-300 text-white' : 'bg-white text-purple-400'}`} 
                onClick={() => handleButtonClick('Standard')}>
                Standard
                <span className="absolute top-0 right-5 inline-flex items-center justify-center px-3 py-1 text-xs font-bold leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-purple-400 rounded-full">
                   1
              </span>
            </button>
            <button 
                className={`relative text-xl px-6 py-2 rounded-lg m-1 ${image === 'Ultra' ? 'bg-purple-300 text-white' : 'bg-white text-purple-400'}`} 
                onClick={() => handleButtonClick('Ultra')}>
                Ultra
                <span className="absolute top-0 right-5 inline-flex items-center justify-center px-3 py-1 text-xs font-bold leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-purple-400 rounded-full">
                   2
              </span>
            </button>
            <button 
                className={`relative text-xl px-6 py-2 rounded-lg m-1 ${image === 'UltraPlus' ? 'bg-purple-300 text-white' : 'bg-white text-purple-400'}`} 
                onClick={() => handleButtonClick('UltraPlus')}>
                Ultra+
                <span className="absolute top-0 right-5 inline-flex items-center justify-center px-3 py-1 text-xs font-bold leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-purple-400 rounded-full">
                   4
              </span>
            </button>
        </div>
                 )}

                    </div>
                </div>
        </div>

        )}
        </div>
    );
};

export default FictionTabs;
