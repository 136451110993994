// NewBookViewer.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import BookReader from './BookReader'
import LandingMenu from './LandingMenuSign';
import axios from 'axios';
import Sidebar from '../NonFictional/NewSidebar';
import { motion } from "framer-motion";
import { SignedOut, SignIn, SignedIn, useUser } from "@clerk/clerk-react";


const BookViewer = () => {
  const { twelveDigitValue, chapterIndex, sectionIndex } = useParams();
  const [sidebarData, setSidebarData] = useState(null);
  const [bookTypeFiction,setBookTypeFiction] = useState(false)
  const [SidebarTitle,setSidebarTitle] = useState(false)
  const [sidebarGenre,setSidebarGenre] = useState(false)
  const [sidebarImageQuality,setSidebarImageQuality] = useState("")
  const [sidebarImage,setSidebarImage] = useState(false)
  const [maxValue, setMaxValue] = useState()
  const [lastImage, setastImage] = useState()
  const { user } = useUser();


  const getBookType = async () => {
    try {
        const response = await axios.get(`https://backend-production-bcc4.up.railway.app/get-type/${twelveDigitValue}`);
        console.log('Book Type:', response.data);
        return response.data;
    } catch (error) {
        console.error('Error fetching book type:', error);
        throw error;
    }
};

  useEffect(() => {

    const fetchJsonData = async () => {
      const fetchedBookType = await getBookType()
      if(fetchedBookType === "Fictional"){
        console.log("True",fetchedBookType)
        setBookTypeFiction(true)
        try {
          const twentyDigitValue = twelveDigitValue
          const fallbackResponse = await fetch(`https://backend-production-bcc4.up.railway.app/get-fiction-book/${twentyDigitValue}`);
          console.log(fallbackResponse);
          const fallbackJsonData = await fallbackResponse.json();
          console.log(fallbackJsonData);
          setSidebarData(fallbackJsonData[0].json_data);
          setSidebarTitle(fallbackJsonData[0].book_title)
          setSidebarGenre(fallbackJsonData[0].genre)
          setMaxValue(fallbackJsonData[0].pages)
          setSidebarImageQuality(fallbackJsonData[0].image_quality)
          setastImage(fallbackJsonData[0].LastImage)

          if(fallbackJsonData[0].image===null){
            setSidebarImage(false)
          } else {
            setSidebarImage(false)
          }
        } catch (error) {
          console.error('TRUE Error fetching fallback JSON data:', error);
        }
      

      } else {
        console.log("False",fetchedBookType)

        try {
          const response = await fetch(`https://backend-production-bcc4.up.railway.app/get-json/${twelveDigitValue}`);
          console.log(response);
          const jsonData = await response.json();
          setSidebarData(jsonData);
        } catch (error) {
          console.error('FALSE Error fetching fallback JSON data:', error);
        }
      }
    };
    fetchJsonData();
  }, [twelveDigitValue]);


  return (
    <div>
      <SignedOut>
          <div className="flex items-center justify-center h-screen">
            <SignIn redirectUrl="/Fictional"/>
          </div>
        </SignedOut>
        <SignedIn>
      {sidebarData ? (
        <div>
          <div style={{ }}>
            <BookReader digitValue={twelveDigitValue} data={sidebarData} bookName={SidebarTitle} bookGenre={sidebarGenre} bookImage={sidebarImage} bookImageQuality={sidebarImageQuality} bookPages={maxValue} LastImage={lastImage} userId={user.id} />
          </div>
          {/* <PDFDownloadLink document={<PdfGenerator data={sidebarData} />} fileName="fruit_knowledge.pdf">
            {({ blob, url, loading, error }) =>
              loading ? 'Loading document...' : 'Download now!'
            }
          </PDFDownloadLink> */}
        </div>
      ) : (
        <div className='flex mt-72 justify-center items-center'>
        <motion.div
className="relative box w-52 h-52 bg-purple-400"
animate={{
scale: [1, 2, 2, 1, 1],
rotate: [0, 0, 180, 180, 0],
borderRadius: ["0%", "0%", "50%", "50%", "0%"]
}}
transition={{
duration: 2,
ease: "easeInOut",
times: [0, 0.2, 0.5, 0.8, 1],
repeat: Infinity,
repeatDelay: 1
}}
/>
<motion.div
className="relative box w-52 h-52 bg-purple-300"
animate={{
scale: [1, 2, 2, 1, 1],
rotate: [0, 0, 180, 180, 0],
borderRadius: ["30%", "20%", "100%", "100%", "40%"]
}}
transition={{
duration: 2,
ease: "easeInOut",
times: [0, 0.2, 0.5, 0.8, 1],
repeat: Infinity,
repeatDelay: 2
}}
/>

<h1 className='absolute text-5xl text-white font-bold'>Fetching Book</h1>
</div>
      )}
      {/* {chapterIndex && sectionIndex && (
        <div>
          <p>{`Selected Chapter Index: ${chapterIndex}`}</p>
          <p>{`Selected Section Index: ${sectionIndex}`}</p>
        </div>
      )} */}
      </SignedIn>
    </div>
  );
};

export default BookViewer;
