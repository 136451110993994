import React, { useEffect } from 'react';
import { initGA, trackPage } from './analytics';
import MenuHead from '../Components/NonFictional/LandingMenu';
import Footer from '../Components/Footer';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';



const TermsOfService = () => {
  useEffect(() => {
    initGA(); // This might be better placed in your index.js or App.js file
    trackPage(window.location.pathname + window.location.search);
  }, []);
  return (
    <>
        <div className="h-screen">
        <MenuHead/>
    <div>
    <section class="text-gray-600 body-font">
  <div class="container px-5 py-24 mx-auto flex flex-wrap">
    <h1 class="sm:text-6xl text-12xl text-purple-300 font-medium title-font mb-2 md:w-2/5">Terms of Service</h1>
    <div class="md:w-3/5 md:pl-6">
    <div className="termsOfService">
        <div className='text-5xl text-gray-300 mt-4' >Acceptance of Terms</div>
        <div className='text-2xl '>By accessing Swift AI Writer ("the Service"), you acknowledge that you have read, understood, and agreed to be bound by these Terms of Service ("TOS"). These TOS are a legally binding agreement between you and Swift AI Writer, and by using the Service, you agree to comply with all applicable laws and regulations. If you do not agree to these TOS, you must cease using the Service immediately.</div>

        <div className='text-5xl text-gray-300 mt-4'>Changes to the TOS</div>
        <div className='text-2xl '>We reserve the right, at our sole discretion, to modify or replace these TOS at any time. When changes are made, we will post the revised TOS on the Service and update the "Last Updated" date at the top of the TOS. Your continued use of the Service after any changes constitutes your acceptance of the new TOS. We encourage you to review the TOS periodically for any updates or changes.</div>


        <div className='text-5xl text-gray-300 mt-4' >Privacy Policy</div>
        <div className='text-2xl '>Our Privacy Policy explains how we treat your personal data and protect your privacy when you use our Service. It forms an integral part of these TOS, and by using the Service, you agree that Swift AI Writer can use such data in accordance with our Privacy Policy. We take your privacy seriously and are committed to protecting your rights.</div>
   

        <div className='text-5xl text-gray-300 mt-4' >User Registration</div>
        <div className='text-2xl '>To access certain features of the Service, you may be asked to register and provide certain personal details. When registering, you must provide accurate, complete, and updated registration information. You are responsible for the security of your account and for all activities that occur under your account. Swift AI Writer is not liable for any loss or damages resulting from your failure to maintain the confidentiality of your account credentials.</div>

        <div className='text-5xl text-gray-300 mt-4' >User Generated Content</div>
        <div className='text-2xl '>You may be able to submit, post, or display content on or through the Service. You retain ownership of any intellectual property rights that you hold in that content. However, by providing content to the Service, you grant Swift AI Writer a worldwide, royalty-free, non-exclusive, perpetual license to use, reproduce, modify, publish, and distribute your content. You represent and warrant that you have all the rights, power, and authority necessary to grant the rights granted herein to any content that you submit.</div>

        <div className='text-5xl text-gray-300 mt-4' >Limitation of Liability</div>
        <div className='text-2xl '>To the maximum extent permitted by applicable law, Swift AI Writer shall not be liable for any indirect, incidental, special, consequential or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from.</div>
        <ul className='mt-3'>
        <div className='text-2xl '><li>(i)Your access to or use of or inability to access or use the Service,</li></div>
        <div className='text-2xl '><li>(ii) Any conduct or content of any third party on the Service.</li></div>
        <div className='text-2xl '><li>(iii) Any content obtained from the Service.</li></div>
        <div className='text-2xl '><li>(iv) Unauthorized access, use or alteration of your transmissions or content.</li></div>
        </ul>

        <div className='text-5xl text-gray-300 mt-4' >Termination</div>
        <div className='text-2xl mb-8'>Swift AI Writer may terminate or suspend your access to all or part of the Service at any time, with or without cause, with or without notice, effective immediately, for any reason whatsoever, including but not limited to a breach of these TOS. Upon termination, your right to use the Service will immediately cease. If you wish to terminate your account, you may simply discontinue using the Service. All provisions of the TOS which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity, and limitations of liability.</div>
        <div className='text-2xl '>By using Swift AI Writer, you acknowledge that you have read these expanded Terms of Service in their entirety, understand them, and agree to be bound by all of the terms and conditions contained herein. If at any time you do not agree with any part of the current version of the TOS, your sole remedy is to cease using the Service.</div>

        <div className='text-5xl text-gray-300 mt-4' >Refund and Cancellation Policy</div>
        <div className='text-2xl '>At Swift AI Writer, we strive to deliver a service that creates significant value for our users. However, we understand that there may be circumstances where you might consider a refund or cancellation. Please be aware of the following terms regarding our refund and cancellation policy:</div>
        <ul className='mt-3'>
        <div className='text-2xl '><li><strong>Non-Refundable Credits:</strong>All purchases of credits or services on Swift AI Writer are non-refundable. Once you have bought credits or services, we cannot offer a refund for any unused portion or unutilized services.</li></div>
        <div className='text-2xl '><li><strong>No Refunds for Inability to Create a Book:</strong>If you are unable to create a book or complete a project due to reasons related to our end, such as technical issues or service interruptions, we deeply regret the inconvenience caused. In such cases, we will assess the situation on a case-by-case basis. Our team will work diligently to resolve the issue and ensure you can use our service effectively.</li></div>
        <div className='text-2xl '><li><strong>Resolution Commitment: </strong>In the event of any issues attributed to our service, we are committed to resolving them in the most appropriate and user-friendly manner possible. Our goal is to ensure that you, as a user of Swift AI Writer, find our service valuable and satisfactory.</li></div>
        <div className='text-2xl '><li><strong>Creating Value: </strong>Our primary objective is to create a product that offers substantial value to our users. We continually work towards improving our service to meet and exceed your expectations. If you encounter any issues or have feedback, please do not hesitate to contact our support team.</li></div>
        </ul>

      </div>
      <div class="flex md:mt-4 mt-6">
      <a href='/Support' class="text-indigo-500 inline-flex items-center ml-4">
      <ChevronLeftIcon fontSize='large'/>
      <button class="inline-flex text-white bg-indigo-500 border-0 py-4 px-5 text-lg font-semibold focus:outline-none hover:bg-indigo-600 rounded">About</button>
      </a>
      <a href='/PrivacyPolicy' class="text-indigo-500 inline-flex items-center ml-4">
        <button class="inline-flex text-white bg-indigo-500 border-0 py-4 px-5 text-lg font-semibold focus:outline-none hover:bg-indigo-600 rounded">Privacy Policy</button>
        <ChevronRightIcon fontSize='large'/>
        </a>
      </div>
    </div>
  </div>
</section>
<Footer/>
    </div>

    </div>
    </>
  );
}

export default TermsOfService;