
import React, { useEffect } from 'react';
import { initGA, trackPage } from './analytics';
import MenuHead from '../Components/NonFictional/LandingMenu';
import Footer from '../Components/Footer';
import Products from '../Components/Home/Products'
import cover from './cover.png'

const openEmail = () => {
  window.location.href = "mailto:team@swiftaiwriter.com";
}

function Support() {
  useEffect(() => {
    initGA(); // This might be better placed in your index.js or App.js file
    trackPage(window.location.pathname + window.location.search);
  }, []);
  return (
    <div>
      <MenuHead/>
      <div>
      <section className="text-gray-600 body-font">
      <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
        <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
          <h1 className="title-font sm:text-8xl text-3xl mb-4 font-medium text-gray-900">
            Join Our Discord
            <br className="hidden lg:inline-block" />Community
          </h1>
          <p className="mb-8 leading-relaxed">
          Connect with our Discord community and be a part of the AI revolution in ebook creation with Swift AI Writer. Stay updated with our weekly news, and look forward to regular feature releases. For any questions, reach out to us at <bold>team@swiftaiwriter.com</bold>
          </p>
          <div className="flex justify-center">
            <button className="inline-flex text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg">
              Join
            </button>
            <button  onClick={openEmail} className="ml-4 inline-flex text-gray-700 bg-gray-100 border-0 py-2 px-6 focus:outline-none hover:bg-gray-200 rounded text-lg">
              Email
            </button>
          </div>
        </div>
        <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
          <img className="object-cover object-center rounded" alt="hero" src={cover} />
        </div>
      </div>
    </section>
      </div>


      <section class="text-gray-600 body-font">
  <div class="container px-5 py-24 mx-auto flex flex-wrap">
    <div class="flex flex-wrap -m-4">
      <div class="p-4 lg:w-1/2 md:w-full">
        <div class="flex border-2 rounded-lg border-gray-200 border-opacity-50 p-8 sm:flex-row flex-col">
          <div class="flex-grow">
            <h2 class="text-gray-900 text-lg title-font font-medium mb-3">Privacy Policy</h2>
            <p class="leading-relaxed text-base">Your information is crucial in helping us provide a high-quality service.</p>
            <a href='/PrivacyPolicy' class="mt-3 text-indigo-500 inline-flex items-center">Learn More
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-2" viewBox="0 0 24 24">
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </a>
          </div>
        </div>
      </div>
      <div class="p-4 lg:w-1/2 md:w-full">
        <div class="flex border-2 rounded-lg border-gray-200 border-opacity-50 p-8 sm:flex-row flex-col">
          <div class="flex-grow">
            <h2 class="text-gray-900 text-lg title-font font-medium mb-3">Terms Of Service</h2>
            <p class="leading-relaxed text-base">Our Privacy Policy explains how we treat your personal data and protect your privacy when you use our Service.</p>
            <a href='/TermsOfService' class="mt-3 text-indigo-500 inline-flex items-center">Learn More
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-2" viewBox="0 0 24 24">
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
      <Products/>
      <Footer/>
    </div>
  );
}

export default Support;
