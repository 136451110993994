import React from 'react';
import { TbInfoTriangle } from "react-icons/tb";


const ProgressBar = ({ progress, goal, show }) => {
  if (!show) {
    return null;
  }

  const percentage = Math.min(100, (progress / goal) * 100);
  const radius = 40; // Radius of the circle
  const stroke = 8; // Stroke width
  const normalizedRadius = radius - stroke * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - (percentage / 100) * circumference;

  const secondsLeft = Math.floor((goal*3)-(progress*3))

  return (
    <div>
      <div className="fixed w-[280px] text-black text-sm font-semibold top-20 right-4 border border-gray-300 bg-white rounded-xl p-2 backdrop-filter backdrop-blur-lg shadow-md bg-opacity-50">
      <TbInfoTriangle className='inline mr-1 mb-[0.5px]'/>
      <span className='text-black'>Do no close this window</span>
      </div>
    <div className="fixed top-32 right-4 border border-gray-300 bg-white rounded-xl pt-4 pr-4 pb-2 backdrop-filter backdrop-blur-lg shadow-md bg-opacity-50">
      {/* Purple Circle */}
      <div className="absolute w-24 h-24 bg-purple-400 rounded-full blur-3xl -top-6 -left-6"></div>
      
      {/* Yellow Circle */}
      <div className="absolute w-24 h-24 bg-yellow-400 rounded-full blur-3xl -bottom-6 -right-6"></div>
      <div className='flex'> 

       {/* Circular Progress Bar */}
      <svg height={radius * 2} width={radius * 2} className="z-10 ">
        <circle
          stroke="gray"
          fill="transparent"
          strokeWidth={stroke}
          strokeDasharray={circumference + ' ' + circumference}
          style={{ strokeDashoffset }}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
        <circle
          stroke="blue"
          fill="transparent"
          strokeWidth={stroke}
          strokeDasharray={circumference + ' ' + circumference}
          style={{ strokeDashoffset }}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
        <text
          x="50%"
          y="50%"
          dy=".3em" // Adjust this value to center the text vertically
          textAnchor="middle"
          fill="black"
          fontSize="20px" // Adjust font size as needed
          className="z-10"
        >
          {`${Math.floor(percentage)}`}
        </text>
      </svg>
      <div class="flex justify-between items-center bg-gray-100 px-4 rounded-lg bg-opacity-25">
      <span className="text-lg font-semibold text-gray-800 z-10">Crafting Chapters</span>
       </div>
      </div>
      <div className="flex justify-between text-xs text-gray-800 z-10 mt-1">
        <span className='text-black ml-3'>{`${progress} / ${goal}`} Chapters</span>
        <span className='text-black'>{`${secondsLeft} SEC LEFT`}</span>
      </div>
    </div>
    </div>
  );
};

export default ProgressBar;
