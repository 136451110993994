
import ReactGA from 'react-ga';

const TRACKING_ID = "G-ERHMHH0MLL"; // Your GA4 tracking ID might not be fully supported

export const initGA = () => {
  ReactGA.initialize(TRACKING_ID);
};

export const trackPage = (page) => {
  ReactGA.pageview(page);
};
