import React, { useState, useEffect } from 'react';

  const facts = [
    'The honeybee is the only insect that produces food eaten by humans.',
    'A group of flamingos is called a "flamboyance."',
    'The Eiffel Tower can be 15 cm taller during the summer.',
    'The world’s largest desert is Antarctica.',
    'The mere presence of other people can boost your performance on simple tasks.',
    'The average high school student today has the same level of anxiety as the average psychiatric patient in the 1950s.',
    'Smiling can trick your brain into a happier state.',
    'People who overthink are more likely to have higher IQs.',
    'Your favorite song is probably your favorite because you associate it with an emotional event.',
    'A hug that lasts for 20 seconds releases chemicals in the body that can help reduce stress and lower blood pressure.',
    'Your mind wanders about 30% of the time.',
    'The ability to read emotions in others is linked to general intelligence.',
    'People are more honest when physically tired.',
    'Holding hands with someone you love can alleviate physical pain as well as stress and fear.',
    'Laughter is contagious because your brain automatically tries to join in on the fun.',
    'The smell of chocolate increases theta brain waves, which trigger relaxation.',
    'People tend to remember the first and last things in a sequence best, known as the serial position effect.',
    'Procrastination is linked to a difficulty in managing emotions.',
    'Studies have found that introverts tend to be more creative than extroverts.',
    'The brain treats rejection like physical pain.',
    'Dreams are often more negative than positive.',
    'The smell of lavender can help reduce anxiety.',
    'People who daydream more score higher on creativity and intelligence tests.',
    'Nostalgia can make you more optimistic about the future.',
    'Your favorite color can influence your mood and behavior.',
  'The anticipation of a reward can be more pleasurable than the reward itself.',
  'Being in nature can improve your focus and reduce mental fatigue.',
  'The brain continues to develop into your 20s, and learning new things can stimulate its growth.',
  'People who express gratitude regularly tend to have lower levels of stress and depression.',
  'Music with a fast tempo can increase alertness and improve mood.',
  `The brain is more creative when it's tired.`,
  'The way you speak to yourself internally can significantly impact your mental well-being.',
  'Exercise has been shown to be as effective as medication in treating mild to moderate depression.',
  'Multitasking can decrease productivity and increase stress.',
  'The feeling of nostalgia can increase feelings of social connectedness.',
  'Expressing kindness releases oxytocin, the "love hormone," which can promote feelings of love and bonding.',
  `The brain's reward system is activated when you solve a problem.`,
  'Visualization can improve performance by programming the brain for success.',
  'The fear of missing out (FOMO) is a real and distressing feeling for many people.',
  'Physical touch can convey a wide range of emotions without words.',
  'The brain is more active at night, which is why creative ideas often come during late hours.',
  'Chronic stress can lead to memory loss and cognitive decline.',
  'Your brain can create false memories.',
  'Positive thinking can improve physical health and longevity.',
  'The brain is more active at night than during the day.',
  'Human memory is fallible and can be influenced by suggestion.',
  'Emotions can significantly influence decision-making.',
  'The mere presence of other people can impact your behavior.',
  'Eye contact is a powerful non-verbal communication tool.',
  'Cognitive dissonance is the discomfort felt when holding conflicting beliefs or attitudes.',
  'We tend to prefer familiar faces, known as the mere-exposure effect.',
  'The halo effect is the tendency to perceive someone as good or bad based on a single trait.',
  'Mirror neurons in the brain enable us to empathize with others.',
  'The brain experiences social rejection similarly to physical pain.',
  'The placebo effect demonstrates the power of belief in influencing health outcomes.',
  'Stress can impact the immune system and overall health.',
  'The subconscious mind can influence behavior without conscious awareness.',
  'Body language can convey more information than verbal communication.',
  'Groupthink is the tendency for a group to prioritize harmony over critical thinking.',
  `The mere anticipation of a reward can activate the brain's pleasure centers.`,
  'Humans have a natural negativity bias, focusing more on negative experiences than positive ones.',
  `The brain's prefrontal cortex, responsible for decision-making, is not fully developed until the mid-20s.`,
  'The way we perceive time is influenced by our emotional state.',
  'Neuroplasticity allows the brain to reorganize and adapt throughout life based on experience.',
  ];

  const FactDisplay = () => {
    const [currentFact, setCurrentFact] = useState('Loading...');
    const [showTitle, setShowTitle] = useState(false);
  
    useEffect(() => {
      setShowTitle(true);
  
      const intervalId = setInterval(() => {
        const randomFact = facts[Math.floor(Math.random() * facts.length)];
        setCurrentFact(randomFact);
        setShowTitle(false);
      }, 7000);
  
      return () => clearInterval(intervalId);
    }, []);
  
    return (
      <div className="mt-5 mb-5 ">
        <div className="text-2xl bg-blue-300 p-6 rounded-lg shadow-lg">
          {showTitle && <div className="text-3xl font-bold mb-2 text-white">Did You Know?</div>}
          <h1 className="text-3xl font-semi-bold text-white mb-2">{currentFact}</h1>
        </div>
      </div>
    );
  };
  
  export default FactDisplay;
